import React, {useEffect, useMemo, useState} from "react";
import style from './editModalWindow.module.scss';
import cancel from "../../../../assets/images/teamManagement/cancel.png";
import ButtonWithImage from "../../../parts/buttonWithImage/buttonWithImage";
import save from "../../../../assets/images/common/save.png";
import MessageBox from "../../../parts/messageBox/messageBox";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {
    changeCompanyNameDataType,
    changeUserDataType
} from "../../companyManagement/companyInformation/companyInformation";

export type EditModalWindowFlagType = ''
    | 'company name'
    | 'admin data'
    | 'department leader data'
    | 'team leader data'
    | 'team member data'
    | 'user account data';

export type EditModalWindowPropsType = {
    flag: EditModalWindowFlagType,
    handlerSubmit: (data: any,
                    setError: (notification: string) => void,
                    setSuccess: (notification: string) => void) => void,
    initialValues: any,
    //closeEditModalWindow: (flag: EditModalWindowFlagType | '', isOpen: boolean, chosenUserIdForDemoEdit?: string, oldEmail?: string) => void,
    closeEditModalWindow: (flag: EditModalWindowFlagType | '', isOpen: boolean, chosenUserForDemoEdit?: any) => void,
    // isError: boolean,
    // isSuccess: boolean,
    // notification: string,
    // hideNotification: () => void
}

const EditModalWindow = (props: EditModalWindowPropsType) => {
    const dispatch = useDispatch();


    //useForm пи вызове может принимать обьект (пропсы) настроек.


    //const {control} = useForm(); --> этот обьект содержит методы для регистрации компонентов в react hook form
    //register --> позволяет регистрировать input или select и применить к ним валидацию

    const {
        register,
        formState: {
            errors,
            isValid,
            defaultValues,
            isSubmitSuccessful
        },
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: "onBlur",  //когда происходит валидация (по дефолту OnSubmit)
      //  shouldFocusError: true,
        defaultValues: useMemo(() => {
            // let a = props.initialValues.map(i =>
            // for (let value of Object.values(props.initialValues)) {
            //     alert(value); // John, затем 30
            // }
            return props.initialValues
        }, [props.initialValues.email]),
    });

    const [notification, setNotification] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const hideNotification = () => {
        setNotification('');
        setIsError(false);
        setIsSuccess(false)
    }

    const setError = (notification: string) => {
        setNotification(notification);
        setIsError(true);
    }

    const setSuccess = (notification: string) => {
        setNotification(notification);
        setIsSuccess(true);
    }

    const closeModalWindow = () => {
        props.closeEditModalWindow('', false, null);
       // props.hideNotification();
        hideNotification();
    }

    //метод register() возвращает обьект, поэтому в инпутах сразу используем деструктуризацию
    //register принимает два параметра: 1 - уникальное имя для инпута; 2 - обьект для работы с валидацией

    //наш хэндлер будет вызываться только если не будет ошибок (это гарантирует обертка handleSubmit)
    const onSubmit = (data: any) => {
        props.handlerSubmit(data, setError, setSuccess);
        reset((formValues: any) => ({...formValues}));
    }

    // useEffect(() => {
    //
    //     return () => props.hideNotification();
    // }, [])
    //
    // useEffect(() => {
    //
    //     if(!Object.values(props.initialValues)[0]) {
    //         reset(props.initialValues)
    //     }
    //
    // }, [props.initialValues])

//     useEffect(() => {
// console.log(props)
//     }, [props.initialValues])

    useEffect(() => {
        document.body.classList.add('overflow-hidden')

        return (() => {
            document.body.classList.remove('overflow-hidden');
        })
    }, [])

    return (
        <div className={style.modal_wrapper}>
            <div className={style.modal_window}>
                <div className={style.modal_window_header}>
                    <p>Change {props.flag}</p>
                    <div className={style.cancel_button} onClick={closeModalWindow}>
                        <img src={cancel} alt=""/>
                    </div>
                </div>

                <div className={style.modal_window_separator}></div>

                <div className={style.modal_window_body}>
                    {/*<p>Change title of form</p>*/}

                    {/*['admin data', 'department leader data', 'team leader data', 'team member data', 'user account data'].includes(props.flag) &&*/}

                    <form onSubmit={handleSubmit(onSubmit)}>
                        { props.flag !== 'company name' ?
                        <>
                            <div className={style.input_text_wrapper}>
                                <label htmlFor="firstName">First Name</label>
                                <input {...register('firstName', {
                                    required: 'First name is required!'})} placeholder={'Enter first name'}/>
                            </div>
                            {errors.firstName && errors.firstName.type === "required" && <div className={style.error_message}>{errors?.firstName?.message || 'error!'}</div>}

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="lastName">Last Name</label>
                                <input {...register('lastName', {
                                    required: 'Last name is required!'})} placeholder={'Enter last name'}/>
                            </div>
                            {!!errors?.lastName && <div className={style.error_message}>{errors?.lastName?.message || 'error!'}</div>}

                            <div className={style.input_text_wrapper}>
                                <label htmlFor="email">Email</label>
                                <input {...register('email', {
                                    required: 'Email is required!',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address!'
                                    }
                                })} placeholder={'Enter email'}/>
                            </div>
                            {errors?.email && <div className={style.error_message}>{errors?.email?.message || 'error!'}</div>}
                            </>
                            :
                            <>
                                <div className={style.input_text_wrapper}>
                                    <label htmlFor="companyName">Company Name</label>
                                    <input {...register('companyName', {
                                        required: 'Company name is required!',})} placeholder={'Enter company name'}/>
                                </div>
                                {errors?.companyName && <div className={style.error_message}>{errors?.companyName?.message || 'error!'}</div>}
                            </>}


                        <div className={style.button_wrapper}>
                            <ButtonWithImage height={46}
                                             name={'Save'}
                                             type={'submit'}
                                             margin={'0 auto'}
                                             disabled={!isValid}
                                             img={save}></ButtonWithImage>
                        </div>

                        {(isError || isSuccess) && <div style={{marginTop: '20px'}}>
                            <MessageBox notification={notification} isError={isError} autoClosing={true}
                                        hideError={hideNotification} isSuccess={isSuccess}/>
                        </div>}

                    </form>


                </div>
            </div>
        </div>
    )
}

export default EditModalWindow;