import React, {useCallback, useEffect, useState} from "react";
import style from './sidebar.module.scss';
import InsertedImage from "../insertedImage/insertedImage";
import dashboard from './../../../assets/images/sidebar/dashboard.png';
import unboxtLogo from './../../../assets/images/sidebar/unboxtLogo.png';
import coachingCorner from './../../../assets/images/sidebar/coachingCorner.png';
import teamManagement from './../../../assets/images/sidebar/teamManagement.png';
import checkins from './../../../assets/images/sidebar/checkins.png';
import createTeam from './../../../assets/images/sidebar/createNewTeam.png';
import companyManagement from './../../../assets/images/sidebar/companyManagement.png';
import faq from './../../../assets/images/sidebar/faq.png';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import departmentManagement from './../../../assets/images/sidebar/departmentManagement.png';
import ModalWindowCreateTeam from "../../pages/createTeam/modalWindowCreateTeam/modalWindowCreateTeam";
import ModalWindowCreateDepartment
    from "../../pages/createDepartment/modalWindowCreateDepartment/modalWindowCreateDepartment";

const Sidebar = React.memo(() => {
    const {currentStatus, tourCompleted, showSidebar} = useSelector((state: RootStateType) => state.common);
    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);

    //для отображения модалки перед create new team или create new department
    const [modalMode, setModalMode] = useState<boolean>(false);

    const changeModalMode = useCallback((mode: boolean) => {
        setModalMode(mode);
    }, [])

    //для определениякакую модалку открывать (на создание команды или департамента)
    const [modalType, setModalType] = useState<'department' | 'team' | ''>('');

    const onClickCreateNewTeam = () => {
        changeModalMode(true);
        setModalType('team');
    }

    const onClickCreateNewDepartment = () => {
        changeModalMode(true);
        setModalType('department');
    }

    const additionalSidebarClass = (currentStatus === 'isModerator' && !tourCompleted) ? 'dashboard_company_sidebar' :
        (currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'dashboard_company_sidebar_DA' :
            (currentStatus === 'isAdmin' && !tourCompleted) ? 'dashboard_leader_sidebar' :
                (currentStatus === 'isMember' && !tourCompleted) ? 'dashboard_member_sidebar' : ''

    return (
        <div className={`${style.sidebar} ${additionalSidebarClass}`}
             style={{position: modalMode ? 'relative' : 'sticky'}}>
            <div>
                <div className={`${style.menu_item} ${style.logo}`}>
                    <div style={{width: '23px', height: '23px'}}></div>
                    <InsertedImage width={7.5} height={3.125} image={unboxtLogo}/>
                </div>

                <NavLink
                    to={`${(currentStatus === 'isModerator' || currentStatus === 'isDepartmentAdmin' || demoMode === 'edit') ? '/dashboard' : '/dashboard/team'}`}>
                    <div className={style.menu_item}>
                        <InsertedImage width={1.4375} height={1.4375} image={dashboard}/>
                        <div>Dashboard</div>
                    </div>
                </NavLink>

                {(['isMember', 'isAdmin'].includes(currentStatus) || demoMode === 'edit') &&
                    <NavLink to='/coachingCorner'>
                        <div
                            className={`${style.menu_item} ${(currentStatus === 'isAdmin' && !tourCompleted) ? 'coaching_corner_sidebar_leader' : ''}
                    ${(currentStatus === 'isMember' && !tourCompleted) ? 'coaching_corner_sidebar_member' : ''}`}>
                            <InsertedImage width={1.4375} height={1.4375} image={coachingCorner}/>
                            <div>Coaching Corner</div>
                        </div>
                    </NavLink>}

                {(currentStatus === 'isAdmin' || demoMode === 'edit') && <NavLink to='/teamManagement'>
                    <div className={style.menu_item}>
                        <InsertedImage width={1.4375} height={1.4375} image={teamManagement}/>
                        <div>Team Management</div>
                    </div>
                </NavLink>}

                {(currentStatus === 'isDepartmentAdmin' || demoMode === 'edit') && <NavLink to='/departmentManagement'>
                    <div
                        className={`${style.menu_item} ${(currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'sidebar_department_management_DA' : ''}`}>
                        <InsertedImage width={1.4375} height={1.4375} image={departmentManagement}/>
                        <div>Department Management</div>
                    </div>
                </NavLink>}

                {(currentStatus === 'isModerator' || demoMode === 'edit') && <NavLink to='/companyManagement'>
                    <div
                        className={`${style.menu_item} ${(currentStatus === 'isModerator' && !tourCompleted) ? 'sidebar_company_management_moderator' : ''}`}>
                        <InsertedImage width={1.4375} height={1.4375} image={companyManagement}/>
                        <div>Company Management</div>
                    </div>
                </NavLink>}

                {(['isModerator', 'isAdmin', 'isDepartmentAdmin'].includes(currentStatus) || demoMode === 'edit') &&
                    <NavLink to='/digitalPulse'>
                        <div
                            className={`${style.menu_item} ${(currentStatus === 'isModerator' && !tourCompleted) ? 'sidebar_digital_pulse_moderator' : ''}
                    ${(currentStatus === 'isAdmin' && !tourCompleted) ? 'sidebar_digital_pulse_leader' : ''} 
                    ${(currentStatus === 'isDepartmentAdmin' && !tourCompleted) ? 'sidebar_digital_pulse_DA' : ''}`}>
                            <InsertedImage width={1.4375} height={1.4375} image={checkins}/>
                            <div>Digital Pulse</div>
                        </div>
                    </NavLink>}
            </div>

            <div></div>

            <div>
                {(currentStatus === 'isDepartmentAdmin' || currentStatus === 'isModerator' || demoMode === 'edit') &&
                    <div className={style.menu_item} onClick={onClickCreateNewDepartment}>
                        <InsertedImage width={1.4375} height={1.4375} image={createTeam}/>
                        <div>Create New Department</div>
                    </div>
                }
                <div className={style.menu_item} onClick={onClickCreateNewTeam}>
                    <InsertedImage width={1.4375} height={1.4375} image={createTeam}/>
                    <div>Create New Team</div>
                </div>

                <NavLink to={`/faq`}>
                    <div className={style.menu_item}>
                        <InsertedImage width={1.4375} height={1.4375} image={faq}/>
                        <div>FAQ</div>
                    </div>
                </NavLink>
            </div>

            {modalMode && modalType === 'team' &&
                <ModalWindowCreateTeam closeModalWindow={(mode: boolean) => changeModalMode(mode)}/>}
            {modalMode && modalType === 'department' &&
                <ModalWindowCreateDepartment closeModalWindow={(mode: boolean) => changeModalMode(mode)}/>}
        </div>
    )
})

export default Sidebar;