import React, {useCallback, useEffect, useState} from "react";
import InsightItem from "../insightItem/insightItem";
import {
    activeTabCoachingCornerAC, clearInsightsAndOffsetCoachingCornerAC,
    coachingCornerDislikeInsightTC,
    coachingCornerGetInsightsTC,
    coachingCornerLikeInsightTC,
    makeInsightSeenCoachingCornerAC,
    setChosenItemInModalWindowCoachingCornerAC,
    setLoadingCoachingCornerAC
} from "../../../../redux/reducers/coachingCornerReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import ModalWindowCoachingCorner from "../modalWindow/modalWindowCoachingCorner";
import {setActiveTabTeamAC} from "../../../../redux/reducers/dashboardReducer";
import {useNavigate} from "react-router-dom";
import Tour, {ReactourStep} from "reactour";
import {setTourCompletedTC} from "../../../../redux/reducers/commonReducer";
import ModalWindowWrapper from "../../../parts/modalWindowWrapper/modalWindowWrapper";
import AddItemModalWIndowBody from "../addItemModalWindowBody/addItemModalWIndowBody";
import ButtonWithoutImage from "../../../parts/buttonWithoutImage/buttonWithoutImage";
import InsertedImageRound from "../../../parts/insertedImageRound/insertedImageRound";
import add from './../../../../assets/images/coachingCorner/add.png';
import {closeTour, getScrollingBehaviourForTour} from "../../../../common";

export type InsightItemType = {
    allowComment: boolean,
    color: string,
    comments: Array<any>,
    daysAgo: string,
    disliked: boolean,
    dislikesCount: number,
    iconClass: string,
    id: number,
    insightBody: string,
    liked: boolean,
    likesCount: number,
    modalHeader: string,
    notificationType: string,
    seen: boolean
}

type InsightsPropsType = {
    itemType: 'insight' | 'trend' | null,
    setItemType: (type: 'insight' | 'trend' | null) => void,
    actionType: 'create' | 'update' | null,
    setActionType: (type: 'create' | 'update' | null) => void,
}

const Insights = ({itemType, setItemType, actionType, setActionType}: InsightsPropsType) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {currentStatus, tourCompleted} = useSelector((state: RootStateType) => state.common);
    const state = useSelector((state: RootStateType) => state.coachingCorner);
    const chosenTeamId = state.chosenTeamId;
    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);

    const insightsForTour = [{
        allowComment: false,
        color: "default",
        comments: [],
        daysAgo: "2 days ago",
        disliked: false,
        dislikesCount: 0,
        iconClass: "brand-icon",
        id: 529000,
        insightBody: "Has there been a shift in leadership? Check on the team dynamics on a weekly basis.",
        liked: true,
        likesCount: 1,
        modalHeader: "Insights from unboXt HQ",
        notificationType: "checkIn_insight",
        seen: false
    }, {
        allowComment: false,
        color: "default",
        comments: [],
        daysAgo: "2 days ago",
        disliked: false,
        dislikesCount: 0,
        iconClass: "brand-icon",
        id: 529011,
        insightBody: "Has there been a shift in leadership? Check on the team dynamics on a weekly basis.",
        liked: true,
        likesCount: 1,
        modalHeader: "Insights from unboXt HQ",
        notificationType: "checkIn_insight",
        seen: false
    }]
    const insights = tourCompleted ? state.insights : insightsForTour;
    const like = (insightId: number) => {
       demoMode !== 'edit' && dispatch(coachingCornerLikeInsightTC(insightId));
    }

    const disLike = (insightId: number) => {
        demoMode !== 'edit' && dispatch(coachingCornerDislikeInsightTC(insightId));
    }

    const [modalView, setModalView] = useState<boolean>(false);

    const closeModalWindow = () => {
        setActionType(null);
        setModalView(false);
        setItemType(null);
        dispatch(setChosenItemInModalWindowCoachingCornerAC(null));
    }

    const clickInsight = (insight: any) => {
        setModalView(true);
        dispatch(setChosenItemInModalWindowCoachingCornerAC(insight));
        dispatch(makeInsightSeenCoachingCornerAC(insight.id));
    }


    const updateData = () => {
        let scroll = window.scrollY;
        dispatch(setLoadingCoachingCornerAC(true));
        dispatch(coachingCornerGetInsightsTC(scroll));
    }

    const scrollWatch = useCallback(() => {
        let scroll = window.scrollY;

        if (
            scroll ===
            document.body.scrollHeight - document.documentElement.clientHeight
        ) {

            if (!state.loading && !state.cancelLoading) {
                updateData();
            }

        }
    }, [state.loading, state.cancelLoading])

    const stepsModerator: Array<ReactourStep> = [];

    const stepsLeader: Array<ReactourStep> = [
        {
            selector: '',
            content: () => (
                <div className="hint-body"></div>
            ),
            action: () => {
                window.scrollTo(0, 0);
                dispatch(setActiveTabTeamAC('Performance'));
                navigate('/dashboard/team', {replace: true})
            }
        },
        {
            selector: ".insights_tab_leader",
            content: () => (
                <div className="hint_body">
                    As a team leader, you will receive insights that help you lead your team.
                    Insights are designed to help you get ahead of situations that can negatively impact the team
                    experience.
                </div>
            ),
        },
        {
            content: "",
            action: () => {
                dispatch(activeTabCoachingCornerAC('trends'))
            }
        },
    ];

    const stepsMember: Array<ReactourStep> = [
        {
            selector: '',
            content: () => (
                <div className="hint-body"></div>
            ),
            action: () => {
                dispatch(setActiveTabTeamAC('Performance'));
                navigate('/dashboard/team', {replace: true})
            }
        },
        {
            selector: ".insights_tab_member",
            content: () => (
                <div className="hint_body">
                    Insights are sent directly to you to help improve your performance as a team member.
                </div>
            ),
        },
        {
            content: "",
            action: () => {
                dispatch(activeTabCoachingCornerAC('trends'))
            }
        },
    ];

    const steps = currentStatus === 'isModerator' ? stepsModerator
        : currentStatus === 'isAdmin' ? stepsLeader : currentStatus === 'isMember' ? stepsMember : [];


    const addNewItem = () => {
        setItemType('insight');
        setActionType('create');
        setModalView(true);
    }

    let headerTitle = actionType === 'update' ? 'Edit' : actionType === 'create' ? 'Add' : '';

    useEffect(() => {
        dispatch(clearInsightsAndOffsetCoachingCornerAC());
        chosenTeamId && updateData();
    }, [chosenTeamId])

    useEffect(() => {
        window.addEventListener('scroll', scrollWatch);

        return (() => {
            window.removeEventListener('scroll', scrollWatch);
        })
    }, [scrollWatch])

    useEffect(() => {
        getScrollingBehaviourForTour(tourCompleted)
    }, [tourCompleted])


    return (
        <div>
            {demoMode === 'edit'  && <div style={{width: '30px', position: 'absolute', top: '10px'}}>
                {/*<ButtonWithoutImage name={'Add insight'} onClickHandler={addNewItem}/>*/}
                <InsertedImageRound height={2} width={2} image={add} handler={addNewItem} />
            </div>}

            {steps.length ? <Tour steps={steps}
                                  isOpen={!tourCompleted}
                                  onRequestClose={() => closeTour(currentStatus, navigate, dispatch)}
                                  closeWithMask={false}
                                  showNumber={false}
                                  nextButton={<span>NEXT</span>}
                                  prevButton={<span>PREV</span>}
                                  showNavigationNumber={false}
                                  disableDotsNavigation={true}
                                  disableInteraction={true}
                                  disableFocusLock={true}
                                  startAt={1}/> : null}
            {modalView && (itemType === 'insight' ?
                <ModalWindowWrapper closeModalWindow={closeModalWindow} headerTitle={`${headerTitle} insight`}>
                    {
                        (injectedProps) => (
                            <AddItemModalWIndowBody itemType={itemType}
                                                    actionType={actionType}
                                                    closeModalWindow={closeModalWindow}
                                                    {...injectedProps}/>
                        )
                    }
                </ModalWindowWrapper>
                : <ModalWindowCoachingCorner closeModalWindow={closeModalWindow}/>)
            }
            {}
            {
                insights.map((insight: InsightItemType) => <InsightItem key={insight.id} insight={insight}
                                                                        like={() => like(insight.id)}
                                                                        dislike={() => disLike(insight.id)}
                                                                        onClickItem={() => clickInsight(insight)}
                                                                        itemType={itemType}
                                                                        setItemType={setItemType}
                                                                        manageModalView={setModalView}
                                                                        setActionType={setActionType}/>)
            }
            {state.loading && <LoaderDots/>}
        </div>

    )
}

export default Insights;