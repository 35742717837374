import React from "react";
import style from './insightItem.module.scss';
import InsertedImageRound from "../../../parts/insertedImageRound/insertedImageRound";

import like from '../../../../assets/images/coachingCorner/like.png';
import like_fill from '../../../../assets/images/coachingCorner/like_fill.png';
import InsertedImage from "../../../parts/insertedImage/insertedImage";
import {InsightItemType} from "../Insights/insights";

import logo from './../../../../assets/images/coachingCorner/logo.png';
import editPencil from "../../../../assets/images/common/pencil_demo.png";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import {
    coachingCornerDeleteItemTC,
    setChosenItemInModalWindowCoachingCornerAC
} from "../../../../redux/reducers/coachingCornerReducer";

import edit from './../../../../assets/images/common/pencil_demo.png';
import remove from './../../../../assets/images/common/trash_demo.png';

export type InsightItemPropsType = {
    insight: InsightItemType,
    like: () => void,
    dislike: () => void,
    onClickItem: () => void,
    itemType: 'insight' | 'trend' | null,
    setItemType: (type: 'insight' | 'trend' | null) => void,
    manageModalView: (view: boolean) => void,
    setActionType: (type: 'create' | 'update' | null) => void,
}

const InsightItem = (props: InsightItemPropsType) => {
    const dispatch = useDispatch();
    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);

    const onClickEditButtonToChangeInsight = () => {
        props.setItemType('insight');
        props.setActionType('update');
        dispatch(setChosenItemInModalWindowCoachingCornerAC(props.insight));
        props.manageModalView(true);
    }

    const onClickTrashButtonToDeleteInsight = () => {
        dispatch(coachingCornerDeleteItemTC({id: props.insight.id, notificationType: props.insight.notificationType}, 'insight'))
    }

    return (
        <div className={style.insight_item} style={{opacity: props.insight.seen ? '.5' : '1'}}>

            <InsertedImageRound height={4.1875}
                                width={4.1875}
                                image={logo}/>

            <div className={`${style.insight_item_info} border`}>
                {demoMode === 'edit' && <div className={style.demo_buttons_wrapper}>
                    <InsertedImage width={1.5} height={1.5} image={edit} cursor={'pointer'}
                                   handler={onClickEditButtonToChangeInsight}/>
                    <InsertedImage width={1.5} height={1.5} image={remove} cursor={'pointer'}
                                   handler={onClickTrashButtonToDeleteInsight}/>
                </div>}

                <div className={style.insight_item_info_text_block}  onClick={props.onClickItem}>
                    <div>{props.insight.insightBody}</div>
                </div>

                <div className={style.insight_item_info_helpful_block}>Helpful?</div>

                <div className={style.insight_item_info_icons_block}>
                    <div className={style.insight_item_info_icons_block_time}>{props.insight.daysAgo}</div>

                    <div className={style.insight_item_info_icons_block_icons}>
                        <div className={style.insight_item_info_helpful_block_mobile}>Helpful?</div>
                        {props.insight.liked ?
                            <InsertedImage width={1.25} height={1.25} image={like_fill} cursor={'pointer'}
                                           handler={props.like}/> :
                            <InsertedImage width={1.25} height={1.25} image={like} cursor={'pointer'}
                                           handler={props.like}/>
                        }
                        {props.insight.disliked ?
                            <InsertedImage width={1.25} height={1.25} image={like_fill} cursor={'pointer'} rotate={true}
                                           handler={props.dislike} margin={'0 15px 0 0'}/> :
                            <InsertedImage width={1.25} height={1.25} image={like} cursor={'pointer'} rotate={true}
                                           handler={props.dislike} margin={'0 15px 0 0'}/>
                        }
                        <div className={'red_point'} style={{display: props.insight.seen ? 'none' : 'block'}}></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InsightItem;