import React from "react";
import style from './notificationItem.module.scss';
import {NotificationsModeType} from "../switchNotifications";
import {
    clickActionButtonMenuNotificationsTC,
    NotificationType, readNotificationMenuNotificationsReducerTC,
    sendMessageFromTextareaMenuNotificationsTC
} from "../../../../redux/reducers/notificationsMenuReducer";
import ItemWithTextarea from "./itemWithTextarea/itemWithTextarea";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import InsertedImage from "../../insertedImage/insertedImage";
import remove from './../../../../assets/images/common/trash_demo.png';
import edit from './../../../../assets/images/common/pencil_demo.png';
import {RootStateType} from "../../../../redux/store/store";
import {setAnchorElForNotificationsMenuAC} from "../../../../redux/reducers/commonReducer";

export type NotificationItemPropsType = {
    notification: NotificationType,
    index: number,
    notificationsMode: NotificationsModeType,
    changeNotificationReadFlag: (id: number) => void,
    editNotification: () => void,
    deleteNotification: () => void
}

const NotificationItem = React.memo((props: NotificationItemPropsType) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);

    const sendMessage = (teamId: number, post: { text: string, anonymously: boolean, isMotivation: boolean }) => {
        dispatch(sendMessageFromTextareaMenuNotificationsTC(teamId, post, props.notification.id, props.notification.seen, showToolTipe));
        // !props.notification.seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: props.notification.id, seen: true}}))
    }

    const buttonHandler = () => {
        let id = props.notification.id;
        let url = props.notification.buttonLink;
        let body = JSON.parse(props.notification.props);
        url && body && dispatch(clickActionButtonMenuNotificationsTC(id, props.notification.seen, url, body, showToolTipe));
        // !props.notification.seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: props.notification.id, seen: true}}))
    }

    const onMessageClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        //@ts-ignore
        if (e.target.attributes[0].nodeName === 'href') {
            let url = e.currentTarget.children[0].getAttribute('href')
            url && navigate(url);
            url && dispatch(setAnchorElForNotificationsMenuAC(null));
            // !props.notification.seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: props.notification.id, seen: true}}))
        }
    }

    const showToolTipe = () => {
        let btn = document.getElementById(`${props.notification.id}`);
        let span = btn?.children.item(0);

        // let btn = document.querySelectorAll(`.${style.btn}`)[props.index];
        // let span = document.querySelectorAll(`.${style.btn} .${style.btn_span}`)[props.index];
        let data = btn?.getAttribute("data-tooltipe");
        //@ts-ignore
        span.style.display = "block";
        //@ts-ignore
        span.innerHTML = data;
        setTimeout(() => {
            //@ts-ignore
            span.style.display = "none";
        }, 2000);
    }

    return (
        <div className={style.notification_item_wrapper}
             style={{display: props.notificationsMode === 'unread' && props.notification.seen ? 'none' : 'flex'}}>

            {demoMode === 'edit' ?
                <>
                    <div className={style.edit_button}>
                        <InsertedImage width={1.5} height={1.5} handler={props.editNotification}
                                       cursor={'pointer'} image={edit}/>
                    </div>

                    <div className={style.delete_button}>
                        <InsertedImage width={1.5} height={1.5} handler={props.deleteNotification}
                                       cursor={'pointer'} image={remove}/>
                    </div>
                </> : null
            }

            <div className={style.notification_item_wrapper_point}>
                <div className={`${props.notification.seen ? style.read_point : style.unread_point}`}
                     onClick={() => props.changeNotificationReadFlag(props.notification.id)}></div>
            </div>
            <div className={style.notification_item_wrapper_context}>
                <div className={style.header}>
                    {props.notification.icon &&
                        <div className={`${style.header_img}`} style={{backgroundColor: `${props.notification.color}`}}>
                            <img src={`${props.notification.icon}`} alt=""/>
                        </div>}
                    {props.notification.title && <div className={style.header_title}
                                                      dangerouslySetInnerHTML={{__html: props.notification.title || ''}}></div>}
                </div>
                {props.notification.message &&
                    <div className={style.text} dangerouslySetInnerHTML={{__html: props.notification.message}}
                         onClick={onMessageClick}></div>}
                {props.notification.textArea && <ItemWithTextarea notification={props.notification}
                    //placeholder={props.notification.textAreaPlaceholder}
                                                                  sendMessage={sendMessage}/>}
                {props.notification.buttonTitle && <div className={`${style.action_button}`}
                                                        style={{color: props.notification.buttonClicked ? 'grey' : '#2697FE'}}
                                                        onClick={buttonHandler} data-tooltipe={`Action completed!`}
                                                        id={`${props.notification.id}`}>
                    <span className={style.btn_span}></span>
                    <span>{props.notification.buttonTitle}</span>
                </div>}
            </div>
        </div>
    )
})

export default NotificationItem;