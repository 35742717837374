import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {authAPI} from "../../api/api";

export type SetPasswordStateType = {
    email: string,
    isLoading: boolean,
    isError: boolean,
    isSuccess: false,
    notification: string
}

const initialState: SetPasswordStateType = {
    email: '',
    isLoading: true,
    isError: false,
    isSuccess: false,
    notification: ''
}

export const setPasswordReducer = (state = initialState, action: AllActionType) => {
    switch (action.type) {
        case 'SET-PASSWORD-SET-EMAIL': {
            return {...state, email: action.email}
        }
        // case 'SET-PASSWORD-SET-LOADING': {
        //     return {...state, loading: action.loading}
        // }
        case 'SET-PASSWORD-SET-COMMON-QUERY-RESULT': {
            return {...state, ...action.result}
        }
        default: return state
    }
}

export type setEmailSetPasswordACType = ReturnType<typeof setEmailSetPasswordAC>;
export type setCommonQueryResultSetPasswordACType = ReturnType<typeof setCommonQueryResultSetPasswordAC>;
//export type setLoadingSetPasswordACType = ReturnType<typeof setLoadingSetPasswordAC>;

export const setEmailSetPasswordAC = (email: string) => ({type: 'SET-PASSWORD-SET-EMAIL', email}as const);
//export const setLoadingSetPasswordAC = (loading: boolean) => ({type: 'SET-PASSWORD-SET-LOADING', loading}as const);
export const setCommonQueryResultSetPasswordAC = (result: any) =>
    ({type: 'SET-PASSWORD-SET-COMMON-QUERY-RESULT', result}as const);


export const checkTokenTC = (token: string, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.checkToken(token).then(res => {
        dispatch(setEmailSetPasswordAC(res.data.user.email))
       dispatch(setCommonQueryResultSetPasswordAC({isLoading: false, isSuccess: true, isError: false, notification: 'Perfect! Now create a password and log in.'}))
    }).catch(error => {
        (error.response.data.status === 400) && navigate('/signIn');
      //  dispatch(setCommonQueryResultSetPasswordAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}

export const setPasswordTC = (info: {token?: string, password: string}, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.setPassword(info).then(res => {
        dispatch(setCommonQueryResultSetPasswordAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        localStorage.setItem('token', res.data.token);
        window.location.href = '/dashboard';
        //navigate('/dashboard');
    }).catch(err => {
        dispatch(setCommonQueryResultSetPasswordAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}

