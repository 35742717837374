import React, {useCallback, useEffect, useMemo, useState} from "react";
import style from './notificationConstructor.module.scss';
import Select, {SingleValue} from "react-select";
import {demoAPI} from "../../../../api/api";
import {
    DemoCheckinConstructorTeamType,
    getTeamsCheckinInConstructorDemoReducerAC
} from "../../../../redux/reducers/demoReducer";
import ButtonWithoutImage from "../../buttonWithoutImage/buttonWithoutImage";
import {
    createNotificationTC, getColorsAndIconsForNotificationsConstructorTC,
    getUsersForNotificationsConstructorTC, updateNotificationTC
} from "../../../../redux/reducers/notificationsMenuReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import {useForm} from "react-hook-form";
import notification from "../../notification/notification";

export type NotificationConstructorPropsType = {
    closeModalWindow: () => void,
    // chosenNotification: any,
    actionType: 'create' | 'update' | null,
    setError: (notification: string) => void,
    setSuccess: (notification: string) => void,
}

export type NotificationType = { value: 1, label: 'general' } | { value: 2, label: 'with textarea' }
    | { value: 3, label: 'with button' } | { value: 4, label: 'with icon' };

const NotificationConstructor = ({
                                     closeModalWindow,
                                     actionType,
                                     setError,
                                     setSuccess,
                                 }: NotificationConstructorPropsType) => {

    const dispatch = useDispatch();
    const {users, chosenNotification, colors, icons} = useSelector((state: RootStateType) => state.notificationsMenu);

    const [notificationType, setNotificationType] = useState<{ value: number, label: string }>({
        value: 1,
        label: 'general'
    });

    const allNotificationTypes: Array<NotificationType> = [{value: 1, label: 'general'}, {
        value: 2,
        label: 'with textarea'
    },
        {value: 3, label: 'with button'}, {value: 4, label: 'with icon'}];

    const onChangeNotificationType = (value: SingleValue<{ value: number, label: string }>) => {
        value && setNotificationType(value);
    }


    const [title, setTitle] = useState<string>('New notification');
    const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value);
    }

    const [seen, setSeen] = useState<boolean>(false);

    //this for users and roles selects
    const usersForOptions = users.map(u => ({value: u.value, label: u.label}));
    const [rolesForOptions, setRolesForOptions] = useState<Array<{ label: string, value: string }>>([]);

    const [chosenUser, setChosenUser] = useState<{ value: number, label: string } | null>(null);
    const [chosenRole, setChosenRole] = useState<{ value: string, label: string } | null>(null);
    const onChangeUser = useCallback((value: SingleValue<{ label: string, value: number }>) => {
        setChosenUser(value);
        let roles = users.filter(u => u.value === value?.value)[0]?.roles;
        roles && setRolesForOptions(roles);
        roles && setChosenRole(roles[0]);
    }, [users])

    const onChangeRole = (value: SingleValue<{ value: string, label: string }>) => {
        setChosenRole(value);
    }

    //message for general
    const [message, setMessage] = useState<string>('');
    const onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.currentTarget.value);
    }

    //for notification with textarea
    const [placeholder, setPlaceholder] = useState<string>('');
    const onChangePlaceholder = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPlaceholder(e.currentTarget.value);
    }

    const [anonym, setAnonym] = useState<boolean>(false);

    const [allTeams, setAllTeams] = useState<Array<{ value: number, label: string }>>([]);

    const [chosenTeam, setChosenTeam] = useState<{ value: number, label: string } | null>(null);

    const onChangeTeam = (value: SingleValue<{ value: number, label: string }>) => {
        setChosenTeam(value);
    }

    //for notification with button
    const [buttonTitle, setButtonTitle] = useState<string>('');
    const onChangeButtonTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setButtonTitle(e.currentTarget.value);
    }

    const [buttonLink, setButtonLink] = useState<string>('');
    const onChangeButtonLink = (e: React.ChangeEvent<HTMLInputElement>) => {
        setButtonLink(e.currentTarget.value);
    }

    const [clicked, setClicked] = useState<boolean>(false);

    const isDisabledButton = !title.trim().length || !chosenUser;

    //for notification with icon
    const [chosenColor, setChosenColor] = useState<{ value: string, label: string } | null>(null);
    const [chosenIcon, setChosenIcon] = useState<{ value: string, label: string } | null>(null);

    const onChangeIcon = useCallback((value: SingleValue<{ label: string, value: string }>) => {
        setChosenIcon(value);
        let defaultColor = colors.find(c => c.label === 'default');
        defaultColor && value?.label === 'UnboXt Logo' && setChosenColor(defaultColor);
    }, [colors]);

    const onChangeColor = (value: SingleValue<{ label: string, value: string }>) => {
        setChosenColor(value);
    }


    const createNotification = () => {
        let messageText = message.trim().replace(/\n/ig, ' ');

        let answer = {
            notification: notificationType.value,
            title,
            seen,
            user: chosenUser?.value,
            role: chosenRole?.value,
            message: notificationType.label === 'general' || notificationType.label === 'with icon' ? messageText : null,
            textArea: notificationType.label === 'with textarea',
            textAreaPlaceholder: notificationType.label === 'with textarea' ? placeholder : null,
            textAreaAnonymous: notificationType.label === 'with textarea' ? anonym : null,
            teamId: notificationType.label === 'with textarea' ? chosenTeam?.value : null,
            buttonTitle: notificationType.label === 'with button' ? buttonTitle : null,
            buttonLink: notificationType.label === 'with button' ? buttonLink : null,
            buttonClicked: notificationType.label === 'with button' ? clicked : null,
            color: notificationType.label === 'with icon' ? chosenColor?.label.toLowerCase() : null,
            icon: notificationType.label === 'with icon' ? chosenIcon?.value : null,
        }

        actionType === 'create' ? dispatch(createNotificationTC(answer, setError, setSuccess)) :
            dispatch(updateNotificationTC({...answer, id: chosenNotification?.id}, setError, setSuccess));
    }

    useEffect(() => {
        dispatch(getUsersForNotificationsConstructorTC());
        dispatch(getColorsAndIconsForNotificationsConstructorTC());

        demoAPI.getALlTeamsCheckinsConstructor().then(res => {
            let teams = res.data.teams.map((t: DemoCheckinConstructorTeamType) => ({value: t.id, label: t.name}))
            setAllTeams(teams);
            setChosenTeam(teams[0]);
        }).catch((err) => {
            setError('wrong getting teams')
        })
    }, [])

    // //when getting colors and icons
    // useEffect(() => {
    //
    // }, [colors, icons])

    useEffect(() => {
        if (chosenNotification) {
            //тип нотификации выбранной
            let initialNotificationType = chosenNotification?.textArea ? {value: 2, label: 'with textarea'} :
                chosenNotification?.buttonTitle ? {value: 3, label: 'with button'} : chosenNotification?.icon ? {value: 4, label: 'with icon'}
                    : {value: 1, label: 'general'};

            setNotificationType(initialNotificationType);

            //титл выбранной нотификашки
            chosenNotification?.title && setTitle(chosenNotification.title);
            //статус просмотрено или нет
            setSeen(chosenNotification?.seen);
            //инитиал роль и юзер
            let initialChosenUser = usersForOptions.filter(u => u.value === chosenNotification?.userId)[0];
            initialChosenUser && setChosenUser(initialChosenUser);

            let roles = users.filter(u => u.value === chosenNotification?.userId)[0]?.roles;
            setRolesForOptions(roles);
            let initialRole = roles?.find((r: {
                value: string,
                label: string
            }) => r.value === chosenNotification?.role);
            setChosenRole(initialRole);
            //for general
            chosenNotification?.message && setMessage(chosenNotification?.message);
            //for textarea
            let initialPlaceHolder = chosenNotification?.textAreaPlaceholder;
            initialPlaceHolder?.length && setPlaceholder(initialPlaceHolder);

            chosenNotification?.textAreaAnonymous && setAnonym(chosenNotification?.textAreaAnonymous);

            let initialChosenTeam = allTeams.filter(u => u.value === chosenNotification?.teamId)[0];
            initialChosenTeam && setChosenTeam(initialChosenTeam);

            //for with button
            chosenNotification?.buttonTitle && setButtonTitle(chosenNotification?.buttonTitle);
            chosenNotification?.buttonLink && setButtonLink(chosenNotification?.buttonLink);
            chosenNotification?.buttonClicked && setClicked(chosenNotification?.buttonClicked);

            //for with icon
            let initialColor =  colors.find(c => c.value === chosenNotification?.color);
            let initialIcon = icons.find(i => i.value === chosenNotification?.icon);
            initialColor && setChosenColor(initialColor);
            initialIcon && setChosenIcon(initialIcon);

        }
    }, [chosenNotification?.id, allTeams, users, colors, icons])

    useEffect(() => {
        let tx = document.getElementsByTagName("textarea");
        let arr = Array.from(tx);
        arr?.map(i => i.setAttribute("style", "height:" + (i.scrollHeight) + "px;overflow-y:hidden;"))
        arr.map(i => i.addEventListener('input', OnInput, false));


        function OnInput() {
            arr.map(i => {
                i.style.height = "auto";
                i.style.resize = "none";
                i.style.height = (i.scrollHeight) + "px";
            })
        }
    }, [notificationType])

    return (
        <div className={style.constructor_wrapper}>

            <div className={style.input_wrapper}>
                <label>Notification type: </label>
                <Select classNamePrefix="customSelect"
                        value={notificationType}
                        options={allNotificationTypes}
                        isSearchable={false}
                        onChange={onChangeNotificationType}/>
            </div>


            <div className={style.input_wrapper}>
                <label>Title: </label>
                <input type="text"
                       style={{width: '100%'}}
                       value={title}
                       placeholder={'new title...'}
                       onChange={onChangeTitle}/>
            </div>

            <div className={style.input_wrapper}>
                <label>Notification status: </label>
                <div className={style.inputs_group_wrapper}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <input type="radio"
                               id='seenNotification'
                               onChange={() => setSeen(true)}
                               checked={seen}
                               name={'status'}
                               value={'seen'}/>
                        <label htmlFor='seenNotification' style={{cursor: 'pointer', fontSize: '.78rem'}}>Read</label>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <input type="radio"
                               id='unseenNotification'
                               onChange={() => setSeen(false)}
                               checked={!seen}
                               name={'status'}
                               value={'unseen'}/>
                        <label htmlFor='unseenNotification'
                               style={{cursor: 'pointer', fontSize: '.78rem'}}>Unread</label>
                    </div>

                </div>
            </div>


            <div className={style.inputs_group_wrapper}>
                <div className={style.input_wrapper}>
                    <label>Select user: </label>
                    <Select classNamePrefix="customSelect"
                            value={chosenUser}
                            options={usersForOptions}
                            isSearchable={false}
                            onChange={onChangeUser}/>
                </div>

                <div className={style.input_wrapper}>
                    <label>Select user role: </label>
                    <Select classNamePrefix="customSelect"
                            value={chosenRole}
                            options={rolesForOptions}
                            isSearchable={false}
                            onChange={onChangeRole}/>
                </div>
            </div>

            {/*for general and icon*/}
            {
                notificationType.label === 'general' || notificationType.label === 'with icon' ?
                    <div className={style.input_wrapper}>
                        <label>Message: </label>
                        <textarea style={{width: '100%'}}
                                  value={message}
                                  placeholder={'new message...'}
                                  onChange={onChangeMessage}/>
                    </div> : null
            }

            {/* for notification with textarea*/}
            {
                notificationType.label === 'with textarea' ?
                    <>

                        <div className={style.input_wrapper}>
                            <label>Placeholder: </label>
                            <input type="text"
                                   style={{width: '100%'}}
                                   value={placeholder}
                                   placeholder={'new placeholder...'}
                                   onChange={onChangePlaceholder}/>
                        </div>


                        <div className={style.input_wrapper}>
                            <label>Anonymity status: </label>
                            <div className={style.inputs_group_wrapper}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <input type="radio"
                                           id='anonymously'
                                           onChange={() => setAnonym(true)}
                                           checked={anonym}
                                           name={'anonym'}
                                           value={'anonymously'}/>
                                    <label htmlFor='anonymously'
                                           style={{cursor: 'pointer', fontSize: '.78rem'}}>Anonymously</label>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <input type="radio"
                                           id='non-anonymously'
                                           onChange={() => setAnonym(false)}
                                           checked={!anonym}
                                           name={'anonym'}
                                           value={'non-anonymously'}/>
                                    <label htmlFor='non-anonymously'
                                           style={{cursor: 'pointer', fontSize: '.78rem'}}>Non-anonymously</label>
                                </div>

                            </div>
                        </div>

                        <div className={style.input_wrapper}>
                            <label>Select team: </label>
                            <Select classNamePrefix="customSelect"
                                    value={chosenTeam}
                                    options={allTeams}
                                    isSearchable={false}
                                    onChange={onChangeTeam}/>
                        </div>

                    </> : null
            }
            {/* for notification with button */}
            {
                notificationType.label === 'with button' ?
                    <>
                        <div className={style.inputs_group_wrapper}>
                            <div className={style.input_wrapper}>
                                <label>Button title: </label>
                                <input type="text"
                                       style={{width: '100%'}}
                                       value={buttonTitle}
                                       placeholder={'new button title...'}
                                       onChange={onChangeButtonTitle}/>
                            </div>

                            <div className={style.input_wrapper}>
                                <label>Button link: </label>
                                <input type="text"
                                       style={{width: '100%'}}
                                       value={buttonLink}
                                       placeholder={'new button link...'}
                                       onChange={onChangeButtonLink}/>
                            </div>
                        </div>

                        <div className={style.input_wrapper}>
                            <label>Button status: </label>
                            <div className={style.inputs_group_wrapper}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <input type="radio"
                                           id='clicked'
                                           onChange={() => setClicked(true)}
                                           checked={clicked}
                                           name={'clicked'}
                                           value={'clicked'}/>
                                    <label htmlFor='clicked'
                                           style={{cursor: 'pointer', fontSize: '.78rem'}}>Clicked</label>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <input type="radio"
                                           id='not-clicked'
                                           onChange={() => setClicked(false)}
                                           checked={!clicked}
                                           name={'clicked'}
                                           value={'not-clicked'}/>
                                    <label htmlFor='not-clicked'
                                           style={{cursor: 'pointer', fontSize: '.78rem'}}>Not-clicked</label>
                                </div>

                            </div>
                        </div>
                    </> : null
            }

            {/*for notification with icon*/}
            {notificationType.label === 'with icon' ?
                    <div className={style.inputs_group_wrapper}>
                        <div className={style.input_wrapper}>
                            <label>Select icon: </label>
                            <Select classNamePrefix="customSelect"
                                    value={chosenIcon}
                                    options={icons}
                                    isSearchable={false}
                                    onChange={onChangeIcon}/>
                        </div>

                        {chosenIcon?.label !== 'UnboXt Logo' ? <div className={style.input_wrapper}>
                            <label>Select color: </label>
                            <Select classNamePrefix="customSelect"
                                    value={chosenColor}
                                    options={colors}
                                    isSearchable={false}
                                    onChange={onChangeColor}/>
                        </div> : null}
                    </div> : null}

            <div className={style.button_wrapper}>
                <ButtonWithoutImage name={actionType === 'create' ? 'Create' : 'Edit'}
                                    onClickHandler={createNotification} disabled={isDisabledButton}/>
            </div>

        </div>
    )
}

export default NotificationConstructor;