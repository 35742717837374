import {AllActionType} from "../../types";
import {Dispatch} from "redux";
import {checkinReport, onboardingAPI} from "../../api/api";
import {setCurrentStatusAC, setRolesAC} from "./commonReducer";
import {CompanyStepAnswerType} from "../../components/pages/onboaring/stepCompany/stepCompany";
import {StepYoyAnswerType} from "../../components/pages/onboaring/stepYou/stepYou";
import {StepTeamAnswerType} from "../../components/pages/onboaring/stepTeam/stepTeam";
import {AppThunk} from "../store/store";
import {StepDepartmentAnswerType} from "../../components/pages/onboaring/stepDepartment/stepDepartment";
import {v1} from "uuid";
import {addAlertMessageCommonReducerAC} from "./commonReducer";
import {errorAlertItem} from "../../common";

export type OnboardingStepType = 'you' | 'team' | 'values' | 'motivators' | 'assessment';
export type MotivatorsStepType =
    'best work'
    | 'being motivated'
    | 'recommend'
    | 'onboarding experience'
    | 'additional questions';

export type YouInfoType = {
    birthDate?: string | null,
    getMembersBirthdays?: boolean,
    hireDate?: string | null,
    userHopes?: Array<{ id: number, options: string }> | [],
    seniorityId?: number | null,
    shareBirthday?: boolean,
    yearsOfLeadershipId?: number | null,
    yearsOfLeadership?: Array<{ id: number, name: string }>,
    hopes?: Array<{ id: number, name: string }>,
    seniorities?: Array<{ id: number, name: string }>,
    isNewHire?: boolean | null,

}

export type CompanyInfoType = {
    companyIndustryId?: number | null | undefined,
    companySizeId?: number | null,
    companyId?: number | null,
    companyName?: string | null,
    superAdminList?: Array<string> | null,
    companyValues?: Array<{ name: string, description: string }> | null,
    companyIndustries?: Array<{ id: number, name: string }>,
    companySizes?: Array<{ id: number, name: string }>
}

export type DepartmentInfoType = {
    id?: number,
    name?: string | null,
    perfomingScores?: Array<{id: number, name: string}>,
    perfomingScoreId?: number | null,
}

export type TeamInfoType = {
    departments?: Array<{id: number, name: string}>,
    departmentName?: string,
    frequencies?: Array<{id: number, name: string}>,
    perfomingScores?: Array<{id: number, name: string}>,
    //res.data.commonSelect
    checkinDay?: number | null,
    departmentId?: number | null,
    frequencyId?: number | null,
    id?: number | null,
    invites?: Array<string> | [],
    name?: string | null,
    perfomingScoreId?: number | null,
    surveysTimeSend?: string | null,
    workingDays?: Array<boolean> | null,
}

export type ValuesInfoType = {
    norms?: Array<{id: number, name: string, description: string}>,
    userNorms?: Array<number> | []
}

export type MotivatorsInfoType = {
    company?: string,
    baselines?: Array<{id: number, question: string}>,
    companyExperience?: any,
    isNewHire?: boolean | null,
    motivators?: Array<{id: number, name: string}>,
    onboardingExperience?: any,
    userBaselines?: Array<{answer: number | null, baselineQuestionId: number}> | null,
    userPriorities?: Array<{id: number}> | [],
    reasons?: Array<{id: number, title: string}>,
    userRatings?: Array<{id: number}> | [],
    userReason?: {id?: number, options?: string} | null,
    score?: number,
    text?: string,
    bestWorkPrioritiesIsChanged?: boolean,
    beingMotivatedPrioritiesIsChanged?: boolean,
}

export type OnboardingType = {
    // onboardingStep: OnboardingStepType,
    loading: boolean,
    onboardingCompleted: boolean | null,
    onboardingStep: number,
    onboardingMotivatorsStep: number,
    userSteps: Array<number>,
    role: string,
    stepYou?: YouInfoType | null | undefined,
    stepCompany?: CompanyInfoType | null,
    stepDepartment?: DepartmentInfoType | null,
    stepTeam?: TeamInfoType | null,
    stepValues?: ValuesInfoType | null,
    stepMotivators?: MotivatorsInfoType | null,
    stepAssessment: any
}

const initialState: OnboardingType = {
    // onboardingStep: 'you',
    loading: false,
    onboardingCompleted: null,
    onboardingStep: 1,
    onboardingMotivatorsStep: 1,
    userSteps: [1, 2, 3, 4, 5, 6, 7],
    role: '',
    stepYou: null,
    stepCompany: null,
    stepDepartment: null,
    stepTeam: null,
    stepValues: null,
    stepMotivators: null,
    stepAssessment: {}
}


export const onboardingReducer = (state: OnboardingType = initialState, action: AllActionType): OnboardingType => {
    switch (action.type) {
        //устанавливаем шаг
        case "SET-ONBOARDING-STEP": {
            return {...state, onboardingStep: action.step}
        }
        case 'SET-ONBOARDING-ROLES': {
            return {...state, role: action.role, onboardingCompleted: action.completed}
        }
        case 'SET-ONBOARDING-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'SET-ONBOARDING-COMPLETED': {
            return {...state, onboardingCompleted: action.onboardingCompleted}
        }
        case 'SET-USER-STEPS': {
            return {...state, userSteps: [...action.steps]}
        }
        case 'SET-ONBOARDING-MOTIVATORS-STEP': {
            return {...state, onboardingMotivatorsStep: action.step}
        }
        case "SET-YOU-STEP": {
            return {...state, stepYou: {...action.youInfo}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-HIRE': {
            return {...state, stepYou: {...state.stepYou, isNewHire: action.hire}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-HIREDATE': {
            return {...state, stepYou: {...state.stepYou, hireDate: action.date}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-SENIORITY-ID': {
            return {...state, stepYou: {...state.stepYou, seniorityId: action.id}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-LEADERSHIP-ID': {
            return {...state, stepYou: {...state.stepYou, yearsOfLeadershipId: action.id}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-USER-HOPES': {
            return {...state, stepYou: {...state.stepYou, userHopes: [...action.userHopes]}}
        }
        case 'ONBOARDING-STEP-YOU-SET-BIRTH-DATE': {
            return {...state, stepYou: {...state.stepYou, birthDate: action.date}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-SHAREBIRTHDAY': {
            return {...state, stepYou: {...state.stepYou, shareBirthday: action.share}}
        }
        case 'ONBOARDING-STEP-YOU-CHANGE-MEMBERSBIRTHDAY': {
            return {...state, stepYou: {...state.stepYou, getMembersBirthdays: action.get}}
        }
        case 'ONBOARDING-STEP-YOU-SAVE-ANSWER': {
            return {...state, stepYou: {...state.stepYou, ...action.answer}}
        }
        case "SET-COMPANY-STEP": {
            return {...state, stepCompany: {...action.companyInfo}}
        }
        case "SET-COMPANY-INDUSTRY-ID": {
            return {...state, stepCompany: {...state.stepCompany, companyIndustryId: action.id}}
        }
        case "SET-COMPANY-SIZED-ID": {
            return {...state, stepCompany: {...state.stepCompany, companySizeId: action.id}}
        }
        case "SET-COMPANY-STEP-ANSWER": {
            return {
                ...state, stepCompany: {
                    ...state.stepCompany, companySizeId: action.answer.companySizeId,
                    companyIndustryId: action.answer.companyIndustryId, superAdminList: action.answer.superAdminList,
                    companyValues: [...action.answer.norms]
                }
            }
        }
        case 'SET-DEPARTMENT-STEP': {
            return {...state, stepDepartment: {...action.departmentInfo}}
        }
        case 'ONBOARDING-STEP-DEPARTMENT-CHANGE-PERFORMING-ID': {
            return {...state, stepDepartment: {...state.stepDepartment, perfomingScoreId: action.id}}
        }
        case 'ONBOARDING-STEP-DEPARTMENT-SET-ANSWER': {
            return {...state, stepDepartment: {...state.stepDepartment, ...action.answer}}
        }
        case 'SET-TEAM-STEP': {
            return {...state, stepTeam: {...action.teamInfo}}
        }
        case 'ONBOARDING-STEP-TEAM-CHANGE-DEPARTMENT-ID': {
            return {...state, stepTeam: {...state.stepTeam, departmentId: action.id}}
        }
        case 'ONBOARDING-STEP-TEAM-CHANGE-PERFORMING-ID': {
            return {...state, stepTeam: {...state.stepTeam, perfomingScoreId: action.id}}
        }
        case 'ONBOARDING-STEP-TEAM-CHANGE-CHECKINDAY': {
            return {...state, stepTeam: {...state.stepTeam, checkinDay: action.id}}
        }
        case 'ONBOARDING-STEP-TEAM-CHANGE-FREQUENCY-ID': {
            return {...state, stepTeam: {...state.stepTeam, frequencyId: action.id}}
        }
        case 'ONBOARDING-STEP-TEAM-CHANGE-WORKING-DAYS': {
            return {...state, stepTeam: {...state.stepTeam, workingDays: action.days}}
        }
        case 'ONBOARDING-STEP-TEAM-CHANGE-TIME-SEND' : {
            return {...state, stepTeam: {...state.stepTeam, surveysTimeSend: action.time}}
        }
        case 'ONBOARDING-STEP-TEAM-SET-ANSWER': {
            return {...state, stepTeam: {...state.stepTeam, ...action.answer}}
        }
        case 'SET-VALUES-STEP': {
            return {...state, stepValues: {...action.valuesInfo}}
        }
        case 'ONBOARDING-STEP-VALUES-CHANGE-USER-VALUES': {
            return {...state, stepValues: {...state.stepValues, userNorms: [...action.id]}}
        }
        case 'SET-MOTIVATORS-STEP': {
            return {...state, stepMotivators: {...action.motivatorsInfo}}
        }
        case 'SET-MOTIVATORS-STEP-USER-RATES': {
            return {...state, stepMotivators: {...state.stepMotivators, userRatings: [...action.rates]}}
        }
        case 'SET-MOTIVATORS-STEP-USER-PRIORITIES': {
            return {...state, stepMotivators: {...state.stepMotivators, userPriorities: [...action.priorities]}}
        }
        case 'SET-MOTIVATORS-STEP-USER-BASELINES': {
            return {...state, stepMotivators: {...state.stepMotivators, userBaselines: [...action.baselines]}}
        }
        case 'SET-MOTIVATORS-STEP-SCORE': {
          return  {...state, stepMotivators: {...state.stepMotivators, score: action.score}}
        }
        case 'SET-MOTIVATORS-STEP-USER-REASON-ID': {
            return {...state, stepMotivators: {...state.stepMotivators, userReason: {...state.stepMotivators?.userReason, id: action.id}}}
        }
        case 'SET-MOTIVATORS-STEP-USER-REASON-TEXT': {
            return {...state, stepMotivators: {...state.stepMotivators, userReason: {...state.stepMotivators?.userReason, options: action.text}}}
        }
        case 'SET-MOTIVATORS-STEP-USER-MAIN-TEXT': {
            return {...state, stepMotivators: {...state.stepMotivators, text: action.text}}
        }
        case 'SET-BEST-WORK-PRIORITIES-IS-CHANGED-MOTIVATORS-STEP': {
            return {...state, stepMotivators: {...state.stepMotivators, bestWorkPrioritiesIsChanged: action.isChanged}}
        }
        case 'SET-BEING-MOTIVATED-PRIORITIES-IS-CHANGED-MOTIVATORS-STEP': {
            return {...state, stepMotivators: {...state.stepMotivators, beingMotivatedPrioritiesIsChanged: action.isChanged}}
        }
        default:
            return state;
    }
}

export type setOnboardingLoadingACType = ReturnType<typeof setOnboardingLoadingAC>;

export type setOnboardingCompletedACType = ReturnType<typeof setOnboardingCompletedAC>;

export type setOnboardingStepACType = ReturnType<typeof setOnboardingStepAC>;

export type setOnboardingRolesACType = ReturnType<typeof setOnboardingRolesAC>;

export type setUserStepsACType = ReturnType<typeof setUserStepsAC>;
export type setOnboardingMotivatorsStepACType = ReturnType<typeof setOnboardingMotivatorsStepAC>;

export type setStepYouInfoACType = ReturnType<typeof setStepYouInfoAC>;
export type changeHireACType = ReturnType<typeof changeHireAC>;
export type changeHireDateACType = ReturnType<typeof changeHireDateAC>;
export type changeSeniorityIdACType = ReturnType<typeof changeSeniorityIdAC>;
export type changeLeadershipIdACType = ReturnType<typeof changeLeadershipIdAC>;
export type changeUserHopesACType = ReturnType<typeof changeUserHopesAC>;
export type changeShareBirthdayACType = ReturnType<typeof changeShareBirthdayAC>;
export type changeGetMembersBirthdaysACType = ReturnType<typeof changeGetMembersBirthdaysAC>;
export type setAnswerStepYouACType = ReturnType<typeof setAnswerStepYouAC>;
export type setBirthDateACType = ReturnType<typeof setBirthDateAC>;

export type setStepCompanyInfoACType = ReturnType<typeof setStepCompanyInfoAC>;
export type setCompanyIndustryIdACType = ReturnType<typeof setCompanyIndustryIdAC>;
export type setCompanySizedIdACType = ReturnType<typeof setCompanySizedIdAC>;
export type setCompanyStepAnswerACType = ReturnType<typeof setCompanyStepAnswerAC>;

export type setStepDepartmentInfoACType = ReturnType<typeof setStepDepartmentInfoAC>;
export type changePerformingIdStepDepartmentACType =  ReturnType<typeof changePerformingIdStepDepartmentAC>;
export type setDepartmentStepAnswerACType = ReturnType<typeof setDepartmentStepAnswerAC>;

export type setStepTeamInfoACType = ReturnType<typeof setStepTeamInfoAC>;
export type changeDepartmentIdACType = ReturnType<typeof changeDepartmentIdAC>;
export type changePerformingIdACType = ReturnType<typeof changePerformingIdAC>;
export type changeCheckinDayACType = ReturnType<typeof changeCheckinDayAC>;
export type changeFrequencyIdACType = ReturnType<typeof changeFrequencyIdAC>;
export type changeWorkingDaysACType = ReturnType<typeof changeWorkingDaysAC>;
export type changeTimeSendACType = ReturnType<typeof changeTimeSendAC>;
export type setTeamStepAnswerACType = ReturnType<typeof setTeamStepAnswerAC>;

export type setStepValuesInfoACType = ReturnType<typeof setStepValuesInfoAC>;
export type changeUserValuesStepValuesACType = ReturnType<typeof changeUserValuesStepValuesAC>;

export type setStepMotivatorsInfoACType = ReturnType<typeof setStepMotivatorsInfoAC>;
export type setStepMotivatorsUserRatesACType = ReturnType<typeof setStepMotivatorsUserRatesAC>;
export type setStepMotivatorsUserPrioritiesACType = ReturnType<typeof setStepMotivatorsUserPrioritiesAC>;
export type setStepMotivatorsUserBaselinesACType = ReturnType<typeof setStepMotivatorsUserBaselinesAC>;
export type setStepMotivatorsScoreACType = ReturnType<typeof setStepMotivatorsScoreAC>;
export type setStepMotivatorsUserReasonIdACType = ReturnType<typeof setStepMotivatorsUserReasonIdAC>;
export type setStepMotivatorsUserReasonTextACType = ReturnType<typeof setStepMotivatorsUserReasonTextAC>;
export type setStepMotivatorsUserMainTextACType = ReturnType<typeof setStepMotivatorsUserMainTextAC>;
export type setBestWorkPrioritiesIsChangedMotivatorsStepACType = ReturnType<typeof setBestWorkPrioritiesIsChangedMotivatorsStepAC>;
export type setBeingMotivatedPrioritiesIsChangedMotivatorsStepACType = ReturnType<typeof setBeingMotivatedPrioritiesIsChangedMotivatorsStepAC>;

export const setOnboardingLoadingAC = (loading: boolean) => ({type: 'SET-ONBOARDING-LOADING', loading} as const);

export const setOnboardingCompletedAC = (onboardingCompleted: boolean) => ({type: 'SET-ONBOARDING-COMPLETED', onboardingCompleted} as const);


export const setOnboardingStepAC = (step: number) => ({type: 'SET-ONBOARDING-STEP', step} as const);

export const setOnboardingMotivatorsStepAC = (step: number) => ({type: 'SET-ONBOARDING-MOTIVATORS-STEP', step} as const);


export const setUserStepsAC = (steps: Array<number>) => ({type: 'SET-USER-STEPS', steps} as const);


//ДЛЯ STEP YOU
export const setOnboardingRolesAC = (role: string, completed: boolean) => ({type: 'SET-ONBOARDING-ROLES', role, completed} as const);
export const setStepYouInfoAC = (youInfo: YouInfoType) => ({type: 'SET-YOU-STEP', youInfo} as const);
export const changeHireAC = (hire: boolean) => ({type: 'ONBOARDING-STEP-YOU-CHANGE-HIRE', hire} as const);
export const changeHireDateAC = (date: string) => ({type: 'ONBOARDING-STEP-YOU-CHANGE-HIREDATE', date} as const);
export const changeSeniorityIdAC = (id: number) => ({type: 'ONBOARDING-STEP-YOU-CHANGE-SENIORITY-ID', id} as const);
export const changeLeadershipIdAC = (id: number) => ({type: 'ONBOARDING-STEP-YOU-CHANGE-LEADERSHIP-ID', id} as const);
export const changeUserHopesAC = (userHopes: Array<{ id: number, options: string }> | []) => ({
    type: 'ONBOARDING-STEP-YOU-CHANGE-USER-HOPES',
    userHopes
} as const);
export const changeShareBirthdayAC = (share: boolean) => ({
    type: 'ONBOARDING-STEP-YOU-CHANGE-SHAREBIRTHDAY',
    share
} as const);
export const changeGetMembersBirthdaysAC = (get: boolean) => ({
    type: 'ONBOARDING-STEP-YOU-CHANGE-MEMBERSBIRTHDAY',
    get
} as const);
export const setAnswerStepYouAC = (answer: StepYoyAnswerType) => ({
    type: 'ONBOARDING-STEP-YOU-SAVE-ANSWER',
    answer
} as const);
export const setBirthDateAC = (date: string) => ({type: 'ONBOARDING-STEP-YOU-SET-BIRTH-DATE', date}as const);


//для STEP COMPANY
export const setStepCompanyInfoAC = (companyInfo: CompanyInfoType) => ({
    type: 'SET-COMPANY-STEP',
    companyInfo
} as const);
export const setCompanyIndustryIdAC = (id: number) => ({type: 'SET-COMPANY-INDUSTRY-ID', id} as const);
export const setCompanySizedIdAC = (id: number) => ({type: 'SET-COMPANY-SIZED-ID', id} as const);
export const setCompanyStepAnswerAC = (answer: CompanyStepAnswerType) => ({
    type: 'SET-COMPANY-STEP-ANSWER',
    answer
} as const);

//ДЛЯ STEP DEPARTMENT
export const setStepDepartmentInfoAC = (departmentInfo: DepartmentInfoType) => ({type: 'SET-DEPARTMENT-STEP', departmentInfo}as const);
export const changePerformingIdStepDepartmentAC = (id: number) => ({type: 'ONBOARDING-STEP-DEPARTMENT-CHANGE-PERFORMING-ID', id} as const);
export const setDepartmentStepAnswerAC = (answer: StepDepartmentAnswerType) => ({type: 'ONBOARDING-STEP-DEPARTMENT-SET-ANSWER', answer}as const);



//для STEP TEAM
export const setStepTeamInfoAC = (teamInfo: TeamInfoType) => ({type: 'SET-TEAM-STEP', teamInfo} as const);
export const changePerformingIdAC = (id: number) => ({type: 'ONBOARDING-STEP-TEAM-CHANGE-PERFORMING-ID', id} as const);
export const changeCheckinDayAC = (id: number | null) => ({type: 'ONBOARDING-STEP-TEAM-CHANGE-CHECKINDAY', id}as const);
export const changeDepartmentIdAC = (id: number | null) => ({type: 'ONBOARDING-STEP-TEAM-CHANGE-DEPARTMENT-ID', id}as const);
export const changeFrequencyIdAC = (id: number | null) => ({type: 'ONBOARDING-STEP-TEAM-CHANGE-FREQUENCY-ID', id}as const);
export const changeWorkingDaysAC = (days: Array<boolean> | []) => ({type: 'ONBOARDING-STEP-TEAM-CHANGE-WORKING-DAYS', days}as const);
export const changeTimeSendAC = (time: string) => ({type: 'ONBOARDING-STEP-TEAM-CHANGE-TIME-SEND', time}as const);
export const setTeamStepAnswerAC = (answer: StepTeamAnswerType) => ({type: 'ONBOARDING-STEP-TEAM-SET-ANSWER', answer}as const);

//для STEP VALUES
export const setStepValuesInfoAC = (valuesInfo: ValuesInfoType) => ({type: 'SET-VALUES-STEP', valuesInfo}as const);
export const changeUserValuesStepValuesAC = (id: Array<number>) => ({type: 'ONBOARDING-STEP-VALUES-CHANGE-USER-VALUES', id}as const);


//для STEP MOTIVATORS
export const setStepMotivatorsInfoAC = (motivatorsInfo: MotivatorsInfoType) => ({type: 'SET-MOTIVATORS-STEP', motivatorsInfo} as const);
export const setStepMotivatorsUserRatesAC = (rates: Array<{id: number}>) => ({type: 'SET-MOTIVATORS-STEP-USER-RATES', rates}as const);
export const setStepMotivatorsUserPrioritiesAC = (priorities: Array<{id: number}>) => ({type: 'SET-MOTIVATORS-STEP-USER-PRIORITIES', priorities}as const);
export const setStepMotivatorsUserBaselinesAC = (baselines: Array<{baselineQuestionId: number, answer: number | null}>) => ({type: 'SET-MOTIVATORS-STEP-USER-BASELINES', baselines}as const);
export const setStepMotivatorsScoreAC = (score: number) => ({type: 'SET-MOTIVATORS-STEP-SCORE', score} as const);
export const setStepMotivatorsUserReasonIdAC = (id: number) => ({type: 'SET-MOTIVATORS-STEP-USER-REASON-ID', id} as const);
export const setStepMotivatorsUserReasonTextAC = (text: string) => ({type: 'SET-MOTIVATORS-STEP-USER-REASON-TEXT', text} as const);
export const setStepMotivatorsUserMainTextAC = (text: string) => ({type: 'SET-MOTIVATORS-STEP-USER-MAIN-TEXT', text} as const);
export const setBestWorkPrioritiesIsChangedMotivatorsStepAC = (isChanged: boolean) => ({type: 'SET-BEST-WORK-PRIORITIES-IS-CHANGED-MOTIVATORS-STEP', isChanged}as const);
export const setBeingMotivatedPrioritiesIsChangedMotivatorsStepAC = (isChanged: boolean) => ({type: 'SET-BEING-MOTIVATED-PRIORITIES-IS-CHANGED-MOTIVATORS-STEP', isChanged}as const);


//НА ГЛАВНОМ ОНБОРДИНГЕ
export const getOnboardingState = ():AppThunk =>  (dispatch) => {
    dispatch(setOnboardingLoadingAC(true));
    dispatch(getOnboardingRoles());
}

/////////////////////////THUNKS STEP YOU
//это thunk creator получает роли е сетает одну в стэйт
export const getOnboardingRoles = ():AppThunk => async (dispatch) => {

    onboardingAPI.getRoles().then(res => {

        let objRoles = res.data;
        let role = '';
        for (let key in objRoles) {
            if (objRoles[key]) {
                role = key;
            } else continue
        }




        let completed = res.data.onboardingCompleted;
        dispatch(setOnboardingRolesAC(role, completed));
       // dispatch(setOnboardingCompletedAC(completed));

        let dispatches: any[] = [];

        if(role === 'isCompanyCreator') {
            dispatches = [
                dispatch(getInfoStepYou()),
                dispatch(getInfoStepCompany()),
                dispatch(getNorms()),
                dispatch(getInfoStepMotivators())
            ];
        }else if(role === 'isDepartmentAdmin') {
            dispatches = [
                dispatch(getInfoStepYou()),
                dispatch(getInfoStepDepartment()),
                dispatch(getNorms()),
                dispatch(getInfoStepMotivators())
            ];
        } else if(role === 'isMember') {
            dispatches = [
                dispatch(getInfoStepYou()),
                dispatch(getNorms()),
                dispatch(getInfoStepMotivators()),
                Promise.resolve()
            ];
        } else {
            dispatches = [
                dispatch(getInfoStepYou()),
                dispatch(getInfoStepTeam()),
                dispatch(getNorms()),
                dispatch(getInfoStepMotivators())
            ];
        }
           // Promise.allSettled(dispatches);
        dispatches[0].then(() => dispatches[1].then(() => dispatches[2].then(() => dispatches[3])));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}


export const getInfoStepYou = () => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.getInfoStepYou().then(res => {

        let youInfo: YouInfoType = {
            //это из res.data.userInfo
            birthDate: res.data.userInfo.birthDate,
            getMembersBirthdays: res.data.userInfo.getMembersBirthdays,
            hireDate: res.data.userInfo.hireDate,
            userHopes: res.data.userInfo.hopes,
            seniorityId: res.data.userInfo.seniorityId,
            shareBirthday: res.data.userInfo.shareBirthday,
            yearsOfLeadershipId: res.data.userInfo.yearsOfLeadershipId,
            isNewHire: res.data.userInfo.isNewHire,
            //это из res.data
            yearsOfLeadership: res.data.yearsOfLeadership,
            hopes: res.data.hopes,
            seniorities: res.data.seniorities,

        }

        dispatch(setStepYouInfoAC(youInfo));
        //getInfoStepCompany();
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })

}


export const sendAnswerStepYou = (answer: StepYoyAnswerType, goNextStep: () => void) => async (dispatch: Dispatch<AllActionType>) => {

    onboardingAPI.sendAnswerYouStep(answer).then((res) => {
        goNextStep();
        window.scrollTo(0, 0);
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

//////////////////////////////////THUNKS STEP COMPANY
export const getInfoStepCompany = () => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.getInfoStepCompany().then(res => {
        let companyInfo: CompanyInfoType = {
            companyIndustryId: res.data.company.companyIndustryId,
            companySizeId: res.data.company.companySizeId,
            companyId: res.data.company.id,
            companyName: res.data.company.name,
            superAdminList: res.data.company.superAdminList,
            companyValues: res.data.company.norms,
            companyIndustries: res.data.companyIndustries,
            companySizes: res.data.companySizes
        }
        dispatch(setStepCompanyInfoAC(companyInfo));
       // getInfoStepTeam();
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })

}

export const sendAnswerStepCompany = (answer: CompanyStepAnswerType, goNextStep: () => void) => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.sendAnswerCompanyStep(answer).then((res) => {
        goNextStep();
        dispatch(setCompanyStepAnswerAC(answer));
        window.scrollTo(0,0);
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

//STEP DEPARTMENT
export const getInfoStepDepartment = () => async (dispatch: Dispatch<AllActionType>) => {


    onboardingAPI.getInfoStepDepartment().then((res) => {

        let departmentInfo: DepartmentInfoType = {
             id: res.data.departament.id,
             name: res.data.departament.name,
             perfomingScoreId: res.data.departament.perfomingScoreId,
             perfomingScores: res.data.perfomingScores
        }

        dispatch(setStepDepartmentInfoAC(departmentInfo));
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })

}



export const sendAnswerStepDepartmentTC = (answer:  StepDepartmentAnswerType, goOtherStep: () => void) => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.sendAnswerStepDepartment(answer).then((res) => {
        goOtherStep();
        dispatch(setDepartmentStepAnswerAC(answer));
        window.scrollTo(0,0);
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

//STEP TEAM
export const getInfoStepTeam = () => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.getInfoStepTeam().then(res => {
        let departmentsWithOther = [{id: 0, name: 'Other'}, ...res.data.departments];

        let teamInfo: TeamInfoType = {
            departments: departmentsWithOther,
            departmentName: res.data.departmentName,
            frequencies: res.data.frequencies,
            perfomingScores: res.data.perfomingScores,
            //res.data.commonSelect
            checkinDay: res.data.team.checkinDay,
            departmentId: res.data.team.departamentId,
            frequencyId: res.data.team.frequencyId,
            id: res.data.team.id,
            invites: res.data.team.invites,
            name: res.data.team.name,
            perfomingScoreId: res.data.team.perfomingScoreId,
            surveysTimeSend: res.data.team.surveysTimeSend,
            workingDays: res.data.team.workingDays,
        }
         dispatch(setStepTeamInfoAC(teamInfo));

    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })

}

export const sendAnswerStepTeam = (answer: StepTeamAnswerType, goNextStep: () => void) => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.stepAnswerStepTeam(answer).then((res) => {
        goNextStep();
        dispatch(setTeamStepAnswerAC(answer));
        window.scrollTo(0,0)
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getNorms = () => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.getNorms().then((res) => {
        let valuesInfo: ValuesInfoType = {
            norms: res.data.norms,
            userNorms: res.data.userNorms
        }
        dispatch(setStepValuesInfoAC(valuesInfo));
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}


export const sendNorms = (norms: {norms: Array<number>}, goNextStep: () => void) => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.sendNorms(norms).then((res) => {
        goNextStep();
        dispatch(changeUserValuesStepValuesAC(norms.norms));
        window.scrollTo(0, 0);
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getInfoStepMotivators = () => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.getMotivators().then((res) => {

            //здесь в зависимости от newHire score и text будешь заполнять инфой из разныхполей
            //и если он заходит на первый онбординг, то что???
        let motivatorsInfo: MotivatorsInfoType = {
            company: res.data.company,
            baselines: res.data.baselines,
            companyExperience: res.data.companyExperience,
            isNewHire: res.data.isNewHire,
            motivators: res.data.motivators,
            onboardingExperience: res.data.onboardingExperience,
            userBaselines: res.data.userBaselines,
            userPriorities: res.data.userPriorities,
            reasons: res.data.reasons,
            userRatings: res.data.userRatings,
            userReason: res.data.userReason,
            score:  res.data.isNewHire === null ? null : res.data.isNewHire === false ? res.data.companyExperience?.score : res.data.onboardingExperience?.score,
            text: res.data.isNewHire === null ? null : res.data.isNewHire === true ? res.data.onboardingExperience?.text : res.data.companyExperience?.text,
        }
        dispatch(setStepMotivatorsInfoAC(motivatorsInfo))
        dispatch(setOnboardingLoadingAC(false));

    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const sendMotivators = (answer: any, goNextStep: () => void) => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.sendMotivators(answer).then((res) => {
        goNextStep();
        window.scrollTo(0,0);

    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const finishOnboarding = (url: string, navigate: (url: string) => void) => async (dispatch: Dispatch<AllActionType>) => {
    onboardingAPI.finishOnboarding().then(res => {
        if (url === '/dashboard') {
            window.location.href = '/dashboard';
           // navigate('/dashboard');
        }else if(url === '/assessment'){
            window.location.href = '/assessment';
           // navigate('/assessment');
        }
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}


