import {AllActionType} from "../../types";
import {Dispatch} from "redux";
import {companyManagementAPI, dashboardAPI, teamManagementAPI} from "../../api/api";
import {addAlertMessageCommonReducerAC, setLoadingCommonReducerAC} from "./commonReducer";
import {AppThunk, RootStateType} from "../store/store";
import {v1} from "uuid";
import {ActiveTabTeamManagementType} from "../../components/pages/teamManagment/teamManagement";
import {ChosenTeamInfoType} from "./dashboardReducer";
import {changeUserDataType} from "../../components/pages/companyManagement/companyInformation/companyInformation";
import {errorAlertItem} from "../../common";

export type CheckinReportMemberType = {
    firstName: string,
    lastName: string,
    email: string,
    completedAt: string,
    id: number | string
}

type MemberType = {
    id: string,
    email: string,
    isTeamLeader: boolean,
    firstName: string,
    lastName: string,
    onboarding: boolean,
    status: boolean,
   // isLeader: boolean
}

export type TeamManagementTeamInformationInfoType = {
    changeEnable: boolean,
    checkinDay: number | null,
    frequencyId: number,
    id: number,
    leaderName: string,
    link: string,
    members: Array<MemberType>,
    name: string,
    surveysTimeSend: string,
    workingDays:Array<number>
}

export type CheckInReportInfoType = {
    date: Date,
    members: Array<CheckinReportMemberType>
}

type TeamType = {
    departamentId: number,
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    leader: string,
    name: string,
    onboardingCompleted: boolean
}

type TeamManagementStateType = {
    activeTab: ActiveTabTeamManagementType,
    availableTeams: Array<TeamType>,
    chosenTeamId: number | null,
    //это когда мы кликаем по мемберу, который является лидером в других командах
    chosenLeader: MemberType | null,
    teamInformation: TeamManagementTeamInformationInfoType,
    checkInReport: CheckInReportInfoType,
    dashboard: {
        tokenForDashboard: string | null,
        selectedTimeFrame: { value: string, label: string },
        teamInfo: ChosenTeamInfoType | null,
    }
}

const initialState: TeamManagementStateType = {
    activeTab: 'information',
    availableTeams: [],
    chosenTeamId: null,
    chosenLeader: null,
    teamInformation: {
        changeEnable: false,
        checkinDay: 1,
        frequencyId: 1,
        id: 0,
        leaderName: '',
        link: '',
        members: [],
        name :'',
        surveysTimeSend: '12:00',
        workingDays: [1,2,3,4,5]
    },
    checkInReport: {
        date: new Date(),
        members: [{
            firstName: '',
            lastName: '',
            email: '',
            completedAt: '',
            id: ''
        }]
    },
    dashboard: {
        tokenForDashboard: null,
        selectedTimeFrame: {value: '1m', label: 'Last 30 days'},
        teamInfo: null,
    }
}

export const teamManagementReducer = (state = initialState, action: AllActionType): TeamManagementStateType => {
    switch (action.type) {
        case 'TEAM-MANAGEMENT-SET-ACTIVE-TAB': {
            return {...state, activeTab: action.tab}
        }
        case "TEAM-MANAGEMENT-SET-AVAILABLE-TEAMS": {
            return {...state, availableTeams: [...action.teams]}
        }
        case 'TEAM-MANAGEMENT-SET-CHOSEN-TEAM-ID': {
            return {...state, chosenTeamId: action.teamId}
        }
        case 'TEAM-MANAGEMENT-SET-TEAM-INFORMATION': {
            return {...state, teamInformation: {...action.info}}
        }
        case 'TEAM-MANAGEMENT-CHANGE-TEAM-NAME': {
            return {...state, teamInformation: {...state.teamInformation, name: action.name}}
        }
        case 'TEAM-MANAGEMENT-CHANGE-LEADER-NAME': {
            return {...state, teamInformation: {...state.teamInformation, leaderName: action.name}}
        }
        case 'TEAM-MANAGEMENT-CHANGE-FREQUENCY': {
            return {...state, teamInformation: {...state.teamInformation, frequencyId: action.frequencyId}}
        }
        case 'TEAM-MANAGEMENT-CHANGE-SEND-TIME': {
            return {...state, teamInformation: {...state.teamInformation, surveysTimeSend: action.time}}
        }
        case 'TEAM-MANAGEMENT-CHANGE-WORKING-DAYS': {
            return {...state, teamInformation: {...state.teamInformation, workingDays: [...action.days]}}
        }
        case 'TEAM-MANAGEMENT-CHANGE-CHECKIN-DAY': {
            return {...state, teamInformation: {...state.teamInformation, checkinDay: action.day}}
        }
        case 'TEAM-MANAGEMENT-ADD-MEMBER': {
            return {...state, teamInformation: {...state.teamInformation, members: [action.member,...state.teamInformation.members]}}
        }
        case 'TEAM-MANAGEMENT-REMOVE-MEMBER': {
            return {...state, teamInformation: {...state.teamInformation, members: state.teamInformation.members.filter(member => member.id !== action.memberId)}}
        }
        case 'TEAM-MANAGEMENT-CHECKIN-REPORTS-CHANGE-DATE': {
            return {...state, checkInReport: {...state.checkInReport, date: action.date}};
        }
        case 'TEAM-MANAGEMENT-CHECKIN-REPORTS-SET-MEMBERS': {
            return {...state, checkInReport: {...state.checkInReport, members: [...action.members]}}
        }
        case 'SET-TOKEN-FOR-DASHBOARD-TEAM-MANAGEMENT': {
            return {...state, dashboard: {...state.dashboard, tokenForDashboard: action.token}}
        }

        case 'TEAM-MANAGEMENT-SET-CHOSEN-LEADER': {
            return {...state, chosenLeader: {...action.leader}}
        }
        case 'SET-TIME-FRAME-TEAM-MANAGEMENT': {
            return {...state, dashboard: {...state.dashboard, selectedTimeFrame: {...action.time}}}
        }
        case 'SET-CHOSEN-TEAM-INFO-TEAM-MANAGEMENT': {
            return {...state, dashboard: {...state.dashboard, teamInfo: {...action.info}}}
        }
        case 'CHANGE-MEMBER-DATA-DEMO-TEAM-INFO-TEAM-MANAGEMENT': {
            return {...state, teamInformation: {...state.teamInformation, members: state.teamInformation.members
                        .map(member => member.id === action.userId ? {...member, ...action.data} : member)}}
        }
        //для дмок, сами экшн креаторы находятся в companyManagementReducer
        case 'CREATE-CHECKIN-COMPANY-MANAGEMENT-DEMO': {
            return action.checkinType === 'team' ? {
                ...state,
                checkInReport: {...state.checkInReport, members: [...state.checkInReport.members, action.checkin]}
            } : {...state}
        }
        case 'UPDATE-CHECKIN-COMPANY-MANAGEMENT-DEMO': {
            return action.checkinType === 'team' ? {
                ...state, checkInReport: {
                    ...state.checkInReport, members: state.checkInReport.members
                        .map(m => m.id === action.checkin.id ? {...action.checkin} : m)
                }
            } : {...state}
        }
        case 'DELETE-CHECKIN-COMPANY-MANAGEMENT-DEMO': {
            return action.checkinType === 'team' ? {
                ...state,
                checkInReport: {
                    ...state.checkInReport,
                    members: state.checkInReport.members.filter(m => m.id !== action.checkinId)
                }
            } : {...state}
        }
        default:
            return state

    }
}

export type setActiveTabTeamManagementACType = ReturnType<typeof setActiveTabTeamManagementAC>;
export type setAvailableTeamsTeamManagementACType = ReturnType<typeof setAvailableTeamsAC>;
export type setChosenTeamIdTeamManagementACType = ReturnType<typeof setChosenTeamIdAC>;
export type setTeamInformationTeamManagementACType = ReturnType<typeof setTeamInformationTeamManagementAC>;
export type changeTeamNameTeamManagementACType = ReturnType<typeof changeTeamNameTeamManagementAC>;
export type changeLeaderNameTeamManagementACType = ReturnType<typeof changeLeaderNameTeamManagementAC>;
export type changeFrequencyTeamManagementACType = ReturnType<typeof changeFrequencyTeamManagementAC>;
export type changeSendTimeTeamManagementACType = ReturnType<typeof changeSendTimeTeamManagementAC>;
export type changeWorkingDaysTeamManagementACType = ReturnType<typeof changeWorkingDaysTeamManagementAC>;
export type changeCheckinDayTeamManagementACType = ReturnType<typeof changeCheckinDayTeamManagementAC>;
export type addMemberTeamManagementACType = ReturnType<typeof addMemberTeamManagementAC>;
export type removeMemberTeamManagementACType = ReturnType<typeof removeMemberTeamManagementAC>;
export type changeDateCheckinReportTeamManagementACType = ReturnType<typeof changeDateCheckinReportTeamManagementAC>;
export type setMembersCheckinReportTeamManagementACType = ReturnType<typeof setMembersCheckinReportTeamManagementAC>;
export type setChosenTeamLeaderTeamManagementACType = ReturnType<typeof setChosenTeamLeaderTeamManagementAC>;
export type setTimeFrameTeamManagementDashboardACType = ReturnType<typeof setTimeFrameTeamManagementDashboardAC>;
export type setChosenTeamInfoTeamManagementACType = ReturnType<typeof setChosenTeamInfoTeamManagementAC>;
export type setTokenForDashboardTeamManagementACType = ReturnType<typeof setTokenForDashboardTeamManagementAC>;
export type changeMemberDataDemoCompanyManagementACType = ReturnType<typeof changeMemberDataDemoCompanyManagementAC>;

export const setActiveTabTeamManagementAC = (tab: ActiveTabTeamManagementType) => ({type: 'TEAM-MANAGEMENT-SET-ACTIVE-TAB', tab}as const);
export const setAvailableTeamsAC = (teams: Array<TeamType> | []) => ({
    type: 'TEAM-MANAGEMENT-SET-AVAILABLE-TEAMS',
    teams
} as const);
export const setChosenTeamIdAC = (teamId: number | null) => ({type: 'TEAM-MANAGEMENT-SET-CHOSEN-TEAM-ID', teamId} as const);
export const setTeamInformationTeamManagementAC = (info: TeamManagementTeamInformationInfoType) => ({type: 'TEAM-MANAGEMENT-SET-TEAM-INFORMATION', info}as const);
export const changeTeamNameTeamManagementAC = (name: string) => ({type: 'TEAM-MANAGEMENT-CHANGE-TEAM-NAME', name}as const);
export const changeLeaderNameTeamManagementAC = (name: string) => ({type: 'TEAM-MANAGEMENT-CHANGE-LEADER-NAME', name}as const);
export const changeFrequencyTeamManagementAC = (frequencyId: number) => ({type: 'TEAM-MANAGEMENT-CHANGE-FREQUENCY', frequencyId}as const);
export const changeSendTimeTeamManagementAC = (time: string) => ({type: 'TEAM-MANAGEMENT-CHANGE-SEND-TIME', time}as const);
export const changeWorkingDaysTeamManagementAC = (days: Array<number>) => ({type: 'TEAM-MANAGEMENT-CHANGE-WORKING-DAYS', days}as const);
export const changeCheckinDayTeamManagementAC = (day: number | null) => ({type: 'TEAM-MANAGEMENT-CHANGE-CHECKIN-DAY', day}as const);
export const addMemberTeamManagementAC = (member: MemberType) => ({type: 'TEAM-MANAGEMENT-ADD-MEMBER', member}as const);
export const removeMemberTeamManagementAC = (memberId: string) => ({type: 'TEAM-MANAGEMENT-REMOVE-MEMBER', memberId}as const);
export const changeDateCheckinReportTeamManagementAC = (date: Date) => ({type: 'TEAM-MANAGEMENT-CHECKIN-REPORTS-CHANGE-DATE', date}as const);
export const setMembersCheckinReportTeamManagementAC = (members: Array<CheckinReportMemberType>) => ({type: 'TEAM-MANAGEMENT-CHECKIN-REPORTS-SET-MEMBERS', members}as const);
export const setTokenForDashboardTeamManagementAC = (token: string | null) => ({type: 'SET-TOKEN-FOR-DASHBOARD-TEAM-MANAGEMENT', token}as const);
export const setChosenTeamLeaderTeamManagementAC = (leader: MemberType) => ({type: 'TEAM-MANAGEMENT-SET-CHOSEN-LEADER', leader}as const);
export const setTimeFrameTeamManagementDashboardAC = (time: {value: string, label: string}) => ({type: 'SET-TIME-FRAME-TEAM-MANAGEMENT', time}as const);
export const setChosenTeamInfoTeamManagementAC = (info: ChosenTeamInfoType) => ({type: 'SET-CHOSEN-TEAM-INFO-TEAM-MANAGEMENT', info}as const);
//demos
export const changeMemberDataDemoCompanyManagementAC = (data: changeUserDataType, userId: string) => ({type: 'CHANGE-MEMBER-DATA-DEMO-TEAM-INFO-TEAM-MANAGEMENT', data, userId}as const);

export const getTeams = (previewToken: boolean): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let chosenTeamId = getState().teamManagement.chosenTeamId;
    teamManagementAPI.getAllTeams(previewToken).then(res => {
        let teams = res.data.teams;
        dispatch(setAvailableTeamsAC(teams));
        !chosenTeamId && dispatch(setChosenTeamIdAC(teams[0].id))
        dispatch(setLoadingCommonReducerAC(false));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getTeamInfo = (chosenTeamId: number | null): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCommonReducerAC(true));
    teamManagementAPI.getChosenTeamInfo(chosenTeamId).then(res => {
        //добавляю id каждому мемберу с сервера
        let info = res.data;
        dispatch(setTeamInformationTeamManagementAC(info));
        dispatch(setLoadingCommonReducerAC(false));
    }).catch(err => {
        dispatch(setLoadingCommonReducerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const addTeamMember = (teamId: number,
                              info: {firstName: string, lastName: string, email: string},
                              member: MemberType,
                              changeModalWindow: (mode: boolean) => void,
                              setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    teamManagementAPI.addTeamMember(teamId, info).then(res => {
        dispatch(addMemberTeamManagementAC(member));
        changeModalWindow(false);
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const removeTeamMember = (memberStatus: boolean, teamId: number,  email: string, memberId: string,
                                 setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    if (memberStatus) {
        teamManagementAPI.removeTeamMember(teamId, {email: email}).then(res => {
            dispatch(removeMemberTeamManagementAC(memberId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    } else {
        teamManagementAPI.removeTeamMemberInvite(teamId, {email: email}).then(res => {
            dispatch(removeMemberTeamManagementAC(memberId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    }
}


export const reinviteMemberTC = (teamId: number,  email: string,
                                 setError: (notification: string) => void,
                                 setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.reinviteMember(teamId, {email: email}).then(res => {
        setSuccess('The invitation will be resent.')
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const nudgeMemberTC = (teamId: number,  email: string,
                              setError: (notification: string) => void,
                              setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.nudgeMember(teamId, {email: email}).then(res => {
        setSuccess('Member will be nudged.')
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const changeTeamNameTC = (name: string, teamId: number,
                                 setError: (error: boolean) => void,
                                 setNotification: (notification: string) => void,
                                 navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
   let teams = getState().teamManagement.availableTeams;
    teamManagementAPI.changeTeamName(name, teamId).then(res => {
        let fixedTeams = teams.map(t => t.id === teamId ? {...t, name: name} : t);
        dispatch(changeTeamNameTeamManagementAC(name));
        dispatch(setAvailableTeamsAC(fixedTeams))
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
           // window.location.href = '/404';
            navigate('/404');
        } else if(status === 403) {
            setError(true);
            setNotification(error.response.data.message);
        }else {
           // window.location.href = '/pageOops';
            navigate('/pageOops');
        }
    })
}
export const saveNewCheckinInfoTC = (teamId: number,
                                     info: {checkinDay: number | null, workingDays: Array<number>, frequencyId: number, time: string},
                                     setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    teamManagementAPI.saveNewCheckinInfo(teamId, info).then(res => {
        window.location.reload();
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const getCheckinReportInfoTC = (teamId: number, date: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.getCheckinReportInfo(teamId, date).then(res => {
       // let members = res.data.answers.map((member: {firstName: string, lastName: string, email: string, completedAt: string}) => ({...member, id: v1()}));
        dispatch(setMembersCheckinReportTeamManagementAC(res.data.answers));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const changeTeamLeaderTeamManagementTC = (teamId: number,
                                                 info: {firstName: string, lastName: string, email: string, keep: boolean},
                                                 setError: (notification: string) => void,
                                                 changeModalWindowMode: (mode: boolean) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.changeTeamLeader(teamId, info).then(res => {
        changeModalWindowMode(false);
    }).catch(err => {
        setError(err.response.data.message);
    })
}

export const setNewTokenLeaderTeamManagementTC = (userId: string, navigate: any): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.getTokenForDashboard(userId).then(res => {
      //  dispatch(setTokenForDashboardTeamManagementAC(res.data.token));
        localStorage.setItem('previewToken', res.data.token);
       navigate('/teamManagement/dashboard');
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const removeNewTokenLeaderTeamManagementTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {

    teamManagementAPI.removeTokenOnDashboard().then(res => {
        localStorage.setItem('token', res.data.token);
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const getChosenTeamInfoTeamManagementDashboardTC = (role: string, selectedTimeFrame: { value: string, label: string },
                                  chosenTeamId: number | null, selectedTeamMember: {value: string, label: string} | null, previewToken?: boolean) => async (dispatch: Dispatch<AllActionType>) => {

    dispatch(setLoadingCommonReducerAC(true));

    dashboardAPI.getTeamInfo(chosenTeamId, selectedTimeFrame.value, selectedTeamMember?.value, previewToken).then(res => {

        let norms = [];
        for (let key in res.data.norms) {
            norms.push(res.data.norms[key])
        }
        let members = res.data.team?.members.map((i: {value: number, label: string}) => ({value: i.value?.toString(), label: i.label}));

        let chosenTeamInfo = {
            teamId: res.data.team.id,
            teamName: res.data.team.name,
            members: members,
            leader: res.data.team.leader,
            motivationAvg: res.data.motivation.avg,
            motivationLineGraph: res.data.motivation.data,
            sentimentAvg: res.data.sentiments.avg,
            sentimentLineGraph: res.data.sentiments.data,
            sentimentAvgBad: res.data.sentiments.typesAvg.bad,
            sentimentAvgGood: res.data.sentiments.typesAvg.good,
            sentimentAvgMeh: res.data.sentiments.typesAvg.meh,
            sentimentBarGraph: res.data.sentiments.typesData,
            performanceAvg: res.data.productivity.avg,
            performanceLineGraph: res.data.productivity.data,
            productivity_sentiment_category: res.data.productivity_sentiment_category,
            norms: norms
        }

        dispatch(setLoadingCommonReducerAC(false));
        dispatch(setChosenTeamInfoTeamManagementAC(chosenTeamInfo));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })


}

export const deleteTeamTeamManagementTC = (teamId: number, redirectTo: (path: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.deleteTeam(teamId).then((res) => {
        dispatch(setChosenTeamIdAC(null))
        redirectTo('/dashboard')
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

//для эдит мода на демке

export const changeMemberDataDemoTC = (teamId: number,
                                       chosenUserForDemoEdit: any,
                                       // userId: string,
                                       // oldChosenEmail: string,
                                       data: changeUserDataType,
                                       setError: (notification: string) => void,
                                       setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType)  => {
    teamManagementAPI.changeMemberDataDemo(teamId, chosenUserForDemoEdit.email, data).then(res => {
        dispatch(changeMemberDataDemoCompanyManagementAC(data, chosenUserForDemoEdit.id));
        setSuccess('Member data successfully changed!')
    }).catch((err) => {
        setError(err.response.data.message)
    })
}