import React, {useCallback, useEffect} from 'react';
import "./app.scss";
import {Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom';
import CoachingCorner from "../pages/coachingCorner/coachingCorner";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../redux/store/store";
import {
    getRoles, getUserInfo,
} from "../../redux/reducers/commonReducer";
import {LoaderDots} from "@thumbtack/thumbprint-react";
import {io} from 'socket.io-client';
import {
    addNewNotificationsMenuNotificationsReducerAC,
    addNewNotificationTC
} from "../../redux/reducers/notificationsMenuReducer";
import MessageBox from "../parts/messageBox/messageBox";
import {tokenRegex} from "flatpickr/dist/utils/formatting";

import {v1} from "uuid";
import Alert from "../parts/alert/alert";
import MainLayout from "../layouts/main-layout";

const Onboarding = React.lazy(() => import("../pages/onboaring/onboarding"));
const TeamManagement = React.lazy(() => import('../pages/teamManagment/teamManagement'));
const TeamManagementDashboard = React.lazy(() => import('../pages/teamManagment/teamManagementDashboard/teamManagementDashboard'));
const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const CertainTeamPage = React.lazy(() => import('../pages/dashboard/certainTeam/certainTeamPage'));
const CompanyManagement = React.lazy(() => import('../pages/companyManagement/companyManagement'));
const SignIn = React.lazy(() => import('../pages/authorizationAndLogging/signIn/signIn'));
const SignUp = React.lazy(() => import('../pages/authorizationAndLogging/signUp/signUp'));
const SetPassword = React.lazy(() => import('../pages/authorizationAndLogging/setPassword/setPassword'));
const Confirmation = React.lazy(() => import('../pages/authorizationAndLogging/confirmation/confirmation'));
const ForgotPassword = React.lazy(() => import('../pages/authorizationAndLogging/forgotPassword/forgotPassword'));
const CheckinEmail = React.lazy(() => import('../pages/checkEmail/checkEmail'));
const UpdatePassword = React.lazy(() => import('../pages/authorizationAndLogging/updatePassword/updatePassword'));
const DigitalPulse = React.lazy(() => import('../pages/digitalPulse/digitalPulse'));
const Edit = React.lazy(() => import('../pages/digitalPulse/edit/edit'));
const Questionnaire = React.lazy(() => import('../pages/digitalPulse/questionnaire/questionnaire'));
const Profile = React.lazy(() => import('../pages/profile/profile'));
const CreateTeam = React.lazy(() => import('../pages/createTeam/createTeam'));
const CreateDepartment = React.lazy(() => import('../pages/createDepartment/createDepartment'))
const Assessment = React.lazy(() => import('../pages/assessment/assessment'));
const Faq = React.lazy(() => import('../pages/faq/faq'));
const SectionPageFaq = React.lazy(() => import('../pages/faq/sectionPageFaq/sectionPageFaq'));
const DepartmentManagement = React.lazy(() => import('../pages/departmentManagement/departmentManagement'));
const CertainTeamPageDepartmentManagement = React.lazy(() => import('../pages/departmentManagement/certainTeamPage/certainTeamPageDepartmentManagement'));
const UnsubscribePage = React.lazy(() => import('../pages/unsubscribePage/unsubscribePage'));
const TeamSelectionForCheckins = React.lazy(() => import('../pages/teamSelectionForCheckins/teamSelectionForCheckins'));
const CheckinsQuestionnaire = React.lazy(() => import('../pages/questionnaire/checkinsQuestionnaire'));
const DemosPage = React.lazy(() => import('../pages/demos/demosPage'));
const CheckinsConstructor = React.lazy(() => import('../pages/checkinsConstructor/checkinsConstructor'));
const OopsPage = React.lazy(() => import('../pages/pageOops/pageOops'));
const Page404 = React.lazy(() => import('../pages/page404/page404'));

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let token = localStorage.getItem("token");
    let commonState = useSelector((state: RootStateType) => state.common);
    const {alerts} = commonState;

    //это для демос
    //комментить тут нельзя, так как при обновлении станицы (перезагрузке) все упадет
    useEffect(() => {
        // document.title = 'unboXt';
        if (token) {
            dispatch(getRoles(navigate));
            dispatch(getUserInfo());
        }
    }, [])

    return (
        <div className="app">
            {alerts.length ? <div className='notification_alert'>
                {alerts.map((a, index) => <Alert {...a} key={index}/>)}
            </div> : null}
            <React.Suspense fallback={<LoaderDots/>}>
                <Routes>
                    <Route path="/" element={<MainLayout/>}>
                        <Route path="/" element={<Navigate replace to="/dashboard"/>}/>
                        <Route path="/dashboard" element={token ? <Dashboard/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/dashboard/team"
                               element={token ? <CertainTeamPage/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/companyManagement"
                               element={token ? <CompanyManagement/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/teamManagement"
                               element={token ? <TeamManagement/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/teamManagement/dashboard"
                               element={token ? <TeamManagementDashboard/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/coachingCorner"
                               element={token ? <CoachingCorner/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/digitalPulse"
                               element={token ? <DigitalPulse/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/edit-digitalPulse/:pulseId"
                               element={token ? <Edit/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path="/profile" element={token ? <Profile/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path={`/departmentManagement`}
                               element={token ? <DepartmentManagement/> : <Navigate replace to={'/signIn'}/>}/>
                        <Route path={`/departmentManagement/:teamName`}
                               element={token ? <CertainTeamPageDepartmentManagement/> :
                                   <Navigate replace to={'/signIn'}/>}/>
                    </Route>
                    <Route path="/onboarding" element={<Onboarding/>}/>
                    <Route path="/signIn" element={<SignIn/>}/>
                    <Route path="/signIn/:token" element={<SignIn/>}/>
                    <Route path="/signUp" element={<SignUp/>}/>
                    <Route path="/signUp/:token" element={<SignUp/>}/>
                    <Route path="/setPassword/:token" element={<SetPassword/>}/>
                    <Route path="/confirmation" element={<Confirmation/>}/>
                    <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                    <Route path="/checkEmail" element={<CheckinEmail/>}/>
                    <Route path="/updatePassword/:token" element={<UpdatePassword/>}/>
                    <Route path="/pulse/:token" element={<Questionnaire/>}/>
                    <Route path={`/teamCreate`} element={token ? <CreateTeam/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/teamCreate/:id`}
                           element={token ? <CreateTeam/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/departmentCreate`}
                           element={token ? <CreateDepartment/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/departmentCreate/:id`}
                           element={token ? <CreateDepartment/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/assessment`} element={token ? <Assessment/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/faq/:section`}
                           element={token ? <SectionPageFaq/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/faq`} element={token ? <Faq/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path={`/unsubscribe/:userId`} element={<UnsubscribePage/>}/>
                    <Route path={`questionnaire/checkins`}
                           element={token ? <TeamSelectionForCheckins/> : <Navigate replace to={'/signIn'}/>}/>
                    <Route path="/questionnaire/:token" element={<CheckinsQuestionnaire/>}/>
                    <Route path="/demo" element={<DemosPage/>}/>
                    <Route path="/checkinsConstructor" element={<CheckinsConstructor/>}/>
                    <Route path="/oops" element={<OopsPage/>}/>
                    <Route path="/404" element={<Page404/>}/>
                    <Route path="*" element={<Navigate to={'/404'}/>}/>
                </Routes>
            </React.Suspense>
        </div>
    );
}

export default App;
