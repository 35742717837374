import React from "react";
import style from './membership.module.scss';
import InsertedImageRound from "../../insertedImageRound/insertedImageRound";
import {UserType} from "../switchAccount";
import {setAnchorElForAccountMenuAC, setCurrentStatusAC} from "../../../../redux/reducers/commonReducer";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {
    setFilteredTeamsAC, setSelectedDepartmentsAC,
    setSelectedTeamMemberAC,
    setSelectedTeamsAC
} from "../../../../redux/reducers/dashboardReducer";
import {setPreviewModeDigitalPulseAC} from "../../../../redux/reducers/digitalPulseReducer";
import {RootStateType} from "../../../../redux/store/store";
import {setChosenTeamCoachingCornerAC} from "../../../../redux/reducers/coachingCornerReducer";
import InsertedImage from "../../insertedImage/insertedImage";
import delete_acc from './../../../../assets/images/common/delete_acc.png';
import MessageBox from "../../messageBox/messageBox";
import {useHookErrors} from "../../../../redux/hookNavigate";
import {Badge} from "@mui/material";

export type MembershipPropsType = {
    user: UserType
    key: number
}

const Membership = (props: MembershipPropsType) => {
    const dispatch = useDispatch();
    const currentStatus = useSelector((state: RootStateType) => state.common.currentStatus);
    let notificationsState = useSelector((state: RootStateType) => state.notificationsMenu);
    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);
    const navigate = useNavigate();

    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();


    const changeRole = () => {
        //меняем роли - сетаем допустимые команды
        localStorage.setItem('role', props.user.role);
        dispatch(setCurrentStatusAC(props.user.role));
        dispatch(setAnchorElForAccountMenuAC(null));
        //очищаем выбранного мембера
        dispatch(setSelectedTeamMemberAC(null));
        dispatch(setSelectedTeamsAC([]));
        dispatch(setFilteredTeamsAC([]));
        dispatch(setSelectedDepartmentsAC([]));
        dispatch(setPreviewModeDigitalPulseAC(false));
        dispatch(setChosenTeamCoachingCornerAC(null));
    }

    const changeRoleWhenDeletingAcc = (role: string) => {
        localStorage.setItem('role', role);
        dispatch(setCurrentStatusAC(role));
        dispatch(setAnchorElForAccountMenuAC(null));
        //очищаем выбранного мембера
        dispatch(setSelectedTeamMemberAC(null));
        dispatch(setSelectedTeamsAC([]));
        dispatch(setFilteredTeamsAC([]));
        dispatch(setSelectedDepartmentsAC([]));
        dispatch(setPreviewModeDigitalPulseAC(false));
        dispatch(setChosenTeamCoachingCornerAC(null));
    }

    // const unreadNotifications = props.user.role === 'isModerator' ? notifications.filter(n => n.role === 'isModerator' && !n.seen).length
    //     : props.user.role === 'isDepartmentAdmin' ? notifications.filter(n => n.role === 'isDepartmentAdmin' && !n.seen).length
    //         : props.user.role === 'isAdmin' ? notifications.filter(n => n.role === 'isAdmin' && !n.seen).length
    //             : props.user.role === 'isMember' ?  notifications.filter(n => n.role === 'isMember' && !n.seen).length : 0;

    const unreadNotifications = props.user.role === 'isModerator' ? notificationsState.moderatorCount
        : props.user.role === 'isDepartmentAdmin' ? notificationsState.departmentLeaderCount
            : props.user.role === 'isAdmin' ? notificationsState.adminCount
                : props.user.role === 'isMember' ? notificationsState.memberCount : 0

    // const unreadNotifications =  notifications.filter(n => n.role === props.user.role && !n.seen).length | 0;

    const deleteAccount = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        let allRoles = localStorage.getItem('all_roles') || '';
        let updatedRoles = localStorage.getItem('updated_roles') || '';
        let workRoles = updatedRoles.length ? JSON.parse(updatedRoles) : JSON.parse(allRoles);

        if(workRoles.length === 1) {
            return
        }else if(currentStatus === props.user.role) {
            let newRoles = workRoles.filter((r: {name: string, value: boolean}) => r.name !== props.user.role);
            localStorage.setItem('updated_roles', JSON.stringify(newRoles));
            changeRoleWhenDeletingAcc(newRoles[0].name);
            navigate((newRoles[0].name === 'isModerator' || newRoles[0].name === 'isDepartmentAdmin') ? '/dashboard' : '/dashboard/team');
        } else if (currentStatus !== props.user.role) {
            let newRoles = workRoles.filter((r: {name: string, value: boolean}) => r.name !== props.user.role);
            localStorage.setItem('updated_roles', JSON.stringify(newRoles));
            dispatch(setAnchorElForAccountMenuAC(null));
        }
    }

    return (
        <>
            <NavLink
                to={(props.user.role === 'isModerator' || props.user.role === 'isDepartmentAdmin') ? '/dashboard' : '/dashboard/team'}
                onClick={changeRole}>
                <div className={style.membership}>
                    <div style={{position: 'relative'}}>
                        <Badge badgeContent={(props.user.role !== currentStatus && unreadNotifications) || 0} color="error">
                            <InsertedImageRound width={2.875} height={2.875} text={props.user.shortName}
                                                background={'#FFDA6E'}/>
                        </Badge>

                    </div>
                    <div className={style.membership_info}>
                        <div>{props.user.name}</div>
                        <div>{props.user.email}</div>
                        <div>{props.user.role === 'isAdmin' ? 'Team Leader' : props.user.role === 'isModerator' ? 'Administrator' : props.user.role === 'isDepartmentAdmin' ? 'Department Leader' : 'Member'}</div>
                    </div>
                    {demoMode === 'edit' && <InsertedImage image={delete_acc} height={2.5} width={2.5} handler={deleteAccount} />}
                </div>
                <div className={style.switch_account_separator}></div>
            </NavLink>
        </>
    )
}

export default Membership;
