import React, {useCallback, useEffect, useMemo, useState} from "react";
import style from './switchNotifications.module.scss';
import {useNavigate} from "react-router-dom";
import NotificationItem from "./notificationItem/notificationItem";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {
    deleteNotificationTC, getNotificationsNotificationsReducerAC,
    getNotificationsTC,
    NotificationType,
    readNotificationMenuNotificationsReducerTC,
    setChosenItemInModalWindowNotificationMenuAC
} from "../../../redux/reducers/notificationsMenuReducer";
import InsertedImage from "../insertedImage/insertedImage";
import add from './../../../assets/images/common/add.png';
import ModalWindowWrapper from "../modalWindowWrapper/modalWindowWrapper";
import AddItemModalWIndowBody from "../../pages/coachingCorner/addItemModalWindowBody/addItemModalWIndowBody";
import ModalWindowCoachingCorner from "../../pages/coachingCorner/modalWindow/modalWindowCoachingCorner";
import {setChosenItemInModalWindowCoachingCornerAC} from "../../../redux/reducers/coachingCornerReducer";
import NotificationConstructor from "./notificationConstructor/notificationConstructor";
import {setAnchorElForNotificationsMenuAC} from "../../../redux/reducers/commonReducer";

export type NotificationsModeType = 'all' | 'unread';


// actionType: 'create' | 'update' | null,
//     setActionType: (type: 'create' | 'update' | null) => void,

const SwitchNotifications = () => {
    const dispatch = useDispatch();
    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);
    const currentStatus = useSelector((state: RootStateType) => state.common.currentStatus);
    const {allNotifications, chosenNotification} = useSelector((state: RootStateType) => state.notificationsMenu);
    let navigate = useNavigate();

    const [notificationsMode, setNotificationsMode] = useState<NotificationsModeType>('unread');

    const changeNotificationsMode = (mode: NotificationsModeType) => {
        setNotificationsMode(mode);
    }

    //делаем все нотификашки прочитанными
    const readAllNotifications = () => {
        let allReadNotifications = allNotifications.map(n => n.id);
        let body = {notification: allReadNotifications}
        dispatch(readNotificationMenuNotificationsReducerTC(body))
    }

    //читаем конкретную нотификашку
    const readCertainNotification = (id: number) => {
        let flag = allNotifications.find(n => n.id === id)?.seen;
        let notificationSend = {notification: {id: id, seen: !flag}}
        dispatch(readNotificationMenuNotificationsReducerTC(notificationSend))
    }


    const [modalView, setModalView] = useState<boolean>(false);
    const [actionType, setActionType] = useState<'create' | 'update' | null>(null);

    let headerTitle = actionType === 'update' ? 'Edit' : actionType === 'create' ? 'Add' : '';

    const openConstructorModalWindow = (actionType: 'create' | 'update' | null,
                                        notification: NotificationType | null) => {
        setModalView(true);
        setActionType(actionType);
        notification && dispatch(setChosenItemInModalWindowNotificationMenuAC(notification));
    }

    const closeModalWindow = () => {
        setActionType(null);
        setModalView(false);
        dispatch(setChosenItemInModalWindowNotificationMenuAC(null));
        dispatch(setAnchorElForNotificationsMenuAC(null));
    }

    const deleteNotification = (id: number, role: string) => {
        dispatch(deleteNotificationTC(id, role));
    }

    const editNotification = (notification: NotificationType) => {
        openConstructorModalWindow('update', notification)
    }

    useEffect(() => {
        currentStatus.length && dispatch(getNotificationsTC(currentStatus));
    }, [currentStatus])

    return (
        <div className={`${style.switch_notifications_window} notifications_menu`}>
            <div className={style.switch_notifications_window_header}>
                {
                    demoMode === 'edit' && <div style={{position: 'absolute', top: 7, left: 10}}><InsertedImage image={add}
                                                             height={1.7}
                                                             width={1.7}
                                                             handler={() => openConstructorModalWindow('create', null)}
                                                             cursor={'pointer'}/></div>
                }
                <span>Notifications</span>
            </div>
            <div className={style.switch_notifications_separator}></div>
            <div className={style.switch_notifications_window_filters}>
                {notificationsMode === 'all' ? <p onClick={() => changeNotificationsMode('unread')}>Unread</p>
                    : <p onClick={() => changeNotificationsMode('all')}>View All</p>}
                <p onClick={readAllNotifications}>Mark all as read</p>
            </div>
            <div className={style.switch_notifications_window_body}>
                {allNotifications.map((n, index) => <NotificationItem key={index}
                                                                      index={index}
                                                                      notificationsMode={notificationsMode}
                                                                      notification={n}
                                                                      changeNotificationReadFlag={readCertainNotification}
                                                                      editNotification={() => editNotification(n)}
                                                                      deleteNotification={() => deleteNotification(n.id, n.role)}/>)}
            </div>
            {modalView &&
                <ModalWindowWrapper closeModalWindow={closeModalWindow} headerTitle={`${headerTitle} notification`}>
                    {
                        (injectedProps) => (
                            <NotificationConstructor closeModalWindow={closeModalWindow}
                                                    // chosenNotification={chosenNotification}
                                                     actionType={actionType}
                                                     {...injectedProps}/>
                        )
                    }
                </ModalWindowWrapper>
            }
        </div>
    )
}

export default SwitchNotifications;