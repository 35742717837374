import React, {useState} from "react";
import style from './coachingCornerTeamSelect.module.scss';
import arrowSelect from "./../../../../assets/images/common/arrowSelect.png";
import arrowCheck from './../../../../assets/images/coachingCorner/arrowCheck.png';
import {CoachingCornerTeamType} from "../../../../redux/reducers/coachingCornerReducer";
import InsertedImage from "../../../parts/insertedImage/insertedImage";
import {useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";


type CoachingCornerTeamSelectPropsType = {
    changeTeam: (teamId: number) => void,
    teamName: string,
    availableTeams: Array<CoachingCornerTeamType>,
    activeTab: string
}

const CoachingCornerTeamSelect = (props: CoachingCornerTeamSelectPropsType) => {
    const[showMode, changeShowMode] = useState<boolean>(false)

    const showTeams = () => {
        changeShowMode(!showMode)
    }

    const choseTeam = (teamId: number, e: React.MouseEvent<HTMLElement>) => {
        props.changeTeam(teamId);
        changeShowMode(!showMode);
    }

     const chosenTeamId = useSelector((state: RootStateType) => state.coachingCorner.chosenTeamId);
    const chosenTeamInfo = props.availableTeams.find(team => team.id === chosenTeamId);
    // const availableTeams = useSelector<RootStateType, Array<AvailableTeamType>>(state => state.dashboard.availableTeams);



    return(
        <div className={`${style.team_selection_block} ${style.select_border_team_selection}`}>
            <div className={style.team_selection_block_dropdown_name}>Select Team</div>
            <div>{props.teamName}</div>
            <InsertedImage width={1.25} height={0.8125} image={arrowSelect} cursor={'pointer'} handler={showTeams}/>
            <ul className='select_border' style={{display: `${showMode ? 'block' : 'none'}`}}>
                <li style={{backgroundColor: '#DCEEFF', fontWeight: 'bold'}} onClick={() => changeShowMode(false)}>
                    <div>{chosenTeamInfo?.name}</div>
                    <InsertedImage width={1.5} height={1.0625} image={arrowCheck}/>
                </li>
                {props.availableTeams.map((i: any, index: number) => <li key={index} onClick={(e) => choseTeam(i.id, e)}>
                    <div style={{fontWeight: i.id === chosenTeamId ? 'bold' : 'normal'}}>{i.name}</div>
                    {
                        chosenTeamInfo?.newMessages ? <div className={style.message_counter}
                                                           style={{display: props.activeTab === 'motivation' ? 'block' : 'none'}}>{chosenTeamInfo.newMessages}</div> : null
                    }
                </li>)}
            </ul>
        </div>
    )
}

export default CoachingCornerTeamSelect;