import React from "react";
import style from './messageItem.module.scss';
import InsertedImageRound from "../../../../parts/insertedImageRound/insertedImageRound";
import {CoachingCornerPostType, coachingCornerReadPostTC} from "../../../../../redux/reducers/coachingCornerReducer";

import nofoto from './../../../../../assets/images/coachingCorner/nofoto.png';
import {useDispatch} from "react-redux";

export type MessageItemPropsType = {
    post: CoachingCornerPostType
}

const MessageItem = (props: MessageItemPropsType) => {
    const dispatch = useDispatch();

    const onClickMessage = () => {
        if(!props.post.seen) {
            dispatch(coachingCornerReadPostTC(props.post.id))
        }
    }

    return (
   <div className={style.message_item_wrapper}>
            <div className={style.read_indicator} style={{visibility: !props.post.seen ? 'visible' : 'hidden'}}></div>

            <div className={`${style.message_item}`} style={{backgroundColor: props.post.seen ? 'white' : 'rgba(220, 238, 255, 0.6'}} onClick={onClickMessage}>
                <div className={style.message_item_header}>
                    <div className={style.message_item_header_block_with_ava}>
                        <div className={style.ava}>
                            <img src={props.post['logo_url'] ? props.post['logo_url'] : nofoto} alt=""/>
                        </div>
                        {/*<InsertedImageRound width={68} height={68} image={props.post['logo_url'] ? props.post['logo_url'] : nofoto}/>*/}
                        <div className={style.message_item_header_block_with_ava_info_block}>
                            <div className={style.message_item_header_block_with_ava_info_block_name}>{props.post.name}</div>
                            <div className={style.message_item_header_block_with_ava_info_block_time}>{props.post.daysAgo}</div>
                        </div>
                    </div>
                    <div className={style.message_item_header_block_from}>{props.post.source}</div>
                </div>
                <div className={style.message_item_text}>{props.post.text}</div>
            </div>
        </div>
    )
}

export default MessageItem;