import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {coachingCornerAPI, notificationsAPI} from "../../api/api";
import {addAlertMessageCommonReducerAC} from "./commonReducer";
import {errorAlertItem} from "../../common";

export type NotificationType = {
    buttonClicked: boolean | null,
    buttonLink: string | null,
    buttonTitle: string | null,
    color: string | null,
    createdAt: string | null,
    icon: string | null,
    id: number,
    message: string | null,
    props: any,
    role: 'isDepartmentAdmin' | 'isMember' | 'isModerator' | 'isAdmin' | any,
    seen: boolean,
    textArea: boolean,
    textAreaPlaceholder: string | null,
    title: string | null,
    updatedAt: string | null,
    userId: number,
    teamId: number | null,
    textAreaAnonymous: boolean | null
}

export type RoleForNotificationType = {
    value: string,
    label: string
}

export type UserForNotificationType = {
    value: number,
    label: string,
    roles: Array<RoleForNotificationType>
}

export type NotificationsMenuType = {
    allNotifications: Array<NotificationType>,
    adminCount: number,
    departmentLeaderCount: number,
    memberCount: number,
    moderatorCount: number,
    //for notification constructor
    chosenNotification: NotificationType | null,
    users: Array<any>,
    colors: Array<{label: string, value: string}>,
    icons: Array<{label: string, value: string}>,
}

const initialState: NotificationsMenuType = {
    allNotifications: [],
    adminCount: 0,
    departmentLeaderCount: 0,
    memberCount: 0,
    moderatorCount: 0,
    chosenNotification: null,
    users: [],
    colors: [],
    icons: [],
}

export const notificationsMenuReducer = (state = initialState, action: AllActionType): NotificationsMenuType => {
    switch (action.type) {
        case 'GET-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: [...action.allNotifications]}
        }
        case 'READ-ALL-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: state.allNotifications.map((notification: NotificationType) => ({...notification, seen: true}))}
        }
        case 'READ-CERTAIN-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: state.allNotifications.map((notification: NotificationType) => notification.id === action.body.id ? {...notification, seen: action.body.seen} : notification)}
        }
        case 'ADD-NEW-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: action.currentStatus !== action.notifications.role ? [...state.allNotifications] : [action.notifications, ...state.allNotifications],
                adminCount: action.notifications.role === 'isAdmin' ? state.adminCount+1 : state.adminCount,
                departmentLeaderCount: action.notifications.role === 'isDepartmentAdmin' ? state.departmentLeaderCount+1 : state.departmentLeaderCount,
                memberCount: action.notifications.role === 'isMember' ? state.memberCount+1 : state.memberCount,
                moderatorCount: action.notifications.role === 'isModerator' ? state.moderatorCount+1 : state.moderatorCount,
            }
        }
        case 'SET-UNREAD-NOTIFICATIONS-AMOUNT-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, ...action.counts}
        }
        case 'SET-BUTTON-CLICKED-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: state.allNotifications.map(n => n.id === action.notificationId ? {...n, buttonClicked: true} : n)}
        }
        case 'SET-CHOSEN-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, chosenNotification: action.notification}
        }
        case 'SET-USERS-ON-NOTIFICATION-CONSTRUCTOR-NOTIFICATIONS-REDUCER': {
            return {...state, users: [...action.users]}
        }
        case 'SET-COLORS-AND-ICONS-ON-NOTIFICATION-CONSTRUCTOR-NOTIFICATIONS-REDUCER': {
            return {...state, colors: action.colors, icons: action.icons}
        }
        case 'DELETE-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: state.allNotifications.filter(n => n.id !== action.id),
                adminCount: action.role === 'isAdmin' ? state.adminCount-1 : state.adminCount,
                departmentLeaderCount: action.role === 'isDepartmentAdmin' ? state.departmentLeaderCount-1 : state.departmentLeaderCount,
                memberCount: action.role === 'isMember' ? state.memberCount-1 : state.memberCount,
                moderatorCount: action.role === 'isModerator' ? state.moderatorCount-1 : state.moderatorCount,
            }
        }
        case  'UPDATE-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER': {
            return {...state, allNotifications: state.allNotifications.map(n => n.id === action.notification.id
                    ? {...action.notification} : n)}
        }
        default: return state;
    }
}

export type getNotificationsNotificationsReducerACType = ReturnType<typeof getNotificationsNotificationsReducerAC>;
export type readAllNotificationsMenuNotificationsReducerACType = ReturnType<typeof readAllNotificationsMenuNotificationsReducerAC>;
export type readCertainNotificationMenuNotificationsReducerACType = ReturnType<typeof readCertainNotificationMenuNotificationsReducerAC>;
export type addNewNotificationsMenuNotificationsReducerACType = ReturnType<typeof addNewNotificationsMenuNotificationsReducerAC>;
export type setUnreadNotificationsCountNotificationsReducerACType = ReturnType<typeof setUnreadNotificationsCountNotificationsReducerAC>;
export type setButtonClickedMenuNotificationsReducerACType = ReturnType<typeof setButtonClickedMenuNotificationsReducerAC>;
export type setChosenItemInModalWindowNotificationMenuACType = ReturnType<typeof setChosenItemInModalWindowNotificationMenuAC>;
export type setUsersOnNotificationsConstructorACType = ReturnType<typeof setUsersOnNotificationsConstructorAC>;
export type deleteNotificationOnNotificationMenuACType = ReturnType<typeof deleteNotificationOnNotificationMenuAC>;
export type updateNotificationOnNotificationMenuACType = ReturnType<typeof updateNotificationOnNotificationMenuAC>;
export type setColorsAndIconsOnNotificationsConstructorACType = ReturnType<typeof setColorsAndIconsOnNotificationsConstructorAC>;
export const getNotificationsNotificationsReducerAC = (allNotifications: Array<NotificationType>) => ({type: 'GET-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER', allNotifications}as const);
export const readAllNotificationsMenuNotificationsReducerAC = () => ({type: 'READ-ALL-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER'}as const);
export const readCertainNotificationMenuNotificationsReducerAC = (body: {id: number, seen:boolean}) => ({type: 'READ-CERTAIN-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER', body}as const);
export const addNewNotificationsMenuNotificationsReducerAC = (notifications: NotificationType, currentStatus: string) => ({type: 'ADD-NEW-NOTIFICATIONS-MENU-NOTIFICATIONS-REDUCER', notifications, currentStatus}as const);
export const setUnreadNotificationsCountNotificationsReducerAC = (counts: {adminCount: number, departmentLeaderCount: number, memberCount: number, moderatorCount: number}) => ({type: 'SET-UNREAD-NOTIFICATIONS-AMOUNT-MENU-NOTIFICATIONS-REDUCER', counts}as const);
export const setButtonClickedMenuNotificationsReducerAC = (notificationId: number) => ({type: 'SET-BUTTON-CLICKED-MENU-NOTIFICATIONS-REDUCER', notificationId}as const);
export const setChosenItemInModalWindowNotificationMenuAC = (notification: NotificationType | null) => ({type: 'SET-CHOSEN-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER', notification}as const);
export const setUsersOnNotificationsConstructorAC = (users: Array<UserForNotificationType>) => ({type: 'SET-USERS-ON-NOTIFICATION-CONSTRUCTOR-NOTIFICATIONS-REDUCER', users}as const);

export const deleteNotificationOnNotificationMenuAC = (id: number, role: string) => ({type: 'DELETE-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER', id, role}as const);
export const updateNotificationOnNotificationMenuAC = (notification: any) => ({type: 'UPDATE-NOTIFICATION-MENU-NOTIFICATIONS-REDUCER', notification}as const);

export const setColorsAndIconsOnNotificationsConstructorAC = (icons: Array<{value: string, label: string}>, colors: Array<{value: string, label: string}>) => ({type: 'SET-COLORS-AND-ICONS-ON-NOTIFICATION-CONSTRUCTOR-NOTIFICATIONS-REDUCER', icons, colors}as const);


export const getNotificationsTC = (currentStatus: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.getNotifications().then(res => {

        let counts = {
            adminCount: res.data.adminCount,
            departmentLeaderCount: res.data.departmentLeaderCount,
            memberCount: res.data.memberCount,
            moderatorCount: res.data.moderatorCount,
        }
        let notificationsForCertainRole = res.data.notifications.filter((n: any) => n.role === currentStatus)

        dispatch(setUnreadNotificationsCountNotificationsReducerAC(counts))
        dispatch(getNotificationsNotificationsReducerAC(notificationsForCertainRole))

    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const readNotificationMenuNotificationsReducerTC = (body: {notification: {id: number, seen: boolean}} | {notification: Array<number>}): AppThunk => async (dispatch, getState: () => RootStateType) => {

    notificationsAPI.makeNotificationSeenUnseen(body).then(res => {
        if(Array.isArray(body.notification)) {
            dispatch(readAllNotificationsMenuNotificationsReducerAC())
        }
        else {
            dispatch(readCertainNotificationMenuNotificationsReducerAC(body.notification))
        }
        // dispatch(getNotificationsNotificationsReducerAC(res.data.notifications))
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const sendMessageFromTextareaMenuNotificationsTC = (teamId: number,
                                                           post: {text: string, anonymously: boolean, isMotivation: boolean},
                                                           notificationId: number,
                                                           seen: boolean,
                                                           showToolTipe: () => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.addNewPost(teamId, post).then(res => {
        showToolTipe();
       // !seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: notificationId, seen: true}}))
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const addNewNotificationTC = (res: any): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let currentStatus = getState().common.currentStatus;
    dispatch(addNewNotificationsMenuNotificationsReducerAC(res, currentStatus))
}

export const clickActionButtonMenuNotificationsTC = (notificationId: number,
                                                     seen: boolean,
                                                     url: string,
                                                     body: {email: string, notification: number},
                                                     showToolTipe: () => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.onClickActionButton(url, body).then(res => {
        showToolTipe();
        dispatch(setButtonClickedMenuNotificationsReducerAC(notificationId));
       // !seen && dispatch(readNotificationMenuNotificationsReducerTC({notification: {id: notificationId, seen: true}}));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getUsersForNotificationsConstructorTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.getUsers().then(res => {
        let users = res.data.filter((i: UserForNotificationType) => i.roles.length);
        dispatch(setUsersOnNotificationsConstructorAC(users));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const createNotificationTC = (notification: any,
                                     setError: (err: string) => void,
                                     setSuccess: (message: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let currentStatus = getState().common.currentStatus;
    notificationsAPI.createNotification(notification).then(res => {
        dispatch(addNewNotificationsMenuNotificationsReducerAC(res.data, currentStatus));
        setSuccess('Notification was created!');
    }).catch(err => {
        setError(err.response.data.message)
    })
}

export const updateNotificationTC = (notification: any,
                                     setError: (err: string) => void,
                                     setSuccess: (message: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let currentStatus = getState().common.currentStatus;
    notificationsAPI.updateNotification(notification).then(res => {
        dispatch(getNotificationsTC(currentStatus));
        //здесь еще дописать креатор на апдейт
       // dispatch(updateNotificationOnNotificationMenuAC(res.data))
        setSuccess('Notification was updated!');
    }).catch(err => {
        setError(err.response.data.message)
    })
}

export const deleteNotificationTC = (id: number, role: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.deleteNotification(id).then(res => {
        dispatch(deleteNotificationOnNotificationMenuAC(id, role))
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const getColorsAndIconsForNotificationsConstructorTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    notificationsAPI.getColorsAndIcons().then(res => {
        let colors = res.data.colors.map((c: {label: string, value: string}) => ({label: c.label.toLowerCase(), value: c.value}));
        let icons = res.data.icons;
        dispatch(setColorsAndIconsOnNotificationsConstructorAC(icons, colors));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}