import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {authAPI} from "../../api/api";
import {getRoles} from "./commonReducer";



export type SignInStateType = {
    email: string,
    password: string,
    emailFromServer: string,
    isLoading: boolean,
    isError: boolean,
    isSuccess: false,
    notification: string
}

const initialState: SignInStateType = {
    email: '',
    password: '',
    emailFromServer: '',
    isLoading: true,
    isError: false,
    isSuccess: false,
    notification: ''
}

export const signInReducer = (state = initialState, action: AllActionType) => {
    switch (action.type) {
        case 'SIGN-IN-SET-COMMON-QUERY-RESULT': {
            return {...state, ...action.result}
        }
        case 'SET-EMAIL-FROM-ANSWER-WITH-TOKEN': {
            return {...state, emailFromServer: action.email}
        }
        default: return state
    }
}


export type setCommonQueryResultSignInACType = ReturnType<typeof setCommonQueryResultSignInAC>;
export type setEmailFromAnswerWithTokenACType = ReturnType<typeof setEmailFromAnswerWithTokenAC>;

//обработка loading и ошибок
export const setCommonQueryResultSignInAC = (result: any) =>
    ({type: 'SIGN-IN-SET-COMMON-QUERY-RESULT', result}as const);

export const setEmailFromAnswerWithTokenAC = (email: string) => ({type: 'SET-EMAIL-FROM-ANSWER-WITH-TOKEN', email}as const);



////////////////по нажатию на кнопку login задействованы 3 следующие санки, в зависимости от наличия токена
export const authLoginSignInTC = (info: {email: string, password: string}, tokenFromURL: string | undefined,  navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authLogin(info).then(res => {
       // dispatch(authLoginDataSignInTC({token: res.data.token}, tokenFromURL, navigate))
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        localStorage.setItem('managerToken', res.data.token);
        navigate('/demo');
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}

//в демосах это вообще не используется
export const authLoginDataSignInTC = (info: {token: string}, tokenFromURL: string | undefined,  navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    authAPI.authLoginData(info).then(res => {
        if(tokenFromURL) {
            dispatch(authLoginInviteJoinSignInTC(tokenFromURL, res.data.token, navigate))
        } else {
           // socket.emit('signIn', res.data.token);
          //  console.log(socket.id)
            dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
            localStorage.setItem('token', res.data.token);
            //getRoles потом закомменть для демосов
            dispatch(getRoles(navigate))
            navigate('/dashboard');
           // navigate('/demos');
        }

    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}

//в демосах этого воббще нет
export const authLoginInviteJoinSignInTC = (tokenFromURL: string, tokenFromResponse: string, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authLoginInviteJoin(tokenFromURL).then(res => {
        //здесь добавила эмит мобытия при логировании токена (если был инвайт)
        //socket.emit('signIn', tokenFromResponse);
       // console.log(socket.id)
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        localStorage.setItem('token', tokenFromResponse);
        dispatch(getRoles(navigate));
       // window.location.href = '/dashboard';
        navigate('/dashboard');
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}
////////////////////////////


//проверка токена при загрузке страницы signIn
export const authLoginInviteCheckTC = (token: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authLoginInviteCheck(token).then(res => {
        let email = res.data.email;

        if(email.length) {
            localStorage.removeItem('email');
            localStorage.setItem('email', email);
            dispatch(setEmailFromAnswerWithTokenAC(email));
        }else {
            let emailFromLS = localStorage.getItem('email');
            emailFromLS && dispatch(setEmailFromAnswerWithTokenAC(emailFromLS));
        }

        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: true, isError: false, notification: res.data.notification}));
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}

//санка для forgotPassword
export const authResetPasswordSignInTC = (info: {email: string}, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authResetPassword(info).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
         //window.location.href = '/checkEmail'
        navigate('/checkEmail')
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));

    })
}

//санка для updatePassword
export const authValidateResetTokenSignInTC = (info: {token: string, password: string}, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authValidateResetToken({token: info.token}).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
        authAPI.authUpdatePassword(info).then(res => {
            //window.location.href = '/dashboard'
            navigate('/dashboard')
        }).catch(err => {

            dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
        })

    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));
    })
}

//санка для reconfirm email
export const authReconfirmEmailSignInTC = (email: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.reconfirmEmail(email).then(res => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: false, notification: ''}));
    }).catch(err => {
        dispatch(setCommonQueryResultSignInAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}));

    })
}

