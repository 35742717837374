import React, {useCallback} from "react";
import style from './tab.module.scss';
import {useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";

type TabPropsType = {
    tabName: string,
    handler?: () => void,
    activeTab: boolean,
    role?: string
}

const Tab = (props: TabPropsType) => {
    const {currentStatus, tourCompleted} = useSelector((state: RootStateType) => state.common);

   let data: {[key: string]: Array<{tab: string, class: string}>} = {
       ['isModerator']: [
           {tab: 'Overview of Company', class: 'overview_of_company_tab'},
           {tab: 'Overview of Teams', class: 'overview_of_teams_tab'}],
       ['isDepartmentAdmin']: [
           {tab: 'Overview of Department', class: 'overview_of_company_tab_DA'},
           {tab: 'Overview of Teams', class: 'overview_of_teams_tab_DA'}],
       ['isAdmin']: [
           {tab: 'Overview', class: 'overview_of_team_leader_tab'},
           {tab: 'Performance & Sentiment', class: 'performance_tab_leader'},
           {tab: 'Insights from unboXt HQ', class: 'insights_tab_leader'},
           {tab: 'Trends', class: 'trends_tab_leader'},
           {tab: 'Motivation Chat Box', class: 'motivation_tab_leader'},
           {tab: 'Team Information', class: 'team_management_information_tab_leader'},
           {tab: 'Check-In Report', class: 'team_management_report_tab_leader'},],
       ['isMember']: [
           {tab: 'Overview', class: 'overview_of_team_member_tab'},
           {tab: 'Performance & Sentiment', class: 'performance_tab_member'},
           {tab: 'Insights from unboXt HQ', class: 'insights_tab_member'},
           {tab: 'Trends', class: 'trends_tab_member'},
           {tab: 'Motivation Chat Box', class: 'motivation_tab_member'},]
   }

    const getAdditionalTourClass = (tabName: string, currentStatus: string) => {
        if(!tourCompleted) {
            return data[currentStatus]?.find(i => i.tab === tabName)?.class || '';
        }else return ''
    };

    const additionalClassTour = getAdditionalTourClass(props.tabName, currentStatus);

    return(
        <div className={`${style.tab} ${props.activeTab && style.active} ${additionalClassTour}`}
        onClick={props.handler}>
            <div>{props.tabName}</div>
        </div>
    )
}

export default Tab;