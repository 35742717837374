import React, {ChangeEvent} from "react";
import style from './itemWithTextarea.module.scss';
import {NotificationType} from "../../../../../redux/reducers/notificationsMenuReducer";

export type ItemWithTextareaPropsType = {
  //  placeholder: string | null,
    sendMessage: (teamId: number, post: {text: string, anonymously: boolean, isMotivation: boolean}) => void,
    //notificationId: number,
    notification: NotificationType
   // notificationType: number
}

const ItemWithTextarea = (props: ItemWithTextareaPropsType) => {

    //для анонимности
    const [anonym, changeAnonym] = React.useState<boolean>(false);

    //срабатывает когда по инпуту чекаем
    const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        changeAnonym(e.target.checked);
    }

    //для изменения текста в текстареа
    const [text, changeText] = React.useState<string>('');

    const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        changeText(e.currentTarget.value);
    }

    //жмем на кнопку отправить (Send)
    const onSendMessage = () => {
       // (teamId: number, post: {text: string, anonymously: boolean, isMotivation: boolean})
        if(text.trim().length) {
            let message = text.replace(/\n/ig, ' ')
            let post = {
                text: message,
                anonymously: anonym,
                isMotivation: false
            }

          // props.notificationType === 1 ? props.sendMessage(text.replace(/\n/ig, ' '), anonym) : props.sendMessage(text.replace(/\n/ig, ' '))
            changeText('')
            props.sendMessage(props.notification.teamId || 1, post)
        }
    }

    //по нажатию на Enter отправляется сообщение
    // const enterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    //     //e.preventDefault();
    //     if(e.charCode === 13 && text.trim().length) {
    //         props.sendMessage(text, anonym)
    //     }
    // }

    const disabledButton = !text.trim().length;




    return (
        <>
            <div className={style.block_with_textarea}>
                <textarea
                    placeholder={props.notification.textAreaPlaceholder || ''}
                        // onKeyPress={enterPress}
                         onChange={onChangeText}
                         value={text}/>
                <p className={style.send_button} onClick={onSendMessage} id={`${props.notification.id}`} data-tooltipe={`Action completed!`}
                      style={{color: disabledButton ? 'rgba(38, 151, 254, .5)' : 'rgba(38, 151, 254, 1)'}}>
                    <span className={style.btn_span}></span>
                    Send
                </p>
            </div>

            {props.notification.textAreaAnonymous && <div className={style.block_with_checkbox}>
                <input className={style.block_with_checkbox_input} onChange={onCheck}
                       id={`anonymity ${props.notification.id}`}
                       type="checkbox"
                       name="anonymity"/>
                <label className={style.block_with_checkbox_label}
                       htmlFor={`anonymity ${props.notification.id}`}
                >Send Anonymously</label>
            </div>}

        </>
    )
}

export default ItemWithTextarea;