import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {authAPI} from "../../api/api";


export type SignUpStateType = {
    isDST: boolean,
    timeZone: string,
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    phone: string,
    isLoading: boolean,
    isError: boolean,
    isSuccess: boolean,
    notification: string,
    checkinInviteData: {
        company: string,
        email: string
    },
    isRecaptchaTokenValid: boolean,
}

const initialState: SignUpStateType = {
    isDST: false,
    timeZone: '',
    email: '',
    companyName: '',
    firstName: '',
    lastName: '',
    phone: '',
    isLoading: false,
    isError: false,
    isSuccess: false,
    notification: '',
    checkinInviteData: {
        company: '',
        email: ''
    },
    isRecaptchaTokenValid: false
}

export const signUpReducer = (state = initialState, action: AllActionType): SignUpStateType => {
    switch (action.type) {
        case 'SIGN-UP-SET-ISDST': {
            return {...state, isDST: action.isDST}
        }
        case 'SIGN-UP-SET-TIME-ZONE': {
            return {...state, timeZone: action.timeZone}
        }
        case 'SIGN-UP-SET-SIGN-UP-INFO': {
            return {...state, ...action.info}
        }
        case 'SIGN-UP-SET-COMMON-QUERY-RESULT': {
            return {...state, ...action.result}
        }
        case 'SIGN-UP-SET-CHECKIN-INVITE-DATA': {
            return {...state, checkinInviteData: {...action.data}}
        }
        case 'SET-RECAPTCHA-TOKEN-VALID-SIGN-UP': {
            return {...state, isRecaptchaTokenValid: action.isValid}
        }
        default: return state
    }
}

export type setISDSTSignUpACType = ReturnType<typeof setISDSTSignUpAC>;
export type setTimeZoneSignUpACType = ReturnType<typeof setTimeZoneSignUpAC>;
export type setSignUpInfoACType = ReturnType<typeof setSignUpInfoAC>;
export type setCheckinInviteDataSignUpACType = ReturnType<typeof setCheckinInviteDataSignUpAC>;
export type setCommonQueryResultSignUpACType = ReturnType<typeof setCommonQueryResultSignUpAC>;
export type setRecaptchaTokenValidSignUpACType = ReturnType<typeof setRecaptchaTokenValidSignUpAC>;

export const setISDSTSignUpAC = (isDST: boolean) => ({type: 'SIGN-UP-SET-ISDST', isDST}as const);
export const setTimeZoneSignUpAC = (timeZone: string) => ({type: 'SIGN-UP-SET-TIME-ZONE', timeZone}as const);
export const setSignUpInfoAC = (info: {email: string, companyName: string, firstName: string, lastName: string, phone: string, timeZone: string}) => ({type: 'SIGN-UP-SET-SIGN-UP-INFO', info}as const);
export const setCheckinInviteDataSignUpAC = (data: {company: string, email: string}) => ({type: 'SIGN-UP-SET-CHECKIN-INVITE-DATA', data}as const);
export const setRecaptchaTokenValidSignUpAC = (isValid: boolean) => ({type: 'SET-RECAPTCHA-TOKEN-VALID-SIGN-UP', isValid}as const);

//обработка loading и ошибок
export const setCommonQueryResultSignUpAC = (result: any) =>
    ({type: 'SIGN-UP-SET-COMMON-QUERY-RESULT', result}as const);

export const signUpTC = (info: {email: string, companyName: string, firstName: string, lastName: string, phone: string, timeZone: string}, navigate: (url: string) => void, token?: string): AppThunk => async (dispatch, getState: () => RootStateType) => {

   if(token) {
       authAPI.signupWithToken({...info, token: token}).then(res => {
           dispatch(setSignUpInfoAC(info));
           dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: false, notification: ''}))
 if(res.data.sentEmail) {
    // window.location.href = '/checkEmail';
     navigate('/checkEmail');
 }else {
     //window.location.href = `/setPassword/${res.data.token}`;
     navigate(`/setPassword/${res.data.token}`);
 }

       }).catch(err => {
           dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}))
       })
   }else {
       authAPI.signup(info).then(res => {
           dispatch(setSignUpInfoAC(info));
           dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: false, notification: ''}))
          // window.location.href = '/checkEmail';
           navigate('/checkEmail')
       }).catch(err => {
           dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}))
       })
   }
}

export const checkRecaptchaSignUpTC = (response: any): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.checkRecaptcha(response).then((res) => {
        if (res.status === 200) {
            dispatch(setRecaptchaTokenValidSignUpAC(true));
        }
    })
        .catch((error) => {
            dispatch(setRecaptchaTokenValidSignUpAC(false));
            dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: true, notification: error.response.data.message}));

        })
}

export const signUpAuthCheckInviteTC = (token: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    authAPI.authCheckInvite(token).then(res => {
        dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: false, notification: ''}))
        dispatch(setCheckinInviteDataSignUpAC({company: res.data.company, email: res.data.email}))
    }).catch(err => {
        dispatch(setCommonQueryResultSignUpAC({isLoading: false, isSuccess: false, isError: true, notification: err.response.data.message}))
    })
}



