import React, {useEffect, useState} from "react";
import style from './switchAccount.module.scss';
import Membership from "./membership/membership";
import Section from "./section/section";
import profile from '../../../assets/images/switchAccount/profile.png';
import membership from '../../../assets/images/switchAccount/membership.png';
import password from '../../../assets/images/switchAccount/password.png';
import personality from '../../../assets/images/switchAccount/personality.png';
import signOut from '../../../assets/images/switchAccount/signOut.png';
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../redux/store/store";
import {NavLink, useNavigate} from "react-router-dom";
import {
    changeUserDataTC,
    setAnchorElForAccountMenuAC,
    setUserInfoAC
} from "../../../redux/reducers/commonReducer";
import {ProfileActiveTabType, setActiveTabProfileAC} from "../../../redux/reducers/profileReducer";
import {setPreviewModeDigitalPulseAC} from "../../../redux/reducers/digitalPulseReducer";
import InsertedImage from "../insertedImage/insertedImage";
import editPencil from "../../../assets/images/common/pencil_demo.png";
import EditModalWindow, {EditModalWindowFlagType} from "../../pages/demos/editModalWindow/editModalWindow";
import {setEditModalWindowDemoReducerAC} from "../../../redux/reducers/demoReducer";
import {changeUserDataType} from "../../pages/companyManagement/companyInformation/companyInformation";

export type UserType = {
    name: string,
    email: string,
    shortName: string,
    role: string
}

const SwitchAccount = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    //здесь из стэйта подтянуть всю инфу о пользователе
    //если больше одной роли то превращаем инфу в такой вид
    const user = useSelector((state: RootStateType) => state.common.userInfo);
    const {roles, currentStatus} = useSelector((state: RootStateType) => state.common);

    //для сокрытия аккаунтов берем роли из локалстореджа
    let allRoles = localStorage.getItem('all_roles') || '';
    let updatedRoles = localStorage.getItem('updated_roles') || '';

    //array for rendering roles in menu with we want to return
    let a = updatedRoles.length && JSON.parse(updatedRoles)?.map((q: any) => q.name);
    let rolesForReturning = a ? JSON.parse(allRoles)?.filter((r: any) => !a?.includes(r.name)) : [];

    const userInfo = updatedRoles.length ? JSON.parse(updatedRoles).map((role: {
            name: string,
            value: boolean
        }) => ({...user, role: role.name}))
        : JSON.parse(allRoles).map((role: { name: string, value: boolean }) => ({...user, role: role.name}));

    const demoMode = useSelector((state: RootStateType) => state.demo.mode.label);

    const {isEditModalWindowOpen} = useSelector((state: RootStateType) => state.demo);
    const [editFlag, setEditFlag] = useState<EditModalWindowFlagType | ''>('');

    const manageEditModalWindow = (flag: EditModalWindowFlagType | '', isOpen: boolean) => {
        //закрываем или открывем окно и присваиваем флагу пустую строку либо значение
        setEditFlag(flag);
        dispatch(setEditModalWindowDemoReducerAC(isOpen));
    }

   // const [initialValuesForEditModalWindow, setInitialValuesForEditModalWindow] = useState<any>(null);

    let userFirstName = user.name.split(' ')[0];
    let userLastName = user.name.split(' ')[1];
    const initialValuesForEditModalWindow = {firstName: userFirstName, lastName: userLastName, email: user.email};

    const onClickSignOut = () => {
        let email = localStorage.getItem('email');
        localStorage.clear();
        email && localStorage.setItem('email', email);
        // navigate('/signIn');
        window.location.href = '/signIn'
    }

    const onClickSection = (section: ProfileActiveTabType) => {
        if (section === 'feedback') {
            navigate('/feedback');
        } else {
            navigate('/profile');
            dispatch(setActiveTabProfileAC(section));
        }
        dispatch(setAnchorElForAccountMenuAC(null));
        dispatch(setPreviewModeDigitalPulseAC(false));
    }

    const onChangeUserAccountData = (data: changeUserDataType,
                                     setError: (notification: string) => void,
                                     setSuccess: (notification: string) => void) => {
        dispatch(changeUserDataTC(data, setError, setSuccess))
    }

    const returnRole = (r: { name: string, value: boolean }) => {
        let a = localStorage.getItem('updated_roles') || '';
        let updatedRoles = a.length && JSON.parse(a);
        let newUpdatedRoles = [...updatedRoles, r];
        localStorage.setItem('updated_roles', JSON.stringify(newUpdatedRoles));
        dispatch(setAnchorElForAccountMenuAC(null));
    }


    return (
        <div className={`${style.switch_account_window} accounts_menu`}>
            {isEditModalWindowOpen && <EditModalWindow closeEditModalWindow={manageEditModalWindow}
                                                       handlerSubmit={onChangeUserAccountData}
                                                       flag={editFlag}
                                                       initialValues={initialValuesForEditModalWindow}/>}
            {demoMode === 'edit' && <div style={{
                position: 'absolute',
                top: 10,
                left: 10,
                width: '1.3rem',
                height: '1.3rem'
            }}>
                <InsertedImage height={1.3}
                               width={1.3}
                               image={editPencil}
                               cursor={'pointer'}
                               handler={() => manageEditModalWindow('user account data', true)}/>
            </div>
            }
            <div className={style.switch_account_window_header}>Account</div>
            <div className={style.switch_account_separator}></div>
            {
                userInfo.map((user: any, index: number) => <Membership key={index} user={user}/>)
            }
            <Section image={profile} name={'Profile'} onClickHandler={() => onClickSection('edit')}/>
            {(currentStatus === 'isModerator' || demoMode === 'edit') &&
                <Section image={membership} name={'Membership'} onClickHandler={() => onClickSection('membership')}/>}
            <Section image={password} name={'Password & Security'} onClickHandler={() => onClickSection('security')}/>
            <Section image={personality} name={'Personality Assesment'}
                     onClickHandler={() => onClickSection('assessment')}/>

            {rolesForReturning.length && demoMode === 'edit' ?
                <>
                    <div className={style.switch_account_separator}></div>
                    <div className={style.back_role_block}>
                        <h3>Get role back:</h3>
                        <div className={style.roles_wrapper}>
                            {rolesForReturning.map((r: { name: string, value: boolean }, index: number) =>
                                <p onClick={() => returnRole(r)}
                                key={index}>{r.name === 'isDepartmentAdmin' ? 'Department Leader'
                                    : r.name === 'isAdmin' ? 'Team Leader' : r.name === 'isModerator' ? 'Administrator' : 'Member'}</p>)}
                        </div>
                    </div>
                </> : null}

            <div className={style.switch_account_separator}></div>
            <Section image={signOut} name={'Sign Out'} onClickHandler={onClickSignOut}/>


        </div>
    )
}

export default SwitchAccount;