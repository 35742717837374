import React, {useEffect} from "react";
import style from './alert.module.scss';
import {AlertItemType, removeAlertMessageCommonReducerAC} from "../../../redux/reducers/commonReducer";
import {useDispatch} from "react-redux";

//each alert is autocloseable after 5 seconds
const Alert = ({id, message, status, showCloseButton}: AlertItemType) => {
    const dispatch = useDispatch();

    const deleteAlert = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(removeAlertMessageCommonReducerAC(id))
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(removeAlertMessageCommonReducerAC(id))
        }, 5000);
    }, [])

    return (
        <div className={style.error_container}>
            <div className={`${style.error_content} ${status === 'success' && style.success}`}>
                <button type="button" className={style.close} style={{display: showCloseButton ? 'block' : 'none'}}
                        onClick={deleteAlert}>×
                </button>
                <div>{message}</div>
            </div>
        </div>
    )
}

export default Alert;