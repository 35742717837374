import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {coachingCornerAPI} from "../../api/api";
import {InsightItemType} from "../../components/pages/coachingCorner/Insights/insights";
import {ActiveTabCoachingCorner} from "../../components/pages/coachingCorner/coachingCorner";
import {
    UpdateItemDataAnswerType
} from "../../components/pages/coachingCorner/addItemModalWindowBody/addItemModalWIndowBody";
import {addAlertMessageCommonReducerAC} from "./commonReducer";
import {v1} from "uuid";
import {errorAlertItem} from "../../common";

export type CoachingCornerPostType = {
    anonymously: boolean,
    createdAt: string,
    daysAgo: string,
    id: number,
    logo_url: null | string,
    name: string,
    seen: boolean,
    source: string,
    text: string
}

export type CoachingCornerTeamType = {
    id: number,
    isAdmin: boolean,
    isMember: boolean,
    leader: string,
    name: string,
    newMessages: string
}

export type CoachingCornerStateType = {
    activeTab: ActiveTabCoachingCorner,
    insights: any,
    trends: any,
    availableTeams: Array<CoachingCornerTeamType>,
    chosenTeamId: number | null,
    loading: boolean,
    offset: number,
    cancelLoading: boolean,
    itemInModalWindow: null | InsightItemType,
    newPosts: Array<CoachingCornerPostType>,
    oldPosts: Array<CoachingCornerPostType>,
    userPhoto: null | string,
    postFromMotivation: boolean
}

const initialState: CoachingCornerStateType = {
    activeTab: 'insights',
    insights: [],
    trends: [],
    availableTeams: [],
    chosenTeamId: null,
    loading: false,
    offset: 0,
    cancelLoading: false,
    itemInModalWindow: null,
    newPosts: [],
    oldPosts: [],
    userPhoto: null,
    postFromMotivation: false
}

export const coachingCornerReducer = (state = initialState, action: AllActionType): CoachingCornerStateType => {
    switch (action.type) {
        case 'SET-ACTIVE-TAB-COACHING-CORNER': {
            return {...state, activeTab: action.tab}
        }
        case 'COACHING-CORNER-SET-TRENDS': {
            return {
                ...state,
                trends: [...state.trends, ...action.trends],
                offset: [...state.trends, ...action.trends].length,
                loading: false,
                cancelLoading: action.trends.length < 5
            }
        }
        case 'COACHING-CORNER-SET-INSIGHTS': {
            return {
                ...state,
                insights: [...state.insights, ...action.insights],
                offset: [...state.insights, ...action.insights].length,
                loading: false,
                cancelLoading: action.insights.length < 5
            }
        }
        case 'CLEAR-INSIGHTS-AND-OFFSET-COACHING-CORNER': {
            return {...state, insights: [], offset: 0}
        }
        case 'CLEAR-TRENDS-AND-OFFSET-COACHING-CORNER': {
            return {...state, trends: [], offset: 0}
        }
        case 'COACHING-CORNER-SET-LOADING': {
            return {...state, loading: action.loading}
        }
        case 'COACHING-CORNER-SET-AVAILABLE-TEAMS': {
            return {...state, availableTeams: [...action.teams]}
        }
        case 'COACHING-CORNER-SET-CHOSEN-TEAM-ID': {
            return {...state, chosenTeamId: action.teamId}
        }
        case 'COACHING-CORNER-SET-CHOSEN-ITEM-IN-MODAL-WINDOW': {
            return {...state, itemInModalWindow: {...action.item}}
        }
        case 'COACHING-CORNER-MAKE-INSIGHT-SEEN': {
            return {
                ...state,
                insights: state.insights.map((i: any) => i.id === action.insightId ? {...i, seen: true} : i)
            }
        }
        case 'COACHING-CORNER-MAKE-TREND-SEEN': {
            return {...state, trends: state.trends.map((i: any) => i.id === action.trendId ? {...i, seen: true} : i)}
        }
        case 'COACHING-CORNER-LIKE-INSIGHT': {
            return {
                ...state,
                insights: state.insights.map((i: any) => i.id === action.insightId ? {
                    ...i,
                    liked: true,
                    disliked: false
                } : i)
            }
        }
        case 'COACHING-CORNER-DISLIKE-INSIGHT': {
            return {
                ...state,
                insights: state.insights.map((i: any) => i.id === action.insightId ? {
                    ...i,
                    liked: false,
                    disliked: true
                } : i)
            }
        }
        case 'COACHING-CORNER-LIKE-TREND': {
            return {
                ...state,
                trends: state.trends.map((i: any) => i.id === action.trendId ? {...i, liked: true, disliked: false} : i)
            }
        }
        case 'COACHING-CORNER-DISLIKE-TREND': {
            return {
                ...state,
                trends: state.trends.map((i: any) => i.id === action.trendId ? {...i, liked: false, disliked: true} : i)
            }
        }
        case 'COACHING-CORNER-CHAT-BOX-SET-POSTS': {
            return {
                ...state,
                newPosts: [...action.newPosts],
                oldPosts: [...action.oldPosts],
                userPhoto: action.userPhoto
            }
        }
        case 'COACHING-CORNER-CHAT-BOX-ADD-NEW-POST': {
            return {...state, oldPosts: [action.newPost, ...state.oldPosts]}
        }
        case 'COACHING-CORNER-CHAT-BOX-SET-FROM-MOTIVATION': {
            return {...state, postFromMotivation: action.fromMotivation}
        }
        case 'UPDATE-ITEM-COACHING-CORNER': {
            return action.itemType === 'insight' ? {
                    ...state,
                    insights: state.insights.map((i: any) => i.id === action.item.id ? {...action.item} : i)
                }
                : {...state, trends: state.trends.map((i: any) => i.id === action.item.id ? {...action.item} : i)}
        }
        case 'CREATE-ITEM-COACHING-CORNER': {
            return action.itemType === 'insight' ? {...state, insights: [action.item, ...state.insights]}
                : {...state, trends: [action.item, ...state.trends]}
        }
        case 'DELETE-ITEM-COACHING-CORNER': {
            return action.itemType === 'insight' ? {
                    ...state,
                    insights: state.insights.filter((i: any) => i.id !== action.id)
                }
                : {...state, trends: state.trends.filter((t: any) => t.id !== action.id)}
        }
        default:
            return state
    }
}

export type activeTabCoachingCornerACType = ReturnType<typeof activeTabCoachingCornerAC>;
export type setLoadingCoachingCornerACType = ReturnType<typeof setLoadingCoachingCornerAC>;
export type setAvailableTeamsCoachingCornerACType = ReturnType<typeof setAvailableTeamsCoachingCornerAC>;
export type setChosenTeamCoachingCornerACType = ReturnType<typeof setChosenTeamCoachingCornerAC>;
export type setTrendsCoachingCornerACType = ReturnType<typeof setTrendsCoachingCornerAC>;
export type setInsightsCoachingCornerACType = ReturnType<typeof setInsightsCoachingCornerAC>;
export type setChosenItemInModalWindowCoachingCornerACType = ReturnType<typeof setChosenItemInModalWindowCoachingCornerAC>;
export type makeInsightSeenCoachingCornerACType = ReturnType<typeof makeInsightSeenCoachingCornerAC>;
export type makeTrendSeenCoachingCornerACType = ReturnType<typeof makeTrendSeenCoachingCornerAC>;
export type likeInsightCoachingCornerACType = ReturnType<typeof likeInsightCoachingCornerAC>;
export type dislikeInsightCoachingCornerACType = ReturnType<typeof dislikeInsightCoachingCornerAC>;
export type likeTrendCoachingCornerACType = ReturnType<typeof likeTrendCoachingCornerAC>;
export type dislikeTrendCoachingCornerACType = ReturnType<typeof dislikeTrendCoachingCornerAC>;

export type setPostChatBoxCoachingCornerACType = ReturnType<typeof setPostChatBoxCoachingCornerAC>;
export type addNewPostChatBoxCoachingCornerACType = ReturnType<typeof addNewPostChatBoxCoachingCornerAC>;
export type setPostFromMotivationChatBoxCoachingCornerACType = ReturnType<typeof setPostFromMotivationChatBoxCoachingCornerAC>;

export type clearInsightsAndOffsetCoachingCornerACType = ReturnType<typeof clearInsightsAndOffsetCoachingCornerAC>;
export type clearTrendsAndOffsetCoachingCornerACType = ReturnType<typeof clearTrendsAndOffsetCoachingCornerAC>;

export type updateItemCoachingCornerACType = ReturnType<typeof updateItemCoachingCornerAC>;
export type createItemCoachingCornerACType = ReturnType<typeof createItemCoachingCornerAC>;
export type deleteItemCoachingCornerACType = ReturnType<typeof deleteItemCoachingCornerAC>;

export const activeTabCoachingCornerAC = (tab: ActiveTabCoachingCorner) => ({
    type: 'SET-ACTIVE-TAB-COACHING-CORNER',
    tab
} as const);

export const setLoadingCoachingCornerAC = (loading: boolean) => ({
    type: 'COACHING-CORNER-SET-LOADING',
    loading
} as const);
export const setAvailableTeamsCoachingCornerAC = (teams: Array<CoachingCornerTeamType>) => ({
    type: 'COACHING-CORNER-SET-AVAILABLE-TEAMS',
    teams
} as const);
export const setChosenTeamCoachingCornerAC = (teamId: number | null) => ({
    type: 'COACHING-CORNER-SET-CHOSEN-TEAM-ID',
    teamId
} as const);
export const setTrendsCoachingCornerAC = (trends: any) => ({
    type: 'COACHING-CORNER-SET-TRENDS', trends
} as const);
export const setInsightsCoachingCornerAC = (insights: any) => ({
    type: 'COACHING-CORNER-SET-INSIGHTS', insights
} as const);

export const setChosenItemInModalWindowCoachingCornerAC = (item: any) => ({
    type: 'COACHING-CORNER-SET-CHOSEN-ITEM-IN-MODAL-WINDOW',
    item
} as const);
export const makeInsightSeenCoachingCornerAC = (insightId: number) => ({
    type: 'COACHING-CORNER-MAKE-INSIGHT-SEEN',
    insightId
} as const);
export const makeTrendSeenCoachingCornerAC = (trendId: number) => ({
    type: 'COACHING-CORNER-MAKE-TREND-SEEN',
    trendId
} as const);
export const likeInsightCoachingCornerAC = (insightId: number) => ({
    type: 'COACHING-CORNER-LIKE-INSIGHT',
    insightId
} as const);
export const dislikeInsightCoachingCornerAC = (insightId: number) => ({
    type: 'COACHING-CORNER-DISLIKE-INSIGHT',
    insightId
} as const);
export const likeTrendCoachingCornerAC = (trendId: number) => ({type: 'COACHING-CORNER-LIKE-TREND', trendId} as const);
export const dislikeTrendCoachingCornerAC = (trendId: number) => ({
    type: 'COACHING-CORNER-DISLIKE-TREND',
    trendId
} as const);

export const setPostChatBoxCoachingCornerAC = (newPosts: Array<CoachingCornerPostType>, oldPosts: Array<CoachingCornerPostType>, userPhoto: string | null) => ({
    type: 'COACHING-CORNER-CHAT-BOX-SET-POSTS',
    newPosts,
    oldPosts,
    userPhoto
} as const);
export const addNewPostChatBoxCoachingCornerAC = (newPost: CoachingCornerPostType) => ({
    type: 'COACHING-CORNER-CHAT-BOX-ADD-NEW-POST',
    newPost
} as const)
export const setPostFromMotivationChatBoxCoachingCornerAC = (fromMotivation: boolean) => ({
    type: 'COACHING-CORNER-CHAT-BOX-SET-FROM-MOTIVATION',
    fromMotivation
} as const);

export const clearInsightsAndOffsetCoachingCornerAC = () => ({type: 'CLEAR-INSIGHTS-AND-OFFSET-COACHING-CORNER'} as const);
export const clearTrendsAndOffsetCoachingCornerAC = () => ({type: 'CLEAR-TRENDS-AND-OFFSET-COACHING-CORNER'} as const);

//for demos
export const updateItemCoachingCornerAC = (item: any,
                                           itemType: 'insight' | 'trend') => ({
    type: 'UPDATE-ITEM-COACHING-CORNER',
    item,
    itemType
} as const);

export const createItemCoachingCornerAC = (item: any, itemType: 'insight' | 'trend') => ({
    type: 'CREATE-ITEM-COACHING-CORNER',
    item,
    itemType
} as const);
export const deleteItemCoachingCornerAC = (id: number, itemType: 'insight' | 'trend') => ({
    type: 'DELETE-ITEM-COACHING-CORNER',
    id,
    itemType
} as const);


export const getTeamsCoachingCornerTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    const state = getState();
    dispatch(setLoadingCoachingCornerAC(true));
    coachingCornerAPI.getAllTeams().then(res => {
        let teams = res.data.teams;
        dispatch(setAvailableTeamsCoachingCornerAC(teams));

        //если мы с одной демки на другую переходим, там разные команды. это для проверки
        let check = teams.find((team: any) => team.id === state.coachingCorner.chosenTeamId);

        if (!state.coachingCorner.chosenTeamId || !check) {
            dispatch(setChosenTeamCoachingCornerAC(teams[0].id))
        }
        dispatch(setLoadingCoachingCornerAC(false));
    }).catch(err => {
        dispatch(setLoadingCoachingCornerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerGetInsightsTC = (scroll: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let state = getState();
    let teamId = state.coachingCorner.chosenTeamId;
    let offset = state.coachingCorner.offset;

    coachingCornerAPI.getCoachingCornerInsights(teamId, offset, scroll).then(res => {
        dispatch(setInsightsCoachingCornerAC(res.data.insights));
    }).catch(err => {
        dispatch(setLoadingCoachingCornerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerLikeInsightTC = (insightId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    coachingCornerAPI.likeItem(insightId).then(res => {
        dispatch(likeInsightCoachingCornerAC(insightId));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerDislikeInsightTC = (insightId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    coachingCornerAPI.dislikeItem(insightId).then(res => {
        dispatch(dislikeInsightCoachingCornerAC(insightId));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerGetTrendsTC = (scroll: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let state = getState();
    let offset = state.coachingCorner.offset;
    let teamId = state.coachingCorner.chosenTeamId;

    coachingCornerAPI.getCoachingCornerTrends(teamId, offset, scroll).then(res => {
        dispatch(setTrendsCoachingCornerAC(res.data.trends));
    }).catch(err => {
        dispatch(setLoadingCoachingCornerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerLikeTrendTC = (insightId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    coachingCornerAPI.likeItem(insightId).then(res => {
        dispatch(likeTrendCoachingCornerAC(insightId));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerDislikeTrendTC = (insightId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    coachingCornerAPI.dislikeItem(insightId).then(res => {
        dispatch(dislikeTrendCoachingCornerAC(insightId));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerGetPostsTC = (teamId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingCoachingCornerAC(true));
    coachingCornerAPI.getPosts(teamId).then(res => {
        dispatch(setLoadingCoachingCornerAC(false));
        dispatch(setPostChatBoxCoachingCornerAC(res.data.newPosts, res.data.oldPosts, res.data.userPhoto))
    }).catch(err => {
        dispatch(setLoadingCoachingCornerAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerReadPostTC = (postId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let userPhoto = getState().coachingCorner.userPhoto;
    let readPost = getState().coachingCorner.newPosts.find(post => post.id === postId);
    let newReadPost = readPost ? {...readPost, seen: true} : readPost;
    let newPosts = getState().coachingCorner.newPosts.filter(post => post.id !== postId);
    let oldPosts = getState().coachingCorner.oldPosts;
    newReadPost && oldPosts.push(newReadPost);

    coachingCornerAPI.readPost(postId).then(res => {
        dispatch(setPostChatBoxCoachingCornerAC(newPosts, oldPosts, userPhoto));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerAddNewPostTC = (teamId: number,
                                           post: CoachingCornerPostType,
                                           answer: {text: string, anonymously: boolean, isMotivation: boolean }): AppThunk => async (dispatch, getState: () => RootStateType) => {
    coachingCornerAPI.addNewPost(teamId, answer).then(res => {
        dispatch(addNewPostChatBoxCoachingCornerAC(post));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

//для демок
export const coachingCornerUpdateItemTC = (answer: UpdateItemDataAnswerType,
                                           itemType: 'insight' | 'trend',
                                           closeModalWindow: () => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {

    coachingCornerAPI.updateItem(answer).then(res => {
        dispatch(updateItemCoachingCornerAC(res.data.notification, itemType));
        closeModalWindow();
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerAddNewItemTC = (answer: UpdateItemDataAnswerType,
                                           itemType: 'insight' | 'trend',
                                           closeModalWindow: () => void,): AppThunk => async (dispatch, getState: () => RootStateType) => {

    coachingCornerAPI.createItem(answer).then(res => {
        dispatch(createItemCoachingCornerAC(res.data.notification, itemType));
        closeModalWindow();
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const coachingCornerDeleteItemTC = (answer: { id: number, notificationType: string },
                                           itemType: 'insight' | 'trend'): AppThunk => async (dispatch, getState: () => RootStateType) => {

    coachingCornerAPI.deleteItem(answer).then(res => {
        dispatch(deleteItemCoachingCornerAC(answer.id, itemType));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}
