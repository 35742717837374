import React, {useEffect, useMemo, useState} from "react";
import style from './addItemModalWindowBody.module.scss';
import ButtonWithImage from "../../../parts/buttonWithImage/buttonWithImage";
import save from "../../../../assets/images/common/save.png";
import {useForm, Controller} from "react-hook-form";
import Select, {StylesConfig} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootStateType} from "../../../../redux/store/store";
import chroma from 'chroma-js';
import {
    coachingCornerAddNewItemTC,
    coachingCornerUpdateItemTC
} from "../../../../redux/reducers/coachingCornerReducer";
import item from "../../digitalPulse/ready/item/item";

type ColourOption = {
    label: string,
    value: string
}

type AddItemModalWindowBodyPropsType = {
    itemType: 'insight' | 'trend',
    actionType: 'create' | 'update' | null,
    closeModalWindow: () => void,
}

export type UpdateItemDataAnswerType = {
    [x: string]: number | string,
    // daysAgo: string,
    // color: string,
    // notificationType: string,
    // insightBody: string,
}
const AddItemModalWindowBody = (props: AddItemModalWindowBodyPropsType) => {
    const dispatch = useDispatch();

    let item = useSelector((state: RootStateType) => state.coachingCorner.itemInModalWindow);
    let teamId = useSelector((state: RootStateType) => state.coachingCorner.chosenTeamId);

    const trendsTypes = [
        {label: 'performance', value: 'perfomance_trend'},
        {label: 'motivation', value: 'motivation_trend'},
        {label: 'sentiment', value: 'sentiment_trend'}
    ];

    const insightsTypes = [
        {label: 'motivation', value: 'motivation_insight'},
        {label: 'sentiment', value: 'sentiment_insight'},
        {label: 'performance', value: 'perfomance_insight'},
        {label: 'checkIn', value: 'checkIn_insight'},
        {label: 'norms', value: 'norms_insight'},
    ]

    // const colors = [
    //     {label: 'yellow', value: '#FFC20E'},
    //     {label: 'green', value: '#6EBC20'},
    //     {label: 'red', value: '#db022b'},
    //     {label: 'default', value: '#DCDCDC'},
    // ];

    const colors = [
        {label: 'good', value: '#6EBC20'},
        {label: 'neutral', value: '#FFC20E'},
        {label: 'bad', value: '#db022b'},
        {label: 'default', value: '#DCDCDC'},
    ];

    const types = props.itemType === 'insight' ? insightsTypes : trendsTypes;

    let initialColor = (item?.color === 'very_good' || item?.color === 'good') ? colors.find(c => c.label === 'good')
            : item?.color === 'neutral' ? colors.find(c => c.label === 'neutral')
                : item?.color === 'bad' ? colors.find(c => c.label === 'bad') : colors.find(c => c.label === 'default');

    let initialType = types.find(t => {
        if (t.value === item?.notificationType) return t;
    });

    const defaultTime = 1;


    const onSubmit = (data: any) => {

        //нужно айди айтема item.id
        let answer: UpdateItemDataAnswerType = {
            [props.actionType === 'create' ? 'teamId' : 'id']: props.actionType === 'create' ? teamId : (item?.id || 0),
            daysAgo: `${data.time} days ago`,
            color: props.itemType === 'trend' ? data.color.label : 'default',
            notificationType: data.type.value,
            insightBody: data.text,
        };
        (props.actionType === 'update') && teamId && dispatch(coachingCornerUpdateItemTC(answer, props.itemType, props.closeModalWindow));
        (props.actionType === 'create') && teamId && dispatch(coachingCornerAddNewItemTC(answer, props.itemType, props.closeModalWindow));
    }

    //это для цветного селекта
    const colourStyles: StylesConfig<ColourOption, true> = {
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            const color = chroma(data.value);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.value
                        : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : chroma.contrast(color, 'white') > 1
                            ? data.value
                            : 'black',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.value
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        },
        singleValue: (styles, {data}) => {
            const color = chroma(data.value);
            return {
                ...styles,
                backgroundColor: color.alpha(.3).css(),
                color: color.alpha(1).css(),
                padding: '5px'
            };
        },
    };


    const {
        register,
        control,
        formState: {
            errors,
            isValid,
        },
        handleSubmit,
        reset,
        setValue,
        getValues
    } = useForm({
        mode: "onBlur",  //когда происходит валидация (по дефолту OnSubmit)
        defaultValues: useMemo(() => ({
            time: defaultTime,
            text: item?.insightBody,
            color: initialColor,
            type: initialType
        }), [item?.id])
    });

    useEffect(() => {
        let tx = document.getElementById("auto_textarea");
        tx?.addEventListener("input", OnInput, false);

        function OnInput() {
            if (tx) {
                tx.style.height = "auto";
                tx.style.resize = "none";
                tx.style.height = (tx.scrollHeight) + "px";
            }
        }
    }, [])

    return (

        <form onSubmit={handleSubmit(onSubmit)}>

            <div className={style.input_text_wrapper}>
                <label htmlFor="time">Days ago:</label>
                <input {...register('time', {
                    required: 'Days is required!'
                })} type={'number'}/>
            </div>
            {errors.time && <div className={style.error_message}>{errors?.time?.message || 'error!'}</div>}

            <div className={style.input_group_wrapper}>
                <div className={style.input_text_wrapper}>
                    <label htmlFor="type">Type:</label>
                    <Controller control={control}
                                name={'type'}
                                rules={{
                                    required: true,
                                }}
                                render={({field: {onChange, value, ref, name}}) => (
                                    <Select styles={{singleValue: (provided) => ({...provided, padding: '5px'})}}
                                            options={types}
                                             defaultValue={initialType}
                                            onChange={(value) => onChange(value)}/>
                                )}/>
                </div>

                {props.itemType === 'trend' && <div className={style.input_text_wrapper}>
                    <label htmlFor="color">Color:</label>
                    <Controller control={control}
                                name={'color'}
                                render={({field: {onChange, value, ref, name}}) => (
                                    <Select styles={{
                                        ...colourStyles,
                                    }}
                                            options={colors}
                                            defaultValue={initialColor}
                                            onChange={(value) => onChange(value)}/>
                                )}/>
                    {errors.type && <div className={style.error_message}>{errors?.type?.message || 'error!'}</div>}
                </div>}
            </div>

            <div className={style.input_text_wrapper}>
                <label htmlFor="text">Text:</label>
                <textarea id={'auto_textarea'} {...register('text', {
                    required: 'Text is required!'
                })}/>
            </div>
            {!!errors?.text && <div className={style.error_message}>{errors?.text?.message || 'error!'}</div>}

            <div className={style.button_wrapper}>
                <ButtonWithImage height={46}
                                 name={props.actionType === 'create' ? 'Add' : 'Edit'}
                                 type={'submit'}
                                 margin={'0 auto'}
                                 disabled={!isValid}
                                 img={save}></ButtonWithImage>
            </div>

        </form>

    )
}

export default AddItemModalWindowBody;

