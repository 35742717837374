import React, {useEffect} from "react";
import style from './modalWindowCreateTeam.module.scss';
import teamImg from './../../../../assets/images/createTeam/ic_team-blue.svg';
import closeImg from './../../../../assets/images/createTeam/close.png';
import {useDispatch, useSelector} from "react-redux";
import {getInfoModalWindowCreateTeamTC} from "../../../../redux/reducers/createTeamReducer";
import {RootStateType} from "../../../../redux/store/store";
import ItemForChoose from "./itemForChoose/itemForChoos";
import {NavLink} from "react-router-dom";
import {LoaderDots} from "@thumbtack/thumbprint-react";

export type ModalWindowCreateTeamPropsType = {
    closeModalWindow: (mode: boolean) => void
}

const ModalWindowCreateTeam = React.memo((props: ModalWindowCreateTeamPropsType) => {
    const state = useSelector((state: RootStateType) => state.createTeam.modalWindow);
    const dispatch = useDispatch();
    const loading = useSelector((state: RootStateType) => state.createTeam.loading);

    useEffect(() => {
        document.body.classList.add('overflow-hidden')
        dispatch(getInfoModalWindowCreateTeamTC());
       // document.body.classList.remove('overflow-hidden');

        return (() => {
            document.body.classList.remove('overflow-hidden');
        })
    }, [])

    return(
      state.company ?
        <div className={style.modal_wrapper}>
            <div className={style.modal_body}>
                <h2>Your Company Selection</h2>
                <h3>Select existing company for your team:</h3>
                <NavLink to={`/teamCreate`}><ItemForChoose name={state.company?.name}/></NavLink>

                {state.teams?.length ?
                <>
                    <h3>The onboarding for teams is not yet complete.</h3>
                    {!loading ? state.teams.map(team => {
                        return (
                            <React.Fragment key={team.id}>
                                <NavLink to={`/teamCreate/${team.id}`} >
                                    <ItemForChoose name={team.name ? team.name : 'N/A'} backGround={'#f8dd88'} />
                                </NavLink>
                            </React.Fragment>
                        )
                    }) : <LoaderDots/>}

                </> : null
                }

                <div className={style.close} onClick={() => props.closeModalWindow(false)}>
                    <img src={closeImg} alt=""/>
                </div>
            </div>
        </div> : <></>
    )
})

export default ModalWindowCreateTeam;