import {AllActionType} from "../../types";
import {checkinReport, userAPI} from "../../api/api";
import {Dispatch} from "redux";
import {RouterAction} from "react-router-redux";
import {AppThunk, RootStateType} from "../store/store";
import {NavigateFunction} from "react-router-dom";
import {setActiveTabCompanyAC, setActiveTabTeamAC} from "./dashboardReducer";
import {changeUserDataType} from "../../components/pages/companyManagement/companyInformation/companyInformation";
import {v1} from "uuid";
import {errorAlertItem} from "../../common";

export type AlertItemType = {
    id: string,
    message: string,
    status: 'error' | 'success',
    showCloseButton: boolean
}

export type CommonType = {
    socket: any,
    showSidebar: boolean,
    showModalAfterTour: boolean,
    loading: boolean,
    currentStatus: string,
    tourCompleted: boolean,
    roles: Array<{ name: string, value: boolean }>,
    userInfo: { name: string, email: string, shortName: string },
    //для всплывающего окошка переключить роль
    anchorElForAccountMenu: null | HTMLElement,
    anchorElForNotificationsMenu: null | HTMLElement,
    //for alert
    alerts: Array<AlertItemType>,
}

const initialState: CommonType = {
    socket: null,
    showSidebar: false,
    showModalAfterTour: false,
    loading: false,
    currentStatus: '',
    tourCompleted: false,
    roles: [],
    userInfo: {name: '', email: '', shortName: ''},
    anchorElForAccountMenu: null,
    anchorElForNotificationsMenu: null,
    //for alert
    alerts: [],
}

// @ts-ignore
export const commonReducer = (state = initialState, action: AllActionType): CommonType => {
    switch (action.type) {
        case 'GET_SOCKET_COMMON_REDUCER': {
            return {...state, socket: action.socket}
        }
        case 'SET-SHOW-SIDEBAR-COMMON-REDUCER': {
            return {...state, showSidebar: action.show}
        }
        case 'COMMON_REDUCER_SET_LOADING' : {
            return {...state, loading: action.loading}
        }
        case 'SET-CURRENT-STATUS': {
            return {...state, currentStatus: action.status}
        }
        case 'SET-ROLES': {
            return {...state, roles: action.roles}
        }
        case 'ADD-ROLE': {
            return {...state, roles: [...state.roles, action.role]}
        }
        case 'SET-USER-INFO': {
            return {...state, userInfo: action.userInfo}
        }
        case 'SET-ANCHOR-EL-FOR-ACCOUNT-MENU': {
            return {...state, anchorElForAccountMenu: action.el}
        }
        case 'SET-ANCHOR-EL-FOR-NOTIFICATION-MENU': {
            return {...state, anchorElForNotificationsMenu: action.el}
        }
        case 'SET-TOUR-COMPLETED': {
            return {...state, tourCompleted: action.isCompleted}
        }
        case 'SET-SHOW-MODAL-WINDOW-AFTER-TOUR': {
            return {...state, showModalAfterTour: action.show}
        }
        case 'ADD-ALERT-COMMON-REDUCER': {
            return {...state, alerts: [...state.alerts, action.alert]}
        }
        case 'REMOVE-ALERT-COMMON-REDUCER': {
            let all = state.alerts.filter(al => al.id !== action.id)
            return {...state, alerts: all}
        }
        default:
            return state;
    }
}

export type getSocketCommonReducerACType = ReturnType<typeof getSocketCommonReducerAC>;
export type setShowSidebarCommonReducerACType = ReturnType<typeof setShowSidebarCommonReducerAC>;
export type setShowModalAfterTourACType = ReturnType<typeof setShowModalAfterTourAC>;
export type setLoadingCommonReducerACType = ReturnType<typeof setLoadingCommonReducerAC>;
export type setCurrentStatusACType = ReturnType<typeof setCurrentStatusAC>;
export type setRolesACType = ReturnType<typeof setRolesAC>;
export type setUserInfoACType = ReturnType<typeof setUserInfoAC>;
export type setAnchorElForAccountMenuACType = ReturnType<typeof setAnchorElForAccountMenuAC>;
export type setAnchorElForNotificationsMenuACType = ReturnType<typeof setAnchorElForNotificationsMenuAC>;
export type setTourCompletedACType = ReturnType<typeof setTourCompletedAC>;
export type addRoleACType = ReturnType<typeof addRoleAC>;
export type addAlertMessageCommonReducerACType = ReturnType<typeof addAlertMessageCommonReducerAC>;
export type removeAlertMessageCommonReducerACType = ReturnType<typeof removeAlertMessageCommonReducerAC>;

export const getSocketCommonReducerAC = (socket: any) => ({type: 'GET_SOCKET_COMMON_REDUCER', socket} as const);
export const setShowSidebarCommonReducerAC = (show: boolean) => ({
    type: 'SET-SHOW-SIDEBAR-COMMON-REDUCER',
    show
} as const);
export const setShowModalAfterTourAC = (show: boolean) => ({type: 'SET-SHOW-MODAL-WINDOW-AFTER-TOUR', show} as const);
export const setLoadingCommonReducerAC = (loading: boolean) => ({type: 'COMMON_REDUCER_SET_LOADING', loading} as const);
export const setCurrentStatusAC = (status: string) => ({type: 'SET-CURRENT-STATUS', status} as const);
export const setRolesAC = (roles: Array<{ name: string, value: boolean }>) => ({type: 'SET-ROLES', roles} as const);

export const addRoleAC = (role: { name: string, value: boolean }) => ({type: 'ADD-ROLE', role} as const);
export const setUserInfoAC = (userInfo: any) => ({type: 'SET-USER-INFO', userInfo} as const);
export const setAnchorElForAccountMenuAC = (el: null | HTMLElement) => ({
    type: 'SET-ANCHOR-EL-FOR-ACCOUNT-MENU',
    el
} as const);
export const setAnchorElForNotificationsMenuAC = (el: null | HTMLElement) => ({
    type: 'SET-ANCHOR-EL-FOR-NOTIFICATION-MENU',
    el
} as const);
export const setTourCompletedAC = (isCompleted: boolean) => ({type: 'SET-TOUR-COMPLETED', isCompleted} as const);
//for alert
export const addAlertMessageCommonReducerAC = (alert: AlertItemType) => ({
    type: 'ADD-ALERT-COMMON-REDUCER',
    alert
} as const);
export const removeAlertMessageCommonReducerAC = (id: string) => ({type: 'REMOVE-ALERT-COMMON-REDUCER', id} as const);


//это thunk creator
export const getRoles = (navigate: (url: string) => void, pathName: string = '') => {

    return async (dispatch: Dispatch<AllActionType | RouterAction>) => {
        dispatch(setLoadingCommonReducerAC(true));
        checkinReport.getRoles().then(res => {
            //если добавленный флажок blocked === true
            if (res.data.blocked) {
                let email = localStorage.getItem('email');
                localStorage.clear();
                email && localStorage.setItem('email', email);
                // window.location.href = '/signIn';
                navigate('/signIn');
            }

            //начальное значение текущего статуса до переключения
            //изменения для сокрытия аккаунтов в демках
            let updatedRoles = localStorage.getItem('updated_roles') || '';
            let currentStatus = updatedRoles.length ? JSON.parse(updatedRoles)[0].name
                : res.data.isModerator ? 'isModerator' : res.data.isAdmin ? 'isAdmin' : res.data.isDepartmentAdmin ? 'isDepartmentAdmin' : res.data.isMember ? 'isMember' : '';
            localStorage.setItem('role', currentStatus);

            //берем только трушные роли
            let roles = [{name: 'isAdmin', value: res.data.isAdmin},
                {name: 'isModerator', value: res.data.isModerator},
                {name: 'isMember', value: res.data.isMember},
                {name: 'isDepartmentAdmin', value: res.data.isDepartmentAdmin}].filter(role => role.value);
            // console.log(roles)
            localStorage.setItem('all_roles', JSON.stringify(roles));

            //костыль
            if (!res.data.onboardingCompleted && window.location.href.split('/')[3] === 'dashboard') {
                navigate('/onboarding')

            }
            dispatch(setLoadingCommonReducerAC(false));
            dispatch(setTourCompletedAC(res.data.tourCompleted));
            dispatch(setRolesAC(roles));
            dispatch(setCurrentStatusAC(currentStatus));

            //это добавила для логики с демкой
            //но делать это только если находимся на странице демок!!!!
            pathName === '/demo' && navigate('/dashboard');

        }).catch(err => {
            dispatch(setLoadingCommonReducerAC(false));
            dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
        })
    };
}


export const getUserInfo = () => async (dispatch: Dispatch<AllActionType>) => {
    userAPI.getData().then(res => {
        const userInfo = {
            name: `${res.data.profile?.firstName} ${res.data.profile?.lastName}`,
            email: res.data.profile?.email,
            shortName: `${res.data.profile?.firstName[0]}${res.data.profile?.lastName[0]}`
        }

        let oldEmailFromLS = localStorage.getItem('email');
        if (oldEmailFromLS) {
            localStorage.removeItem('email');
            localStorage.setItem('email', userInfo.email);
        } else {
            localStorage.setItem('email', userInfo.email);
        }

        dispatch(setUserInfoAC(userInfo));
    })
}

export const setTourCompletedTC = (currentStatus: any, navigate: NavigateFunction): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let anchorElForAccount = getState().common.anchorElForAccountMenu;

    checkinReport.setTourCompleted().then(res => {
        dispatch(setTourCompletedAC(true));
        dispatch(setShowModalAfterTourAC(true));
        anchorElForAccount && dispatch(setAnchorElForAccountMenuAC(null));
        if (currentStatus === 'isModerator' || currentStatus === 'isDepartmentAdmin') {
            navigate('/dashboard', {replace: true});
            dispatch(setActiveTabCompanyAC('Overview of Company'));
        } else {
            navigate('/dashboard/team', {replace: true});
            dispatch(setActiveTabTeamAC('Overview'));
        }
    }).catch((err) => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}))
    })
}

export const changeUserDataTC = (data: changeUserDataType,
                                 setError: (notification: string) => void,
                                 setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    userAPI.changeUserData(data).then(res => {
        const userInfo = {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            shortName: `${data.firstName[0]}${data.lastName[0]}`
        }
        dispatch(setUserInfoAC(userInfo));
        setSuccess('Account data successfully changed!')
    }).catch((err) => {
        setError(err.response.data.message);
    })
}