import {AllActionType} from "../../types";
import {AppThunk, RootStateType} from "../store/store";
import {companyManagementAPI, departmentManagementAPI, teamManagementAPI} from "../../api/api";
import {v1} from "uuid";
import {
    addMemberTeamManagementAC, changeMemberDataDemoCompanyManagementAC,
    CheckinReportMemberType,
    removeMemberTeamManagementAC
} from "./teamManagementReducer";
import {setErrorDigitalPulseAC, setNotificationDigitalPulseAC} from "./digitalPulseReducer";
import {changeUserDataType} from "../../components/pages/companyManagement/companyInformation/companyInformation";
import {changeDepartmentLeaderDataDemoCompanyManagementAC} from "./companyManagementReducer";
import {addAlertMessageCommonReducerAC, setLoadingCommonReducerAC} from "./commonReducer";
import {errorAlertItem} from "../../common";

//initialState.leader: это для того, чтоб в модалке отобразить разный контент и действия в зависимости от того, где модалка вызвана
//если team: мы либо меняем тим лидера, либо приглашаем нового тим лидера
//если department: мы меняем менеджера департамента
//если member: мы добавляем в команду нового участника

export type  LeaderType = 'team' | 'department' | 'member';

export type CertainTeamMemberType = {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    onboarding: boolean,
    status: boolean
}

export type DepartmentManagementDepartmentType = {
    companyId: number,
    createdAt: string,
    id: number,
    name: string,
    updatedAt: string,
    userId: number
}

export type DepartmentManagementCertainTeamType = {
    id: number,
    leaderName: string,
    link: string,
    members: Array<CertainTeamMemberType>,
    name: string,
    surveysTimeSend: string,
}

export type DepartmentManagementTeamType = {
    id: number,
    email: string,
    leader: string,
    membersCount: number,
    name: string,
    onboardingCompleted: boolean,
    status: boolean
}

export type DepartmentInfoType = {
    companyId: number,
    id: number,
    leader: string,
    link: string,
    name: string,
    teams: Array<DepartmentManagementTeamType>,
    teamscount: string
}

export type DepartmentManagementStateType = {
    loading: boolean,
    //для того. чтоб знать с каким контентом модалку открывать
    leader: LeaderType | '',
    departments: Array<DepartmentManagementDepartmentType>,
    chosenDepartment: DepartmentManagementDepartmentType | null,
    chosenTeam: any,
    departmentInfo: DepartmentInfoType,
    departmentCheckinReport: {
        chosenTeamFromSelect: DepartmentManagementTeamType | null,
        date: Date,
        members: Array<CheckinReportMemberType>,
    }
    certainTeamPage: {
        certainTeamPageInfo: DepartmentManagementCertainTeamType,
        certainTeamPageCheckinReports: {
            date: Date,
            members: Array<CheckinReportMemberType>,
        },
    }
}

const initialState: DepartmentManagementStateType = {
    loading: true,
    //для того. чтоб знать с каким контентом модалку открывать
    leader: '',
    departments: [],
    chosenDepartment: null,
    chosenTeam: null,
    departmentInfo: {
        companyId: 0,
        id: 0,
        leader: '',
        link: '',
        name: '',
        teams: [],
        teamscount: ''
    },
    departmentCheckinReport: {
        chosenTeamFromSelect: null,
        date: new Date(),
        members: [],
    },
    certainTeamPage: {
        certainTeamPageInfo: {
            id: 0,
            leaderName: '',
            link: '',
            members: [],
            name: '',
            surveysTimeSend: '',
        },
        certainTeamPageCheckinReports: {
            date: new Date(),
            members: [],
        },
    }
}


export const departmentManagementReducer = (state = initialState, action: AllActionType): DepartmentManagementStateType => {
    switch (action.type) {
        case 'SET-LOADING-DEPARTMENT-MANAGEMENT': {
            return {...state, loading: action.loading}
        }
        case 'SET-LEADER-TYPE-DEPARTMENT-MANAGEMENT': {
            return {...state, leader: action.leader}
        }
        case 'SET-ALL-DEPARTMENTS-DEPARTMENT-MANAGEMENT': {
            return {...state, departments: [...action.departments]}
        }
        case 'SET-CHOSEN-DEPARTMENT-DEPARTMENT-MANAGEMENT': {
            return {...state, chosenDepartment: action.department ? {...action.department} : action.department}
        }
        case 'SET-DEPARTMENT-INFO-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentInfo: {...action.info}}
        }
        case 'CHOOSE-TEAM-DEPARTMENT-MANAGEMENT-INFO': {
            return {...state, chosenTeam: {...action.team}}
        }
        case 'SET-CERTAIN-TEAM-INFO-DEPARTMENT-MANAGEMENT': {
            return {...state, certainTeamPage: {...state.certainTeamPage, certainTeamPageInfo: {...action.info}}}
        }
        case 'SET-CHOSEN-TEAM-FROM-SELECT-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                departmentCheckinReport: {
                    ...state.departmentCheckinReport,
                    chosenTeamFromSelect: action.team ? {...action.team} : null
                }
            }
        }
        case 'SET-DATE-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentCheckinReport: {...state.departmentCheckinReport, date: action.date}}
        }
        case 'SET-MEMBERS-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentCheckinReport: {...state.departmentCheckinReport, members: [...action.members]}}
        }
        case 'SET-MEMBERS-CERTAIN-TEAM-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageCheckinReports: {
                        ...state.certainTeamPage.certainTeamPageCheckinReports,
                        members: [...action.members]
                    }
                }
            }
        }
        case 'SET-DATE-CERTAIN-TEAM-PAGE-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageCheckinReports: {
                        ...state.certainTeamPage.certainTeamPageCheckinReports,
                        date: action.date
                    }
                }
            }
        }
        case 'ADD-TEAM-MEMBER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        members: [...state.certainTeamPage.certainTeamPageInfo.members, action.member]
                    }
                }
            }
        }
        case 'REMOVE-TEAM-MEMBER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                certainTeamPage: {
                    ...state.certainTeamPage,
                    certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        members: state.certainTeamPage.certainTeamPageInfo.members.filter(member => member.id !== action.memberId)
                    }
                }
            }
        }
        case 'CHANGE-DEPARTMENT-NAME-DEPARTMENT-MANAGEMENT': {
            return {...state, departmentInfo: {...state.departmentInfo, name: action.name}}
        }
        case 'INVITE-TEAM-LEADER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                departmentInfo: {...state.departmentInfo, teams: [action.teamLeader, ...state.departmentInfo.teams]}
            }
        }
        case 'DELETE-TEAM-LEADER-DEPARTMENT-MANAGEMENT': {
            return {
                ...state,
                departmentInfo: {
                    ...state.departmentInfo,
                    teams: state.departmentInfo.teams.filter(team => team.id !== action.teamId)
                }
            }
        }
        case 'CHANGE-TEAM-LEADER-DATA-DEMO-DEPARTMENT-MANAGEMENT': {
            let newLeaderName = `${action.data.firstName} ${action.data.lastName}`
            return {
                ...state, departmentInfo: {
                    ...state.departmentInfo, teams: state.departmentInfo.teams
                        .map(team => team.id === action.teamId ? {
                            ...team,
                            leader: newLeaderName,
                            email: action.data.email
                        } : team)
                }
            }
        }
        case 'CHANGE-MEMBER-DATA-DEMO-CERTAIN-TEAM-INFO-DEPARTMENT-MANAGEMENT': {
            return {
                ...state, certainTeamPage: {
                    ...state.certainTeamPage, certainTeamPageInfo: {
                        ...state.certainTeamPage.certainTeamPageInfo,
                        members: state.certainTeamPage.certainTeamPageInfo.members.map(member => member.id === action.userId ? {...member, ...action.data} : member)
                    }
                }
            }
        }
        //для дмок, сами экшн креаторы находятся в companyManagementReducer
        case 'CREATE-CHECKIN-COMPANY-MANAGEMENT-DEMO': {
            return action.checkinType === 'department' ? {
                ...state,
                departmentCheckinReport: {...state.departmentCheckinReport, members: [...state.departmentCheckinReport.members, action.checkin,]}
            } : action.checkinType === 'department_certain_team' ? {
                    ...state,
                    certainTeamPage: {...state.certainTeamPage, certainTeamPageCheckinReports:
                            {...state.certainTeamPage.certainTeamPageCheckinReports, members: [...state.departmentCheckinReport.members, action.checkin]} }
                } : {...state}
        }
        case 'UPDATE-CHECKIN-COMPANY-MANAGEMENT-DEMO': {
            return action.checkinType === 'department' ? {
                ...state, departmentCheckinReport: {
                    ...state.departmentCheckinReport, members: state.departmentCheckinReport.members
                        .map(m => m.id === action.checkin.id ? {...action.checkin} : m)
                }
            } : action.checkinType === 'department_certain_team' ? {
                ...state,
                certainTeamPage: {...state.certainTeamPage, certainTeamPageCheckinReports:
                        {...state.certainTeamPage.certainTeamPageCheckinReports, members: state.certainTeamPage.certainTeamPageCheckinReports.members
                                .map(m => m.id === action.checkin.id ? {...action.checkin} : m) }}
            } : {...state}
        }
        case 'DELETE-CHECKIN-COMPANY-MANAGEMENT-DEMO': {
            return action.checkinType === 'department' ? {
                ...state,
                departmentCheckinReport: {
                    ...state.departmentCheckinReport,
                    members: state.departmentCheckinReport.members.filter(m => m.id !== action.checkinId)
                }
            } : action.checkinType === 'department_certain_team' ? {
                ...state,
                certainTeamPage: {...state.certainTeamPage, certainTeamPageCheckinReports:
                        {...state.certainTeamPage.certainTeamPageCheckinReports, members: state.certainTeamPage.certainTeamPageCheckinReports.members
                                .filter(m => m.id !== action.checkinId) }}
            } : {...state}
        }
        default:
            return state;
    }
}


export type setLeaderTypeDepartmentManagementACType = ReturnType<typeof setLeaderTypeDepartmentManagementAC>;
export type setLoadingDepartmentManagementACType = ReturnType<typeof setLoadingDepartmentManagementAC>;
export type setDepartmentsDepartmentManagementACType = ReturnType<typeof setDepartmentsDepartmentManagementAC>;
export type setChosenDepartmentDepartmentManagementACType = ReturnType<typeof setChosenDepartmentDepartmentManagementAC>;
export type setDepartmentInfoDepartmentManagementACType = ReturnType<typeof setDepartmentInfoDepartmentManagementAC>;
export type setChosenTeamDepartmentManagementInfoACType = ReturnType<typeof setChosenTeamDepartmentManagementInfoAC>;

export type setCertainTeamInfoDepartmentManagementACType = ReturnType<typeof setCertainTeamInfoDepartmentManagementAC>;
export type setChosenTeamFromSelectDepartmentManagementACType = ReturnType<typeof setChosenTeamFromSelectDepartmentManagementAC>;
export type setDateDepartmentCheckinReportACType = ReturnType<typeof setDateDepartmentCheckinReportAC>;

export type setMembersDepartmentCheckinReportDepartmentManagementACType = ReturnType<typeof setMembersDepartmentCheckinReportDepartmentManagementAC>;

export type setDateCertainTeamPageCheckinReportDepartmentManagementACType = ReturnType<typeof setDateCertainTeamPageCheckinReportDepartmentManagementAC>;
export type setMembersCertainTeamCheckinReportDepartmentManagementACType = ReturnType<typeof setMembersCertainTeamCheckinReportDepartmentManagementAC>;

export type addTeamMemberDepartmentManagementACType = ReturnType<typeof addTeamMemberDepartmentManagementAC>;
export type removeMemberDepartmentManagementACType = ReturnType<typeof removeMemberDepartmentManagementAC>;

export type changeDepartmentNameDepartmentManagementACType = ReturnType<typeof changeDepartmentNameDepartmentManagementAC>;
export type inviteTeamLeaderDepartmentManagementACType = ReturnType<typeof inviteTeamLeaderDepartmentManagementAC>;
export type deleteTeamLeaderDepartmentManagementACType = ReturnType<typeof deleteTeamLeaderDepartmentManagementAC>;
export type changeTeamLeaderDataDemoDepartmentManagementACType = ReturnType<typeof changeTeamLeaderDataDemoDepartmentManagementAC>;
export type changeMemberDataDemoDepartmentManagementACType = ReturnType<typeof changeMemberDataDemoDepartmentManagementAC>;

export const setLeaderTypeDepartmentManagementAC = (leader: LeaderType) => ({
    type: 'SET-LEADER-TYPE-DEPARTMENT-MANAGEMENT',
    leader
} as const);

export const setLoadingDepartmentManagementAC = (loading: boolean) => ({
    type: 'SET-LOADING-DEPARTMENT-MANAGEMENT',
    loading
} as const);
export const setDepartmentsDepartmentManagementAC = (departments: Array<DepartmentManagementDepartmentType>) => ({
    type: 'SET-ALL-DEPARTMENTS-DEPARTMENT-MANAGEMENT',
    departments
} as const);
export const setChosenDepartmentDepartmentManagementAC = (department: DepartmentManagementDepartmentType | null) => ({
    type: 'SET-CHOSEN-DEPARTMENT-DEPARTMENT-MANAGEMENT',
    department
} as const);
export const setDepartmentInfoDepartmentManagementAC = (info: DepartmentInfoType) => ({
    type: 'SET-DEPARTMENT-INFO-DEPARTMENT-MANAGEMENT',
    info
} as const);
export const setChosenTeamDepartmentManagementInfoAC = (team: DepartmentManagementTeamType) => ({
    type: 'CHOOSE-TEAM-DEPARTMENT-MANAGEMENT-INFO',
    team
} as const);

export const setCertainTeamInfoDepartmentManagementAC = (info: any) => ({
    type: 'SET-CERTAIN-TEAM-INFO-DEPARTMENT-MANAGEMENT',
    info
} as const);
export const setChosenTeamFromSelectDepartmentManagementAC = (team: DepartmentManagementTeamType | null) => ({
    type: 'SET-CHOSEN-TEAM-FROM-SELECT-DEPARTMENT-MANAGEMENT',
    team
} as const);
export const setDateDepartmentCheckinReportAC = (date: Date) => ({
    type: 'SET-DATE-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    date
} as const);
export const setMembersDepartmentCheckinReportDepartmentManagementAC = (members: Array<CheckinReportMemberType>) => ({
    type: 'SET-MEMBERS-DEPARTMENT-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    members
} as const);

export const setDateCertainTeamPageCheckinReportDepartmentManagementAC = (date: Date) => ({
    type: 'SET-DATE-CERTAIN-TEAM-PAGE-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    date
} as const);
export const setMembersCertainTeamCheckinReportDepartmentManagementAC = (members: Array<CheckinReportMemberType>) => ({
    type: 'SET-MEMBERS-CERTAIN-TEAM-CHECKIN-REPORT-DEPARTMENT-MANAGEMENT',
    members
} as const);

export const addTeamMemberDepartmentManagementAC = (member: CertainTeamMemberType) => ({
    type: 'ADD-TEAM-MEMBER-DEPARTMENT-MANAGEMENT',
    member
} as const);
export const removeMemberDepartmentManagementAC = (memberId: string) => ({
    type: 'REMOVE-TEAM-MEMBER-DEPARTMENT-MANAGEMENT',
    memberId
} as const);

export const changeDepartmentNameDepartmentManagementAC = (name: string) => ({
    type: 'CHANGE-DEPARTMENT-NAME-DEPARTMENT-MANAGEMENT',
    name
} as const);
export const inviteTeamLeaderDepartmentManagementAC = (teamLeader: DepartmentManagementTeamType) => ({
    type: 'INVITE-TEAM-LEADER-DEPARTMENT-MANAGEMENT',
    teamLeader
} as const);
export const deleteTeamLeaderDepartmentManagementAC = (teamId: number) => ({
    type: 'DELETE-TEAM-LEADER-DEPARTMENT-MANAGEMENT',
    teamId
} as const);
//demo
export const changeTeamLeaderDataDemoDepartmentManagementAC = (data: changeUserDataType, teamId: number | string) => ({
    type: 'CHANGE-TEAM-LEADER-DATA-DEMO-DEPARTMENT-MANAGEMENT',
    data,
    teamId
} as const);
export const changeMemberDataDemoDepartmentManagementAC = (data: changeUserDataType, userId: string) => ({
    type: 'CHANGE-MEMBER-DATA-DEMO-CERTAIN-TEAM-INFO-DEPARTMENT-MANAGEMENT',
    data,
    userId
} as const);


export const getAllDepartmentsDepartmentManagementTC = (): AppThunk => async (dispatch, getState: () => RootStateType) => {
    // dispatch(setLoadingDepartmentManagementAC(true));

    departmentManagementAPI.getDepartments().then(res => {

        let chosenDepartment = getState().departmentManagement.chosenDepartment;
        //если департамент не выбран, то мы устанавливаем первый из полученного списка
        if (!chosenDepartment) {
            dispatch(setChosenDepartmentDepartmentManagementAC(res.data.departments[0]));
        }
        dispatch(setDepartmentsDepartmentManagementAC(res.data.departments));
        // dispatch(setLoadingDepartmentManagementAC(false));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const getDepartmentInfoDepartmentManagementTC = (departmentId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    dispatch(setLoadingDepartmentManagementAC(true));
    departmentManagementAPI.getDepartmentInfo(departmentId).then(res => {
        dispatch(setDepartmentInfoDepartmentManagementAC(res.data.department));
        dispatch(setLoadingDepartmentManagementAC(false));
    }).catch(err => {
        dispatch(setLoadingDepartmentManagementAC(false));
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

//для страницы конкретно выбранной команды
export const getCertainTeamInfoDepartmentManagementTC = (teamId: number): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getCertainTeamInfo(teamId).then(res => {
        let info = res.data.team;
        let newMembers = info.members.map((i: any) => ({...i, id: v1()}));
        info.members = newMembers;
        dispatch(setCertainTeamInfoDepartmentManagementAC(info));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}


export const getInfoDepartmentCheckinReportDepartmentManagementTC = (teamId: number, date: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getCheckinReportInfo(teamId, date).then(res => {
        // let members = res.data.answers.map((m: CheckinReportMemberType) => ({...m, id: v1()}))
        dispatch(setMembersDepartmentCheckinReportDepartmentManagementAC(res.data.answers));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}


export const getInfoCertainTeamCheckinReportDepartmentManagementTC = (teamId: number, date: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.getCheckinReportInfo(teamId, date).then(res => {
       // let members = res.data.answers.map((m: CheckinReportMemberType) => ({...m, id: v1()}))
        dispatch(setMembersCertainTeamCheckinReportDepartmentManagementAC(res.data.answers));
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const changeDepartmentManagerTC = (departmentId: number, info: { firstName: string, lastName: string, email: string, keep: boolean }): AppThunk => async (dispatch, getState: () => RootStateType) => {

    departmentManagementAPI.changeDepartmentManager(departmentId, info).then(res => {
//гоша еще не сделал
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const changeDepartmentNameTC = (departmentId: number,
                                       name: string,
                                       setError: (error: boolean) => void,
                                       setNotification: (notification: string) => void,
                                       navigate: (url: string) => void
): AppThunk => async (dispatch, getState: () => RootStateType) => {
    let departments = getState().departmentManagement.departments;
    departmentManagementAPI.changeDepartmentName(departmentId, name).then(res => {
        let fixedDepartments = departments.map(d => d.id === departmentId ? {...d, name: name} : d);
        dispatch(changeDepartmentNameDepartmentManagementAC(name));
        dispatch(setDepartmentsDepartmentManagementAC(fixedDepartments));
    }).catch(error => {
        let status = error.response && error.response.data && error.response.data.status;
        if (status === 500 || status === 404) {
            // window.location.href = '/404';
            navigate('/404');
        } else if (status === 403) {
            setError(true);
            setNotification(error.response.data.message);
            // dispatch(setErrorDigitalPulseAC(true));
            // dispatch(setNotificationDigitalPulseAC(error.response.data.message));
        } else {
            // window.location.href = '/pageOops';
            navigate('/pageOops');
        }
    })
}

export const deleteDepartmentTC = (departmentId: number, navigate: (url: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.deleteDepartment(departmentId).then(res => {
         dispatch(setChosenDepartmentDepartmentManagementAC(null));
        navigate('/dashboard');
        //какая логика тут должна быть еще??? еще не тестили
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const inviteTeamLeaderDepartmentManagementTC = (departmentId: number, info: { firstName: string, lastName: string, email: string }, teamLeader: DepartmentManagementTeamType): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.inviteTeamLeader(departmentId, info).then(res => {
        dispatch(inviteTeamLeaderDepartmentManagementAC(teamLeader))
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const reinviteTeamLeaderDepartmentManagementTC = (departmentId: number,
                                                         email: string,
                                                         setError: (notification: string) => void,
                                                         setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.reinviteTeamLeader(departmentId, email).then(res => {
        setSuccess('The invitation will be resent.')
    }).catch(err => {
        setError(err.response.data.message);
        //alert('wrong reinviting team leader on department management');
    })
}

export const deleteTeamLeaderDepartmentManagementTC = (departmentId: number,
                                                       email: string,
                                                       teamId: number,
                                                       setError: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {

    if (teamId < 0) {
        departmentManagementAPI.deleteInviteTeamLeader(departmentId, email).then(res => {
            dispatch(deleteTeamLeaderDepartmentManagementAC(teamId));
        }).catch(err => {
            setError(err.response.data.message);
        })
    } else {
        departmentManagementAPI.deleteTeamLeader(teamId).then(res => {
            dispatch(deleteTeamLeaderDepartmentManagementAC(teamId));
        }).catch(err => {
            setError(err.response.data.message);
        })

    }
}

export const nudgeTeamDepartmentManagementTC = (teamId: number,
                                                setError: (notification: string) => void,
                                                setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.nudgeTeam(teamId).then(res => {
        setSuccess('Leader will be nudged.')
    }).catch(err => {
        setError(err.response.data.message);
        //alert('wrong nudge team on department management');
    })
}

//для всего, что касается мемберов, использую апишку с тим мэнэджмента

export const changeTeamLeaderDepartmentManagementTC = (teamId: number, info: { firstName: string, lastName: string, email: string, keep: boolean }): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.changeTeamLeader(teamId, info).then(res => {

    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const addTeamMemberDepartmentManagementTC = (teamId: number, info: { firstName: string, lastName: string, email: string }, member: CertainTeamMemberType): AppThunk => async (dispatch, getState: () => RootStateType) => {

    teamManagementAPI.addTeamMember(teamId, info).then(res => {
        dispatch(addTeamMemberDepartmentManagementAC(member))
    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const nudgeTeamMemberDepartmentManagementTC = (teamId: number, email: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.nudgeMember(teamId, {email}).then(res => {

    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const reinviteTeamMemberDepartmentManagementTC = (teamId: number, email: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    teamManagementAPI.reinviteMember(teamId, {email}).then(res => {

    }).catch(err => {
        dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
    })
}

export const removeTeamMemberDepartmentManagementTC = (memberStatus: boolean, teamId: number, email: string, memberId: string): AppThunk => async (dispatch, getState: () => RootStateType) => {
    if (memberStatus) {
        teamManagementAPI.removeTeamMember(teamId, {email: email}).then(res => {
            dispatch(removeMemberDepartmentManagementAC(memberId));
        }).catch(err => {
            dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
        })
    } else {
        teamManagementAPI.removeTeamMemberInvite(teamId, {email: email}).then(res => {
            dispatch(removeMemberDepartmentManagementAC(memberId));
        }).catch(err => {
            dispatch(addAlertMessageCommonReducerAC({...errorAlertItem}));
        })
    }

}

//демка
export const changeTeamLeaderDataDemoTC = (chosenUserForDemoEdit: any,
                                           data: changeUserDataType,
                                           setError: (notification: string) => void,
                                           setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.changeDepartmentLeaderDataDemo(chosenUserForDemoEdit.id, chosenUserForDemoEdit.email, data).then(res => {
        dispatch(changeTeamLeaderDataDemoDepartmentManagementAC(data, chosenUserForDemoEdit.id));
        setSuccess('Team leader data successfully changed!')
    }).catch((err) => {
        setError(err.response.data.message)
    })
}


export const changeMemberDataDemoDMTC = (teamId: number,
                                         chosenUserForDemoEdit: any,
                                         data: changeUserDataType,
                                         setError: (notification: string) => void,
                                         setSuccess: (notification: string) => void): AppThunk => async (dispatch, getState: () => RootStateType) => {
    departmentManagementAPI.changeMemberDataDemo(teamId, chosenUserForDemoEdit.email, data).then(res => {
        dispatch(changeMemberDataDemoDepartmentManagementAC(data, chosenUserForDemoEdit.id));
        setSuccess('Member data successfully changed!')
    }).catch((err) => {
        setError(err.response.data.message)
    })
}